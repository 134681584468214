
const adhiDevAwahan=[
    {
        title:`१.शिव (सूर्य के दायें भाग में)`,
        des1:`ॐ त्र्यम्बकं यजामहे सुगन्धिम्पुष्टिवर्द्धनम् उर्वारुकमिव बन्धनान्मृत्योर्मुक्षीय मामृतात् ।`,
        des2:`ॐ एह्येहि विश्वेश्वरनस्त्रिशूलकपालखट्वाङ्गधरेण सार्धम् । लोकेश यक्षेश्वर यज्ञसिद्ध्यै गृहाण पूजां भगवन् नमस्ते ।। `,
        des3:`ॐ भूर्भुवःस्वः ईश्वराय नमः, इहागच्छ, इह तिष्ठ ।`
    },
    {
        title:`२.उमा (चन्द्रमा के दायें भाग में)`,
        des1:`ॐ श्रीश्च ते लक्ष्मीश्च पत्न्यावहोरात्रो पार्श्वे नक्षत्राणि रूपमश्विनौ व्यात्तम्। इष्णन्निषाणामुम्मऽइषाणसर्वलोकम्मऽइषाण।`,
        des2:`ॐ हेमाद्रितनयां देवीं वरदां शंकरप्रियाम् । लम्बोदरस्य जननीमुमावाहयाम्यहम् ।।`,
        des3:`ॐ भूर्भुवःस्वः उमायै नमः, इहागच्छ, इह तिष्ठ ।`
    },
    {
        title:`३.स्कन्द (मंगल के दायें भाग में)`,
        des1:`ॐ यदक्रन्दः प्रथमं जायमानः उद्यन्त्समुद्रादुत वा पुरीषात्। श्येनस्य पक्षा हरिणस्य बाहूऽउपस्तुत्यं महिजातन्ते अर्वन् ।।`,
        des2:`ॐ रुद्रतेजः समुत्पन्नं देवसेनाग्रगं विभुम् । षण्मुखं कृत्तिकासूनुं स्कन्दमावाहयाम्यहम् ।। `,
        des3:`ॐ भूर्भुवःस्वः स्कन्दाय नमः, इहागच्छ, इह तिष्ठ ।`
    },
    {
        title:`४.विष्णु (बुध के दायें भाग में)`,
        des1:`ॐ विष्णोरराटमसि विष्णोः  श्नप्त्रेस्त्थो विष्णोः स्यूरसि विष्णोर्ध्र्रुवोसि वैष्णवमसि विष्णवे त्वा ।।`,
        des2:`ॐ देवदेवं जगन्नाथं भक्तानुग्रहकारकम् । चतुर्भुजं रमानाथं विष्णुमावाहयाम्यहम् ।।`,
        des3:`ॐ भूर्भुवःस्वः विष्णवे नमः, इहागच्छ, इह तिष्ठ ।`
    },
    {
        title:`५.ब्रह्मा (बृहस्पति के दायें भाग में)`,
        des1:`ॐ ब्रह्म यज्ञानं प्रथमं पुरस्ताद्विसीमतः सुरुचो वेनऽआवः। सबुध्न्या उपमाऽअस्य विष्ठाः सतश्च योनिमसश्च विवः।।`,
        des2:`ॐ कृष्णाजिनाम्बरधरं पद्मसंस्थं चतुर्मुखम् । वेदाधारं निरालम्बं विधिमावाहयाम्यहम् ।।`,
        des3:`ॐ भूर्भुवःस्वः ब्रह्मणे नमः, इहागच्छ, इह तिष्ठ ।`
    },
    {
        title:`६. इन्द्र (शुक्र के दायें भाग में)`,
        des1:`ॐ सजोषा इन्द्र सगणो मरुद्भिः सोमम्पिब वृत्रहा शूर विद्वान् जहि शत्रूँरपमृधोनुदस्वाथाभयङ्कृणुहि विश्वतो नमः ।।`,
        des2:`ॐ देवराजं गजारुढं शुनासीरं शतक्रतुम् । वज्रहस्तं महाबाहुमिन्द्रमावाहयाम्यहम् ।।`,
        des3:`ॐ भूर्भुवःस्वः इन्द्राय नमः, इहागच्छ, इह तिष्ठ ।`
    },
    {
        title:`७. यम (शनि के दायें भाग में)`,
        des1:`ॐ यमाय त्वांगिरस्वते पितृमते स्वाहा धर्माय स्वाहा धर्मः पित्रे ।।`,
        des2:`ॐ धर्मराजं महावीर्यं दक्षिणादिक्पतिं प्रभुम् । रक्तेक्षणं महाबाहुं यममावाहयाम्यहम् ।।`,
        des3:`ॐ भूर्भुवःस्वः यमाय नमः, इहागच्छ, इह तिष्ठ ।`
    },
    {
        title:`८. काल (राहु के दायें भाग में)`,
        des1:`ॐ कार्षिरसि समुद्रस्य त्वाक्षित्याऽउन्नयामि समापोऽअद्भिरग्मतसमोषधी-भिरोषधीः ।।`,
        des2:`ॐ अनाकारमन्ताख्यं वर्तमानं दिने-दिने। कलाकाष्ठादिरुपेण कालमावाहयाम्यहम् ।।`,
        des3:`ॐ भूर्भुवः स्वः कालाय नमः, इहागच्छ, इह तिष्ठ ।`
    },
    {
        title:`९. चित्रगुप्त (केतु के दायें भाग में)`,
        des1:`ॐ चित्रावसो स्वस्ति ते पारमशीय ।।`,
        des2:`ॐ धर्मराजसभासंस्थं कृताकृत विवेकिनम् । आवाहये चित्रगुप्तं लेखनीपत्रहस्तकम् ।।`,
        des3:`ॐ भूर्भुवःस्वः चित्रगुप्ताय नमः, इहागच्छ, इह तिष्ठ ।`
    }
]

const pratydhiDevAvahan=[
    {
        title:`१.अग्नि(सूर्य के बायें)`,
        des1:`ॐ अग्निं दूतं पुरो दधे हव्यवाहमुपब्रुवे देवाँ२ ऽआसादयादिह ।।`,
        des2:`ॐ रक्तमाल्याम्बर धरं रक्तपद्मासनस्थितम् । वरदाभयदं देवमग्निमावाहयाम्यहम् ।।`,
        des3:`ॐ भूर्भुवःस्वः अग्नये नमः, इहागच्छ, इह तिष्ठ ।`
    },
    {
        title:`२.अप् (जल) - (चन्द्रमा के बायें)`,
        des1:`ॐ आपो हिष्ठा मयोभुवस्तानऽऊर्जे दधातन महेरणाय चक्षसे यो वः शिवतमो रसस्तस्य भाजयतेह नः उशतीरिव मातरः। तस्माऽअरङ्गमामवो यस्य क्षयाय जिन्वथ आपो जनयथा च नः।। `,
        des2:`ॐ आदिदेवसमुद्भूतजगच्छुद्धिकराः शुभाः। ओषध्याप्यायनकरा अप आवाहयाम्यहम् ।। `,
        des3:`ॐ भूर्भुवःस्वः अद्भ्यो नमः, इहागच्छत, इह तिष्ठत ।`
    },
    {
        title:`३.पृथ्वी (मंगल के बायें)`,
        des1:`ॐ स्योना पृथिवी नो भवानृक्षरा निवेशनी यच्छा नः शर्म स प्रथाः।। `,
        des2:`ॐ शुक्लवर्णां विशालाक्षीं कूर्मपृष्ठोपरिस्थिताम् । सर्वशस्याश्रयां देवीं धरामावाहयाम्यहम् ।।`,
        des3:`ॐ भूर्भुवःस्वः पृथिव्यै नमः, इहागच्छ, इह तिष्ठ।`
    },
    {
        title:`४.विष्णु (बुध के बायें)`,
        des1:`ॐ विष्णोरराटमसि विष्णोः  श्नप्त्रेस्त्थो विष्णोः स्यूरसि विष्णोर्ध्र्रुवोसि वैष्णवमसि विष्णवे त्वा।।`,
        des2:`ॐ शङ्खचक्रगदापद्महस्तं गरुडवाहनम् । किरीटकुण्डलधरं विष्णुमावाहयाम्यहम् ।।`,
        des3:`ॐ भूर्भुवःस्वः विष्णवे नमः, इहागच्छ, इह तिष्ठ।`
    },
    {
        title:`५.इन्द्र (बृहस्पति के बायें)`,
        des1:`ॐ त्रातारमिन्द्रमवितारमिन्द्रᳪ हवे हवे सुहव ᳪ शूरमिन्द्रम्, ह्वायामि शक्रम्पुरुहूतमिन्द्र ᳪ स्वस्ति नो मघवा धात्विन्द्रः।।`,
        des2:`ॐ ऐरावतगजारुढ़ं सहस्राक्षं शचीपतिम् । वज्रहस्तं सुराधीशमिन्द्रमावाहयाम्यहम् ।। `,
        des3:`ॐ भूर्भुवःस्वः इन्द्राय नमः, इहागच्छ, इह तिष्ठ ।`
    },
    {
        title:`६.इन्द्राणी(शुक्र के बायें)`,
        des1:`ॐ अदित्यैरास्नासीन्द्राण्याऽउष्णीषः पूषासि धर्मायदीष्व।।`,
        des2:`ॐ प्रसन्नवदनां देवीं देवराजस्य वल्लभाम् । नानालङ्कारसंयुक्तां शचीमावाहयाम्यहम् ।।`,
        des3:`ॐ भूर्भुवःस्वः इन्द्राण्यै नमः, इहागच्छ, इह तिष्ठ।`
    },
    {
        title:`७.प्रजापति (शनि के बायें)`,
        des1:`ॐ प्रजापते न त्वदेतान्यन्न्यो विश्वारूपाणि परिता बभूव। यत्कामास्ते जुहुमस्तन्नोऽस्तु वय ᳪ स्याम पतयो रयीणाम् ।।`,
        des2:`ॐ आवाहयाम्यहं देव देवेशं च प्रजापतिम् । अनेकव्रतकर्तारं सर्वेषां च पितामहम् ।।`,
        des3:`ॐ भूर्भुवःस्वः प्रजापतये नमः, प्रजापतिमावाहयामि,स्थापयामि ।`
    },
    {
        title:`८.सर्प (राहु के बायें)`,
        des1:`ॐ नमोस्तु सर्पेभ्यो ये के च पृथिवीमनु येऽअन्तरिक्षे ये दिवि तेभ्यः सर्पेभ्यो नमः।।`,
        des2:`ॐ अनन्ताद्यान् महाकायान् नानामणिविराजितान् । आवाहयाम्यहमं सर्पान् फणासप्तकमण्डितान् ।।`,
        des3:`ॐ भूर्भुवःस्वः सर्पेभ्यो नमः, इहागच्छ, इह तिष्ठ ।`
    },
    {
        title:`९.ब्रह्मा (केतु के बायें)`,
        des1:`ॐ ब्रह्म यज्ञानम्प्रथमम्पुरस्ताद्विसीमतः सुरुचो वेनऽआवः सबुध्न्याऽउपमाऽअस्य विष्ठाः सतश्च योनिमसतश्च विवः।।`,
        des2:`ॐ हंसपृष्ठसमारुढ़ं देवतागण पूजितम् । आवाहयाम्यहं देवं ब्रह्माणं कमलासनम् ।।`,
        des3:`ॐ भूर्भुवःस्वः ब्रह्मणे नमः, इहागच्छ, इह तिष्ठ ।`
    }
]

const rudraAstadhyayi=[
    {
        title:`॥ प्रथमोऽध्यायः ॥`,
        des:`ॐ गणानां त्वा गणपति ᳪ हवामहे प्रियाणां त्वा प्रियपति ᳪ हवामहे निधीनां त्वा निधीपति ᳪ हवामहे वसो मम । आहमजानिगर्भधमात्त्वमजासि गर्भधम् ॥१॥

            गायत्रीत्रिष्टुब्जगत्यनुष्टुप्पङ्क्त्यासह ॥
            बृहत्युष्णिहाककुप्सूचीभिः शम्यन्तु त्वा ॥२॥

            द्विपदायाश्चतुष्पदास्त्रिपदायाश्चषट्पदाः ।
            विच्छन्दा याश्च सच्छन्दाः सूचीभिः शम्यन्तु त्वा ॥३॥

            सहस्तोमाः सहछन्दस ऽआवृतः सहप्रमाः ऽऋषयः सप्तदैव्याः ॥
            पूर्वेषां पन्थामनुदृश्य धीरा ऽअन्वालेभिरे रथ्यो न रश्मीन् ॥४॥

            यज्जाग्रतो दूरमुदैति दैवं तदु सुप्तस्य तथैवैति ॥
            दूरङ्गमं ज्योतिषां ज्योतिरेकं तन्मे मनः शिवसङ्कल्पमस्तु ॥५॥


            येन कर्माण्यपसो मनीषिणो यज्ञे कृण्वन्ति विदथेषु धीराः ॥
            यदपूर्वं यक्षमन्तः प्रजानां तन्मे मनः शिवसङ्कल्पमस्तु ॥६॥

            यत्प्रज्ञानमुतचेतो धृतिश्च यज्ज्योतिरन्तरमृतं प्रजासु ॥
            यस्मान्न ऽऋते किञ्चन कर्म क्रियते तन्मे मनः शिवसङ्कल्पमस्तु ॥७॥

            येनेदं भूतं भुवनं भविष्यत्परिगृहीतममृतेन सर्वम् ॥
            येन यज्ञस्तायते सप्तहोता तन्मे मनः शिवसङ्कल्पमस्तु ॥८॥

            यस्मिन् ऋचः सामयजू ᳪ षि यस्मिन्प्रतिष्ठिता रथनाभाविवाराः ॥
            यस्मिन् चित्त ᳪ सर्वमोतं प्रजानां तन्मे मनः शिवसङ्कल्पमस्तु ॥९॥

            सुषारथिरश्वानिव यन्मनुष्प्यान्नेनीयतेभीशुभिर्वाजिनऽइव ॥
            हृत्प्रतिष्ठं यदजिरं जविष्ठं तन्मे मनः शिवसङ्कल्पमस्तु ॥१०॥
            `,
        end:`इति रुद्राष्टाध्यायी प्रथमोऽध्यायः ॥१॥`
    },
    {
        title:`॥ द्वितीयोऽध्यायः ॥`,
        des:`ॐ सहस्रशीर्षा पुरुषः सहस्राक्षः सहस्रपात् ।
            स भूमि ᳪ सर्वत स्पृत्वात्यतिष्ठद्दशाङ्गुलम् ॥१॥

            पुरुष ऽएवेद ᳪ सर्वं यद्भूतं यच्च भाव्यम् ।
            उतामृतत्वस्येशानो यदन्नेनातिरोहति ॥२॥

            एतावानस्य महिमातो ज्यायाँश्च पूरुषः ।
            पादोऽस्य विश्वा भूतानि त्रिपादस्यामृतं दिवि ॥३॥

            त्रिपादूर्ध्व ऽउदैत्पुरुषः पादोऽस्येहाभवत् पुनः ।
            ततो विष्वङ् व्यक्रामत्साशनानशने ऽअभि ॥४॥


            ततो विराडजायत विराजो ऽअधि पूरुषः ।
            स जातो ऽअत्यरिच्यत पश्चाद्भूमिमथो पुरः ॥५॥

            तस्माद्यज्ञात् सर्वहुतः सम्भृतं पृषदाज्यम् ।
            पशूँस्ताँश्चक्रे वायव्यानारण्या ग्राम्याश्च ये ॥६॥

            तस्माद्यज्ञात् सर्वहुत ऽऋचः सामानि जज्ञिरे ।
            छन्दा ᳪ सि जज्ञिरे तस्माद्यजुस्तस्मादजायत ॥७॥

            तस्मादश्वा ऽअजायन्त ये के चोभयादतः ।
            गावो ह जज्ञिरे तस्मात्तस्माज्जाता ऽअजावयः ॥८॥


            तं यज्ञं बर्हिषि प्रौक्षन् पुरुषं जातमग्रतः ।
            तेन देवा ऽअयजन्त साध्या ऽऋषयश्च ये ॥९॥

            यत्पुरुषं व्यदधुः कतिधा व्यकल्पयन् ।
            मुखं किमस्यासीत्किम्बाहू किमूरू पादा ऽउच्येते ॥१०॥

            ब्राह्मणोऽस्य मुखमासीद्बाहू राजन्यः कृतः ।
            ऊरू तदस्य यद्वैश्यः पद्भ्या ᳪ शूद्रो ऽअजायत ॥११॥

            चन्द्रमा मनसो जातश्चक्षोः सूर्यो ऽअजायत ।
            श्रोत्राद्वायुश्च प्राणश्च मुखादग्निरजायत ॥१२॥


            नाभ्या ऽआसीदन्तरिक्ष ᳪ शीर्ष्णो द्यौः समवर्तत ।
            पद्भ्यां भूमिर्दिशः श्रोत्रात्तथा लोकाँ२ऽकल्पयन् ॥१३॥

            यत्पुरुषेण हविषा देवा यज्ञमतन्वत ।
            वसन्तोऽस्यासीदाज्यं ग्रीष्म ऽइध्मः शरद्धविः ॥१४॥

            सप्तास्यासन् परिधयस्त्रिः सप्त समिधः कृताः ।
            देवा यद्यज्ञं तन्वाना ऽअबध्नन् पुरुषं पशुम् ॥१५॥

            यज्ञेन यज्ञमयजन्त देवास्तानि धर्माणि प्रथमान्यासन् ।
            ते ह नाकं महिमानः सचन्त यत्र पूर्वे साध्याः सन्ति देवाः ॥१६॥


            अद्भ्यः सम्भृतः पृथिव्यै रसाच्च विश्वकर्मणः समवर्तताग्रे ।
            तस्य त्वष्टा विदधद्रूपमेति तन्मर्त्यस्य देवत्वमाजानमग्रे ॥१७॥

            वेदाहमेतं पुरुषं महान्तमादित्यवर्णं तमसः परस्तात् ।
            तमेव विदित्वाति मृत्युमत्येति नान्यः पन्था विद्यतेऽयनाय ॥१८॥

            प्रजापतिश्चरति गर्भे ऽअन्तरजायमानो बहुधा विजायते ।
            तस्य योनिम् परिपश्यन्ति धीरास्तस्मिन् ह तस्थुर्भुवनानि विश्वा ॥१९॥

            यो देवेभ्य ऽआतपति यो देवानां पुरोहितः ।
            पूर्वो यो देवेभ्यो जातो नमो रुचाय ब्राह्मये ॥२०॥


            रुचं ब्राह्मं जनयन्तो देवा ऽअग्रे तदब्रुवन् ।
            यस्त्वैवं ब्राह्मणो विद्यात्तस्य देवा ऽअसन् वशे ॥२१॥

            श्रीश्च ते लक्ष्मीश्च पत्न्यावहोरात्रे पार्श्वे नक्षत्राणि रूपमश्विनौ व्यात्तम् ।
            इष्णन्निषाणामुं म ऽइषाण । सर्वलोकं म ऽइषाण ॥२२॥
            `,
        end:`इति रुद्राष्टाध्यायी द्वितीयोऽध्यायः ॥२॥`
    },
    {
        title:`॥ तृतीयोऽध्यायः ॥`,
        des:`ॐ आशु: शिशानोवृषभो न भीमो घनाघन: क्षोभणश्चर्षणीनाम्।
            संक्रन्दनो ऽनिमिष एकवीरः शत ᳪ सेना ऽअजयत् साकमिन्द्रः॥१॥

            संक्रन्दनेनानिमिषेण जिष्णुना युत्कारेण दुश्च्यवनेन धृष्णुना।
            तदिन्द्रेण जयत तत्सहध्वं युधो नर ऽइषुहस्तेन वृष्णा॥२॥

            सऽइषुहस्तैः सनिषङ्गिभिर्वशी ᳪ स्रष्टा सयुधऽइन्द्रोगणेन।
            स ᳪ सृष्टजित्सोमपा बाहुशर्ध्युग्रधन्वा प्रतिहिताभिरस्ता॥३॥

            बृहस्पते परिदीया रथेन रक्षोहामित्राँ२ ऽअपबाधमानः।
            प्रभञ्जन्त्सेनाः प्रमृणो युधा जयन्नस्माकमेध्यविता रथानाम्॥४॥

            बलविज्ञाय स्थविरः प्रवीरः सहस्वान् वाजी सहमान ऽउग्रः।
            अभिवीरो ऽअभित्त्वा सहोजा जैत्रमिन्द्र रथमा तिष्ठ गोवित्॥५॥

            गोत्रभिदं गोविदं वज्रबाहुं जयन्तमज्म प्रमृणन्तमोजसा।
            इम ᳪ सजाता ऽअनुवीरयध्वमिन्द्र ᳪ सखायो ऽअनुस ᳪ रभध्वम्॥६॥

            अभिगोत्राणि सहसा गाहमानोऽदयो वीरः शतमन्युरिन्द्रः।
            दुश्च्यवनः पृतनाषाडयुध्योऽस्माक ᳪ सेना ऽअवतु प्र युत्सु॥७॥

            इन्द्र ऽआसान्नेता बृहस्पतिर्दक्षिणा यज्ञः पुर ऽएतु सोमः।
            देवसेनानामभिभञ्जतीनां जयन्तीनां मरुतोयन्त्वग्रम्॥७॥

            इन्द्रस्य वृष्णोवरुणस्य राज्ञऽआदित्यानां मरुता ᳪ शर्द्धऽउग्रम्।
            महामनसां भुवनच्यवानां घोषो देवानां जयतामुदस्थात्॥९॥

            उद्धर्षय मघवन्नायुधान्युत्सत्वनां मामकानां मना ᳪ सि।
            उद्वृत्रहन्वाजिनां वाजिनान्युद्रथानां जयतां यन्तु घोषाः॥१०॥

            अस्माकमिन्द्र: समृतेषु ध्वजेष्वस्माकं या ऽइषवस्ता जयन्तु।
            अस्माकं वीरा उत्तरे भवन्त्वस्माँ२ उ देवा ऽअवता हवेषु॥११॥

            अमीषां चित्तं प्रतिलोभयन्ती गृहाणाङ्गान्यप्वे परेहि।
            अभि प्रेहिनिर्दह हृत्सु शोकैरन्धेनामित्रास्तमसा सचन्ताम्॥१२॥

            अवसृष्टा परापत शरव्ये ब्रह्मस ᳪ शिते।
            गच्छामित्रान् प्रपद्यस्व मामीषां कंचनोच्छिषः॥१३॥

            प्रेता जयता नर ऽइन्द्रो वः शर्म यच्छतु।
            उग्रा वः सन्तु बाहवोनाधृष्या यथासथ॥१४॥

            असौ या सेना मरुतः परेषामभ्यैति न ऽओजसा स्पर्धमाना।
            तां गूहत तमसापव्रतेन यथाऽमी ऽअन्यो ऽअन्यं न जानन्॥१५॥

            यत्र बाणा: सम्पतन्ति कुमारा विशिखा ऽइव।
            तन्न इन्द्रोबृहस्पतिरदितिः शर्म्म यच्छतु विश्वाहा शर्म्मयच्छतु॥१६॥

            मर्म्माणि ते वर्म्मणा च्छादयामि सोमस्त्वा राजामृतेनानु वस्ताम्।
            उरोर्वरीयो वरुणस्ते कृणोतु जयन्तं त्वानु देवा मदन्तु ॥१७॥
            `,
        end:`इति रुद्राष्टाध्यायी तृतीयोऽध्यायः ॥३॥`
    },
    {
        title:`॥ चतुर्थोऽध्यायः ॥`,
        des:`ॐ विभ्राड् बृहत्पिबतु सोम्यं मध्वायुर्दधद्यज्ञपतावविह्रुतम् ।
            वातजूतो यो ऽअभिरक्षति त्मना प्रजाः पुपोष पुरुधा वि राजति ॥१॥

            उदुत्यं जातवेदसं देवं वहन्ति केतवः । दृशे विश्वाय सूर्यम् ॥२॥

            येना पावक चक्षसा भुरण्यन्तं जनाँ२ ऽअनु । त्वं वरुण पश्यसि ॥३॥

            दैव्यावध्वर्यू ऽआ गत ᳪ रथेन सूर्यत्वचा । मध्वा यज्ञ ᳪ समञ्जाथे ।
            तं प्रत्नथाऽयंवेनश्चित्रं देवानाम् ॥४॥

            तं प्रत्नथा पूर्वथा विश्वथेमथा ज्येष्ठतातिं बर्हिषद ᳪ स्वर्विदम् ।
            प्रतीचीनं वृजनं दोहसे धुनिमाशुं जयन्तमनु यासु वर्द्धसे ॥५॥

            अयं वेनश्चोदयत् पृश्निगर्भा ज्योतिर्जरायू रजसो विमाने ।
            इममपा ᳪ सङ्गमे सूर्यस्य शिशुं न विप्रा मतिभी रिहन्ति ॥६॥

            चित्रं देवानामुदगादनीकं चक्षुर्मित्रस्य वरुणस्याग्नेः ।
            आप्रा द्यावापृथिवी ऽअन्तरिक्ष ᳪ सूर्य ऽआत्मा जगतस्तस्थुषश्च ॥७॥

            आ न ऽइडाभिर्विदथे सुशस्ति विश्वानरः सविता देव ऽएतु ।
            अपि यथा युवानो मत्सथा नो विश्वं जगदभिपित्वे मनीषा ॥८॥

            यदद्य कच्च वृत्रहन्नुदगा ऽअभि सूर्य । सर्वं तदिन्द्र ते वशे ॥९॥

            तरणिर्विश्वदर्शतो ज्योतिष्कृदसि सूर्य । विश्वमा भासि रोचनम् ॥१०॥

            तत् सूर्यस्य देवत्वं तन्महित्वं मध्या कर्तोर्वितत ᳪ सञ्जभार ।
            यदेदयुक्त हरितः सधस्थादाद्रात्री वासस्तनुते सिमस्मै ॥११॥

            तन्मित्रस्य वरुणस्याभिचक्षे सूर्यो रूपं कृणुते द्योरुपस्थे ।
            अनन्तमन्यद्रुशदस्य पाजः कृष्णमन्यद्धरितः सम्भरन्ति ॥१२॥

            बण्महाँ२ ऽअसि सूर्य बडादित्य महाँ२ ऽअसि ।
            महस्ते सतो महिमा पनस्यतेद्धा देव महाँ२ ऽअसि ॥१३॥

            बट्सूर्य श्रवसा महाँ२ ऽअसि सत्रा देव महाँ२ ऽअसि ।
            मन्हा देवानामसुर्यः पुरोहितो विभु ज्योतिरदाभ्यम् ॥१४॥

            श्रायन्त ऽइव सूर्यं विश्वेदिन्द्रस्य भक्षत ।
            वसूनि जाते जनमान ऽओजसा प्रति भागन्न दीधिम ॥१५॥

            अद्या देवा ऽउदिता सूर्यस्य निर ᳪ हसः पिपृता निरवद्यात् ।
            तन्नो मित्रो वरुणो मामहन्तामदितिः सिन्धुः पृथिवी ऽउत द्यौः ॥१६॥

            आ कृष्णेन रजसा वर्त्तमानो निवेशयन्नमृतं मर्त्यं च ।
            हिरण्ययेन सविता रथेना देवो याति भुवनानि पश्यन् ॥१७॥`,
        end:`इति रुद्राष्टाध्यायी चतुर्थोऽध्यायः ॥४॥`
    },
    {
        title:`॥ पञ्चमोऽध्यायः ॥`,
        des:`रुद्रसूक्त
            ॐ नमस्ते रुद्द्र मन्न्यव ऽउतो त ऽइषवे नमः । बाहुब्भ्यामुत ते नमः ॥१॥

            या ते रुद्द्र शिवा तनूरघोराऽपापकाशिनी । तयानस्तन्न्वाशन्तमया गिरिशन्ताभिचाकशीहि ॥२॥

            यामिषुङ्गिरिशन्तहस्ते बिभर्ष्ष्यस्तवे । शिवाङ्गिरित्रताङ्कुरुमा हि ᳪ सीः पुरुषञ्जगत् ॥३॥

            शिवेनव्वचसात्त्वागिरिशाच्छाव्वदामसि । यथा नः सर्व्वमिज्जगदयक्ष्म ᳪ सुमनाऽअसत् ॥४॥

            अद्धयवोचदधिवक्क्ताप्प्रथमोदैव्व्योभिषक्।अहीँश्च्चसर्व्वाञ्जभयन्त्सर्व्वाश्च्चयातुधान्न्योऽधराचीः परासुव॥५॥


            असौयस्ताम्म्रोऽअरुणऽउतबब्भ्रुः सुमङ्गलः । ये चैन ᳪ रुद्द्राऽअभितोदिक्षुश्रिताः सहस्रशोऽवैषा ᳪ हेडऽईमहे ॥६॥

            असौ योऽवसर्प्पति नीलग्ग्रीवो व्विलोहितः। उतैङ्गोपाऽअदृश्श्रन्नदृश्श्रन्नुदहार्य्यः सदृष्टो मृडयातिनः ॥७॥

            नमोऽस्तु नीलग्ग्रीवाय सहस्राक्षायमीढुषे। अथोये ऽअस्य सत्त्वानोऽहन्तेब्भ्योऽकरन्नमः ॥८॥

            प्प्रमुञ्चधन्न्वनस्त्वमुभयोरार्त्क्ज्याम्। याश्च्च तेहस्तऽइषवः पराता भगवोव्वप ॥९॥

            व्विज्ज्यन्धनुः कपर्द्दिनो व्विशल्ल्यो बाणवाँ२ ऽउत । अनेशन्नस्ययाऽइषवऽआभुरस्यनिषङ्गधिः ॥१०॥

            याते हेतिर्म्मीढुष्ट्टमहस्ते बभूव ते धनुः। तयास्म्मान्निवश्श्वतस्त्वमयक्ष्म्मया परिभुज ॥११॥


            परि ते धन्न्वनोहेतिरस्म्मान्न्व्वृणक्तु व्विश्श्वतः। अथोयऽइषुधिस्तवारेऽअस्म्मन्निधेहितम् ॥१२॥

            अवतत्त्य धनुष्ट्व ᳪ सहस्राक्षशतेषुधे। निशीर्य्यशल्ल्यानाम्मुखा शिवोनः सुमना भव ॥१३॥

            नमस्तऽआयुधायानातताय धृष्ष्णवे। उभाब्भ्यामुतते नमो बाहुब्भ्यान्तव धन्न्वने ॥१४॥

            मानोमहान्तमुतमानोऽअर्ब्भकम्मानऽउक्षन्तमुतमानऽउक्षितम् । मानोव्वधीः पितरम्मोतमातरम्मानः प्प्रियास्तन्न्वो रुद्द्ररीरिषः ॥१५॥

            मानस्तोकेतनये मानऽआयुषिमानो गोषुमानोऽअश्वेषुरीरिषः। मानोव्वीरान्न्रुद्द्रभामिनोव्वधीर्हविष्म्मन्तः सदमित्त्वा हवामहे ॥१६॥

            नमोहिरण्ण्यबाहवे सेनान्न्ये दिशाञ्चपतये नमोनमो व्वृक्षेब्भ्यो हरिकेशेब्भ्यः पशूनाम्पतये नमोनमः शष्प्पिञ्जरायत्त्विषीमते पथीनाम्पतये नमोनमो हरिकेशायोपवीतिने पुष्ट्टानाम्पतये नमोनमो बब्भ्लुशाय ॥१७॥


            नमो बब्भ्लुशाय व्व्याधिनेऽन्नानांपतये नमोनमो भवस्यहेत्यै जगताम्पतये नमोनमो रुद्रायाततायिने क्षेत्राणाम्पतये नमोनमः सूतायाहन्त्यै व्वनानाम्पतये नमोनमो रोहिताय ॥१८॥

            नमो रोहितायस्थपतये व्वृक्षाणाम्पतये नमोनमो भुवन्तये व्वारिवस्कृतायौषधीनाम्पतये नमोनमो मन्त्रिणे व्वाणिजायकक्षाणाम्पतये नमोनमऽउच्चैर्घोषाया क्क्रन्दयते पत्तीनाम्पतये नमोनमः कृत्स्नायतया ॥१९॥

            नमः कृत्स्नायतया धावते सत्त्वनाम्पतये नमोनमः सहमानायनिव्व्याधिनऽआव्व्याधिनीनाम्पतये नमोनमो निषङ्गिणे ककुभायस्तेनानाम्पतये नमोनमो निचेरवे परिचरायारण्ण्यानाम्पतये नमोनमो वञ्चते ॥२०॥

            नमो वञ्चते परिवञ्चतेस्तायूनाम्पतये नमोनमो निषङ्गिणऽइषुधिमते तस्क्कराणाम्पतये नमोनमः सृकायिब्भ्यो जिघा ᳪ सद्ब्भ्योमुष्णताम्पतये नमोनमो ऽसिमद्ब्भ्योनक्क्तञ्चरद्ब्भ्यो व्विकृन्तानाम्पतये नमः ॥२१॥


            नमऽउष्ष्णीषिणे गिरिचराय कुलुञ्चानाम्पतये नमोनम ऽइषुमद्ब्भ्यो धन्न्वायिब्भ्यश्च वो नमोनमऽआतन्न्वानेब्भ्यः प्रतिदधानेब्भ्यश्च वोनमोनमऽआयच्छद्ब्भ्योऽस्यद्ब्भ्यश्चवो नमोनमो व्विसृजद्ब्भ्यः॥२२॥

            नमो व्विसृजद्ब्भ्यो व्विद्धयद्ब्भ्यश्च वो नमोनमः स्वपद्ब्भ्यो जाग्रद्ब्भ्यश्च वो नमोनमः शयानेब्भ्यऽआसीनेब्भ्यश्च वो नमोनमस्तिष्ठद्ब्भ्यो धावद्ब्भ्यश्च वो नमोनमः सभाब्भ्यः ॥२३॥

            नमः सभाब्भ्यः सभापतिब्भ्यश्च वो नमोनमो ऽश्वेब्भ्यो ऽश्वपतिब्भ्यश्च वो नमोनम ऽआव्व्याधिनीब्भ्यो व्विविद्धयन्तीब्भ्यश्च वो नमोनम उगणाब्भ्यस्तृ ᳪ हतीब्भ्यश्च वो नमोनमो गणेब्भ्यः ॥२४॥

            नमो गणेब्भ्यो गणपतिब्भ्यश्च वो नमोनमो व्व्रातेब्भ्यो व्व्रातपतिब्भ्यश्च वो नमोनमो गृत्सेब्भ्यो गृत्सपतिब्भ्यश्च वो नमोनमो व्विरूपेब्भ्यो व्विश्वरुपेब्भ्यश्च वो नमोनमः सेनाब्भ्यः ॥२५॥


            नमः सेनाब्भ्यः सेनानिब्भ्यश्च वो नमोनमो रथिब्भ्यो ऽअरथेब्भ्यश्च वो नमोनमः क्षत्तृब्भ्यः संग्रहीतृब्भ्यश्च वो नमोनमो महद्ब्भ्यो ऽअर्ब्भकेब्भ्यश्च्च वो नमः ॥२६॥

            नमस्तक्षब्भ्यो रथकारेब्भ्यश्च वो नमोनमः कुलालेब्भ्यः कर्म्मारेब्भ्यश्च वो नमोनमो निषादेब्भ्यः पुञ्जिष्ट्ठेब्भ्यश्च वो नमोनमः श्वनिब्भ्योमृगयुब्भ्यश्च वो नमोनमः श्वब्भ्यः ॥२७॥

            नमः श्वब्भ्यः श्वपतिब्भ्यश्च वो नमोनमो भवाय च रुद्राय च नमः शर्व्वाय च पशुपतये च नमो नीलग्ग्रीवाय च शितिकण्ठाय च नमः कपर्द्दिने ॥२८॥

            नमः कपर्द्दिने च व्व्युप्तकेशाय च नमः सहस्राक्षाय च शतधन्न्वने च नमो गिरिशयाय च शिपिविष्ट्टाय च नमो मीढुष्ट्टमाय चेषुमते च नमो ह्रस्वाय ॥२९॥

            नमो ह्रस्वाय च व्वामनाय च नमो बृहते च व्वर्षीयसे च नमो वृद्धाय च सवृधे च नमो ऽग्ग्र्याय च प्रथमाय च नम ऽआशवे ॥३०॥

            नमऽआशवे चाजिराजाय च नमः शीग्घ्र्याय च शीब्भ्याय च नमऽऊर्म्म्याय चावस्वन्न्याय च नमो नादेयाय च द्द्वीप्प्याय च ॥३१॥

            नमो जयेष्ठ्ठाय च कनिष्ठ्ठाय च नमः पूर्व्वजाय चापरजाय च नमो मद्ध्यमाय चापगल्ब्भाय च नमो जघन्न्याय च बुध्न्याय च नमः सोब्भ्याय ॥३२॥

            नमः सोब्भ्याय च प्रतिसर्य्याय च नमो याम्म्याय च क्षेम्म्याय च नमः श्लोक्क्याय चावसान्न्याय च नम उर्व्वर्याय च खल्ल्याय च नमो व्वन्न्याय ॥३३॥

            नमो व्वन्न्याय च कक्ष्याय च नमः श्रवाय च प्प्रतिश्रवाय च नम आशुषेणाय चाशुरथाय च नमः शूराय चावभेदिने च नमो बिल्मिने ॥३४॥

            नमो बिल्मिने च कवचिने च नमो व्वर्मिणे च व्वरूथिने च नमः श्रुताय च श्रुतसेनाय च नमो दुन्दुब्भ्याय चाहनन्न्याय च नमो धृष्ष्णवे ॥३५॥

            नमो धृष्ष्णवे च प्रमृशाय च नमो निषङ्गिणे चेषुधिमते च नमस्तीक्ष्णेषवे चायुधिने च नमः स्वायुधाय च सुधन्वने च ॥३६॥

            नमः स्रुत्याय च पथ्याय च नमः काट्टयाय च नीप्प्याय च नमः कुल्ल्याय च सरस्याय च नमो नादेयाय च व्वैशन्ताय च नमः कूप्प्याय ॥३७॥

            नमः कूप्प्याय चावट्टयाय च नमो व्वीद्ध्र्याय चातप्प्याय च नमो मेग्घ्याय च व्विद्द्युत्याय च नमो व्वर्ष्ष्याय चाव्वर्ष्ष्याय च नमो व्वात्त्याय ॥३८॥

            नमो व्वात्त्याय च रेष्म्म्याय च नमो व्वास्तव्व्याय च व्वास्तुपाय च नमः सोमाय च रुद्राय च नमस्ताम्राय चारुणाय च नमः शङ्गवे ॥३९॥

            नमः शङ्गवे च पशुपतये च नमऽउग्ग्राय च भीमाय च नमो ऽग्ग्रेव्वधाय च दूरेव्वधाय च नमो हन्त्रे च हनीयसे च नमो व्वृक्षेब्भ्यो हरिकेशेब्भ्यो नमस्ताराय ॥४०॥

            नमः शम्भवाय च मयोभवाय च नमः शङ्कराय च मयस्क्कराय च नमः शिवाय च शिवतराय च ॥४१॥

            नमः पार्य्याय चावार्य्याय च नमः प्प्रत्तरणाय चोत्तरणाय च नमस्तीर्त्थ्याय च कूल्ल्याय च नमः शष्प्याय च फ़ेन्न्याय च नमः सिकत्त्याय ॥४२॥

            नमः सिकत्त्याय च प्प्रवाह्य्याय च नमः कि ᳪ शिलाय च क्षयणाय च नमः कपर्द्दिने च पुलस्तये च नम ऽइरिण्ण्याय च प्प्रपत्थ्याय च नमो व्व्रज्ज्याय ॥४३॥

            नमो व्व्रज्ज्याय च गोष्ठयाय च नमस्तल्प्प्याय च गेह्य्याय च नमो हृदय्याय च निवेष्प्याय च नमः काट्टयाय च गह्वरेष्ठ्ठाय च नमः शुष्क्क्याय ॥४४॥

            नमः शुष्क्क्याय च हरित्याय च नमः पा ᳪ सव्व्याय च रजस्याय च नमो लोप्प्याय चोलप्प्याय च नमऽउर्व्व्याय च सूर्व्व्याय नमः पर्ण्णाय ॥४५॥

            नमः पर्ण्णाय च पर्ण्णशदाय च नमऽउद्गुरमाणाय चाभिघ्नते च नमऽआखिदते च प्प्रखिदते च नमऽइषुकृद्ब्भ्यो धनुष्कृद्ब्भयश्च वो नमोनमो वः किरिकेब्भ्यो देवाना ᳪ हृदयेब्भ्यो नमो व्विचिन्न्वत्केब्भ्यो नमो व्विक्षिणत्केब्भ्यो नमऽआनिर्हतेब्भ्यः ॥४६॥

            द्द्रापेऽअन्धसस्प्पतेदरिद्द्रनीललोहित । आसाम्प्रजानामेषाम्पशूनाम्माभेर्म्मारोङ्मोचनः किञ्चनाममत् ॥४७॥

            इमारुद्द्रायतवसे कपर्द्दिने क्षयद्वीराय प्प्रभरामहेमतीः । यथाषमसद्द्विपदे चतुष्ष्पदे व्विश्व्म्पुष्टङ्ग्रामेऽअस्म्मिन्ननातुरम् ॥४८॥

            याते रुद्द्र शिवा तनूः शिवा व्विश्वाहाभेषजी । शिवारुतस्य भेषजी तयानो मृडजीवसे ॥४९॥

            परिनोरुद्द्रस्य हेतिर्व्वृणक्क्तु परित्वेषस्य दुर्मतिरघायोः । अवस्त्थिरामघवद्ब्भ्यस्तनुष्ष्वमीड्ढवस्तोकाय तनयाय मृड ॥५०॥

            मीढुष्ट्टमशिवतमशिवो नः सुमना भव। परमे व्वृक्षऽआयुधन्निधाय कृत्तिंव्वसानऽआचरपिनाकम्बिब्भ्रदागहि ॥५१॥

            व्विकिरिद्द्रव्विलोहित नमस्तेऽअस्तुभगवः। यास्ते सहस्र ᳪ हेतयोऽन्न्यमस्म्मन्निव पन्तुताः ॥५२॥

            सहस्राणि सहस्रशोबाह्वोस्तव हेतयः। तासामीशानो भगवः पराचीनामुखाकृधि ॥५३॥

            असङ्ख्याता सहस्राणि ये रुद्द्राऽअधिभूम्म्याम्। तेषा ᳪ सहस्रयोजनेऽवधन्न्वानि तन्न्मसि ॥५४॥

            अस्म्मिन्न्महत्त्यर्ण्णवेऽन्तरिक्षे भवाऽअधि। तेषा ᳪ सहस्रयोजनेऽवधन्न्वानि तन्न्मसि ॥५५॥

            नीलग्ग्रीवाः शितिकण्ठा दिव ᳪ रुद्द्राऽउपश्श्रिताः। तेषा ᳪ सहस्रयोजनेऽवधन्न्वानि तन्न्मसि ॥५६॥

            नीलग्ग्रीवाः शितिकण्ठाः शर्व्वाऽअधः क्षमाचराः। तेषा ᳪ सहस्रयोजनेऽवधन्न्वानि तन्न्मसि ॥५७॥

            ये व्वृक्षेषु शष्पिञ्जरा नीलग्ग्रीवा व्विलोहिताः। तेषा ᳪ सहस्रयोजनेऽवधन्न्वानि तन्न्मसि ॥५८॥

            ये भूतानामधिपतयो व्विशिखासः कपर्द्दिनः। तेषा ᳪ सहस्रयोजनेऽवधन्न्वानि तन्न्मसि ॥५९॥

            ये पथाम्पथि रक्षयऽऐलबृदाऽआयुर्य्युधः। तेषा ᳪ सहस्रयोजनेऽवधन्न्वानि तन्न्मसि ॥६०॥

            ये तीर्त्थानि प्प्रचरन्ति सृकाहस्ता निषङ्गिणः। तेषा ᳪ सहस्रयोजनेऽवधन्न्वानि तन्न्मसि ॥६१॥

            येन्नेषु व्विविद्ध्यन्ति पात्रेषु पिबतो जनान्। तेषा ᳪ सहस्रयोजनेऽवधन्न्वानि तन्न्मसि ॥६२॥

            यऽएतावन्तश्च्च भूया ᳪ सश्च्च दिशो रुद्द्रा व्वितस्त्थिरे। तेषा ᳪ सहस्रयोजनेऽवधन्न्वानि तन्न्मसि ॥६३॥

            नमोऽस्तु रुद्द्रेब्भ्यो ये दिवि येषाम्व्वातऽइषवः। तेब्भ्यो दशप्राचीर्द्दश दक्षिणादशप्प्रतीचीर्द्दशोदीचीर्द्दशोर्द्ध्वाः। तेब्भ्यो नमो ऽअस्तु तेनोऽवन्तु तेनो मृडयन्तु ते यन्द्विष्म्मो यश्च्च नो द्वेष्ट्टि तमेषाञ्जम्भेदद्धमः ॥६४॥

            नमोऽस्तु रुद्द्रेब्भ्यो येऽन्तरिक्षे येषाम्व्वर्षमिषवः तेब्भ्यो दशप्राचीर्द्दशदक्षिणा दशप्प्रतीचीर्द्दशोदीचीर्द्दशोर्द्ध्वाः। तेब्भ्यो नमो ऽअस्तु तेनोऽवन्तु तेनो मृडयन्तु ते यन्द्विष्म्मो यश्च्च नो द्वेष्ट्टि तमेषाञ्जम्भेदद्धमः ॥६५॥

            नमोऽस्तु रुद्द्रेब्भ्यो ये पृथिव्यां येषामन्न मिषवः। तेब्भ्यो दशप्राचीर्द्दशदक्षिणा दशप्प्रतीचीर्द्दशोदीचीर्द्दशोर्द्ध्वाः तेब्भ्यो नमो ऽअस्तु तेनो ऽवन्तु तेनो मृडयन्तु ते यन्द्विष्म्मो यश्च्च नो द्वेष्ट्टि तमेषाञ्जम्भेदद्धमः ॥६६॥
            `,
        end:`इति रुद्राष्टाध्यायी पञ्चमोऽध्यायः ॥५॥`
    },
    {
        title:`॥ षष्ठोऽध्यायः ॥`,
        des:`ॐ वय ᳪ सोमव्रते तव मनस्तनूषु बिभ्रतः ॥ प्रजावन्तः सचेमहि ॥१॥
            एष ते रुद्रभागः सहस्वस्राऽम्बिकया तं जुषस्व स्वाहैष ते रुद्रभाग ऽआखुस्ते पशुः ॥२॥

            अव रुद्रमदीमह्यव देवं त्र्यम्बकम् ॥
            यथा नो वस्यसस्करद्यथा नः श्रेयसस्करद्यथा नो व्यवसायात् ॥३॥

            भेषजमसि भेषजं गवेऽश्वाय पुरुषाय भेषजम् । सुखं मेषाय मेष्यै ॥४॥

            त्र्यम्बकं यजामहे सुगन्धिं पुष्टिवर्द्धनम् । उर्वारुकमिव बन्धनान्मृत्योर्मुक्षीय माऽमृतात् ।
            त्र्यम्बकं यजामहे सुगन्धिं पतिवेदनम् । उर्वारुकमिव बन्धनादितो मुक्षीय मामुतः ॥५॥

            एतत्ते रुद्राऽवसं तेन परो यमूजवतोऽतीहि ॥
            अवतत धन्वा पिनाकवासः कृत्तिवासा ऽअहि ᳪ सन्नः शिवोऽतीहि ॥६॥

            त्र्यायुषं जमदग्ने कश्यपस्य त्र्यायुषम् ॥ यद्देवेषु त्र्यायुषं तन्नोऽअस्तु त्र्यायुषम् ॥७॥

            शिवो नामाऽसि स्वधितिस्ते पिता नमस्तेऽअस्तु मा मा हि ᳪ सीः ॥
            निवर्त्तयाम्यामुषेऽन्नाद्याय प्रजननाय रायस्पोषाय सुप्रजास्त्वाय सुवीर्याय ॥८॥
            `,
        end:`इति रुद्राष्टाध्यायी षष्ठोऽध्यायः ॥६॥`
    },
    {
        title:`॥ सप्तमोऽध्यायः ॥`,
        des:`ॐ उग्नश्च भीमश्च ध्वान्तश्च धुनिश्च । सासह्वाँश्चाभियुग्वा च विक्षिपः स्वाहा ॥१॥

            अग्नि ᳪ हृदयेनाशनि ᳪ हृदयाग्रेण पशुपतिं कृत्स्नहृदयेन भवं यक्ना ॥ शर्वं मतस्नाभ्यामीशानं मन्युना महादेवमन्तः पर्शव्येनोग्रं देवं वनिष्ठुना वसिष्ठहनुः शिङ्गीनि कोश्याभ्याम् ॥२॥

            उग्रँल्लोहितेन मित्र ᳪ सौव्रत्येन रुद्रं दौव्रत्येनेन्द्रं प्रक्रीडेन मरुतो बलेन साध्यान्प्रमुदा ।
            भवस्य कण्ठ्य ᳪ रुद्रस्यान्तः पार्श्व्यं महादेवस्य यकृच्छर्वस्य वनिष्ठुः पशुपतेः पुरीतत् ॥३॥

            लोमभ्यः स्वाहा लोमभ्यः स्वाहा त्वचे स्वाहा त्वचे स्वाहा लोहिताय स्वाहा लोहिताय स्वाहा मेदोभ्यः स्वाहा मेदोभ्यः स्वाहा ॥ मा ᳪ सेभ्यः स्वाहा मा ᳪ सेभ्यः स्वाहा स्नावभ्यः स्वाहा स्नावभ्यः स्वाहास्थभ्यः स्वाहास्थभ्यः स्वाहा मज्जभ्यः स्वाहा मज्जभ्यः स्वाहा ॥ रेतसे स्वाहा पायवे स्वाहा ॥४॥


            आयासाय स्वाहा प्रायासाय स्वाहा संयासाय स्वाहा वियासाय स्वाहोद्यासाय स्वाहा ॥ शुचे स्वाहा शोचते स्वाहा शोचमानाय स्वाहा शोकाय स्वाहा ॥५॥

            तपसे स्वाहा तप्यते स्वाहा तप्यमानाय स्वाहा तप्ताय स्वाहा घर्माय स्वाहा ॥ निष्कृत्यै स्वाहा प्रायश्चित्यै स्वाहा भेषजाय स्वाहा ॥६॥

            यमाय स्वाहाऽन्तकाय स्वाहा मृत्यवे स्वाहा ॥ ब्रह्मणे स्वाहा ब्रह्महत्यायै स्वाहा विश्वेभ्यो देवेभ्यः स्वाहा द्यावापृथिवीभ्या ᳪ स्वाहा ॥७॥
            `,
        end:`इति रुद्राष्टाध्यायी सप्तमोऽध्यायः ॥७॥`
    },
    {
        title:`॥ अष्टमोऽध्यायः ॥`,
        des:`ॐ व्वाजश्च्च मे प्प्रसवश्च्च मे प्प्रयतिश्च्च मे प्प्रसितिश्च्च मे धीतिश्च्च मे क्क्रतुश्च्च मे स्वरश्च्च मे श्श्लोकश्च्च मे श्श्रवश्च्च मे श्श्रुतिश्च्च मे ज्ज्योतिश्च्च मे स्वश्च्च मे यज्ञेन कल्प्पन्ताम् ॥१॥

            प्प्राणश्च्च मे पानश्च्च मे व्व्यानश्च्च मे सुश्च्च मे चित्तञ्चमऽआधीतञ्च मे व्वाक्चमे मनश्चमे चक्षुश्च्च मे श्रोत्रञ्चमे दक्षश्च्च मे बलञ्चमे यज्ञेन कल्प्पन्ताम् ॥२॥

            ओजश्च्च मे सहश्च्च मऽआत्मा च मे तनूश्च्च मे शर्म्म च मे व्वर्म्म च मेऽङ्गानि मेऽस्थीनि च मे परू ᳪ षि च मे शरीराणि च मऽआयुश्च्च मे जरा च मे यज्ञेन कल्प्पन्ताम् ॥३॥

            ज्ज्यैष्ठयञ्च मऽआधिपत्त्यञ्च मे मन्न्युश्च मे भामश्च्च मेऽमश्च्च मेऽम्भश्च्च मे जेमा च मे महिमा च मे व्वरिमा च मे प्प्रथिमा च मे व्वर्षिमा च मे द्द्राघिमा च मे व्वृद्धञ्च मे व्वृद्धिश्च्च मे यज्ञेन कल्प्पन्ताम् ॥४॥


            सत्त्यञ्च मे श्श्रद्धा च मे जगच्च मे धनञ्च मे महश्च्च मे क्क्रीडा च मे मोदश्च्च मे जातञ्च मे जनिष्ष्यमाणञ्च मे सूक्क्तञ्च मे सुकृतञ्च मे यज्ञेन कल्प्पन्ताम् ॥५॥

            ऋतञ्च मेऽमृतञ्च मेयक्ष्मञ्च मे नामयच्च मे जीवातुश्च्च मे दीर्घायुत्त्वञ्च मेनमित्रञ्च मेऽभयञ्च मे सुखञ्च मे शयनञ्च मे सूषाश्च्च मे सुदिनञ्च मे यज्ञेन कल्प्पन्ताम् ॥६॥

            यन्ता च मे धर्त्ता च मे क्षेमश्च्च मे धृतिश्च मे व्विश्श्वञ्च मे महश्श्च्च मे संव्विच्च मे ज्ञात्रञ्च मे सूश्च्च मे प्रसूश्च्च मे सीरञ्च मे लयश्च्च मे यज्ञेन कल्प्पन्ताम् ॥७॥

            शञ्च मे मयश्च्च मे प्प्रियञ्च मेऽनुकामश्च्च मे कामश्च्च मे सौमनसश्च्च मे भगश्च्च मे द्द्रविणञ्च मे भद्द्रञ्च मे श्श्रेयश्च्च मे व्वसीयश्च्च मे यशश्च्च मे यज्ञेन कल्प्पन्ताम् ॥८॥


            ऊर्क्क्च मे सूनृता च मे पयश्च्च मे रसश्च्च मे घृतञ्च मे मधु च मे सग्ग्धिश्च्च मे सपीतिश्च्च मे कृषिश्च्च मे व्वृष्ट्टिश्च्च मे जैत्रञ्च मऽऔद्भिद्यञ्च मे यज्ञेन कल्प्पन्ताम् ॥९॥

            रयिश्च्च मे रायश्च्च मे पुष्टञ्च मे पुष्ट्टिश्च्च मेव्विभु च मे प्प्रभु च मे पूर्णञ्च पूर्ण्णतरञ्च मे कुयवञ्च मेऽक्षितञ्च मेऽन्नञ्च मेऽक्षुच्च मे यज्ञेन कल्प्पन्ताम् ॥१०॥

            व्वित्तञ्च मे व्वेद्यञ्च मे भूतञ्च मे भविष्ष्यच्च मे सुगञ्च मे सुपत्थ्यञ्च मऽऋद्धञ्च मऽऋद्धिश्च्च मे क्लृपतञ्च मे क्लृप्तिश्च्च मे मतिश्च्च मे सुमतिश्च्च मे यज्ञेन कल्प्पन्ताम् ॥११॥

            व्व्रीहयश्च्च मे यवाश्च्च मे माषाश्च्च मे तिलाश्च्च मे मुद्गाश्च्च मे खल्ल्वाश्च्च मे प्रियङ्गवश्च्च मेऽणवश्च्च मे श्यामाकाश्च्च मे नीवाराश्च्च मे गोधूमाश्च्च मे मसूराश्च्च मे यज्ञेन कल्प्पन्ताम् ॥१२॥


            अश्म्मा च मे मृत्तिका च मे गिरयश्च्च मे पर्व्वताश्च्च मे सिकताश्च्च मे व्वनस्प्पतयश्च्च मे हिरण्ण्यञ्च मे यश्च्च मे श्यामञ्च मे लोहञ्च मे सीसञ्च मे त्रपु च मे यज्ञेन कल्ल्पन्ताम् ॥१३॥

            अग्ग्निश्च्च मऽआपश्च्च मे व्वीरुधश्च्च मऽओषधयश्च्च मे कृष्ट्टपच्च्याश्च्च मेऽकृष्ट्टपच्च्याश्च्च मे ग्ग्राम्म्याश्च्च मे पशवऽआरण्ण्याश्च्च मे व्वित्तञ्च मे व्वित्तिश्च्च मे भूतञ्च मे भूतिश्च्च मे यज्ञेन कल्प्पन्ताम् ॥१४॥

            व्वसु च मे व्वसतिश्च्च मे कर्म्म च मे शक्तिश्च्च मऽर्थश्च्च मऽएमश्च्च मऽइत्या च मे गतिश्च्च मे यज्ञेन कल्प्पन्ताम् ॥१५॥

            अग्ग्निश्च मऽइन्द्रश्च्च मे सोमश्च मऽइन्द्रश्च्च मेसविताश्च मऽइन्द्रश्च्च मेसरस्वतीश्च मऽइन्द्रश्च्च मे पूषा च मऽइन्द्रश्च्च मे बृहस्प्पतिश्च मऽइन्द्रश्च्च मे यज्ञेन कल्प्पन्ताम् ॥१६॥

            मित्रश्च मऽइन्द्रश्च्च मे व्वरुणश्च मऽइन्द्रश्च्च मे धाता च मऽइन्द्रश्च्च मे त्त्वष्ट्टा च मऽइन्द्रश्च्च मे मरुतश्च मऽइन्द्रश्च्च मे व्विश्श्वे च मे देवाऽइन्द्रश्च्च मे यज्ञेन कल्प्पन्ताम् ॥१७॥

            पृथिवी च मऽइन्द्रश्च्च मेऽन्तरिक्षञ्च मऽइन्द्रश्च्च मे द्यौश्च मऽइन्द्रश्च्च मे समाश्च मऽइन्द्रश्च्च मे नक्षत्राणि च मऽइन्द्रश्च्च मे दिशश्च मऽइन्द्रश्च्च मे यज्ञेन कल्प्पन्ताम् ॥१८॥

            अ ᳪ शुष्च्च मे रश्म्मिश्च्च मेऽदाब्भ्यश्च्च मेऽधिपतिश्च्च म ऽउपा ᳪ शुष्च्च मेऽन्तर्य्यामश्च्च मऽऐन्द्रवायवश्च्च मे मैत्रावरुणश्च्च म ऽआश्विनश्च्च मे प्रतिप्रस्थानश्च्च मे शुक्क्रश्च्च मे मन्थी च मे यज्ञेन कल्प्पन्ताम् ॥१९॥

            आग्ग्रयणश्च मे व्वैश्वदेवश्च्च मे महावैश्वदेवश्च्च मे मरुत्त्वतीयाश्च्च मे निष्क्केवल्ल्यश्च्च मे सावित्रश्च्च मे सारस्वतश्च्च मे पात्क्नीवतश्च्च मे हारियोजनश्च्च मे यज्ञेन कल्प्पन्ताम् ॥२०॥

            स्रुचश्च्च मे चमसाश्च्च मे व्वायव्यानि च मे द्रोणकलशश्च्च मे ग्ग्रावाणश्च्च मेऽधिषवने च मे पूतभृच्च मऽआधवनीयश्च्च मे व्वेदिश्च्च मे बर्हिष्च्च मेऽवभृथश्च्च मे स्वगाकारश्च्च मे यज्ञेन कल्प्पन्ताम् ॥२१॥

            अग्ग्निश्च्च मे घर्म्मश्च्च मेऽर्क्कश्च्च मे सूर्य्यश्च्च मे प्प्राणश्च्च मे ऽश्वमेधश्च्च मे पृथिवी च मेऽदितिश्च्च मे दितिश्च्च मे द्यौश्च्च मे ऽङ्गुलयः शक्क्वरयो दिशश्च्च मे यज्ञेन कल्प्पन्ताम् ॥२२॥

            व्रतञ्च मऽऋतवश्च्च मे तपश्च्च मे संव्वत्सरश्च्च मेऽहोरात्रेऽऊर्वष्टठीवे बृहद्रथन्तरे च मे यज्ञेन कल्प्पन्ताम् ॥२३॥

            एका च मे तिस्रश्च मे तिस्रश्च मे पञ्च मे पञ्च मे सप्त च मे सप्त च मे नव च मे नव च मऽएकादश च मऽएकादश च मे त्रयोदश च मे त्रयोदश च मे पञ्चदश च मे पञ्चदश च मे सप्तदश च मे सप्तदश च मे नवदश च मे नवदश च मऽएकवि ᳪ शतिश्च्च म ऽएकवि ᳪ शतिश्च्च मे त्रयोवि ᳪ शतिश्च्च मे त्रयोवि ᳪ शतिश्च्च मे पञ्चवि ᳪ शतिश्च्च मे पञ्चवि ᳪ शतिश्च्च मे सप्तवि ᳪ शतिश्च्च मे सप्तवि ᳪ शतिश्च्च मे नववि ᳪ शतिश्च्च मे नववि ᳪ शतिश्च्च म ऽएकत्रि ᳪ शश्च्च म ऽएकत्रि ᳪ शश्च्च मे त्रयस्त्रि ᳪ शश्च्च मे यज्ञेन कल्प्पन्ताम् ॥२४॥

            चतस्रश्च मेऽष्टौ च मेऽष्टौ च मे द्वादश च मे द्वादश च मे षोडश च मे षोडश च मे वि ᳪ शतिश्च मे वि ᳪ शतिश्च मे चतुर्वि ᳪ शतिश्च मे चतुर्वि ᳪ शतिश्च मेऽष्टावि ᳪ शतिश्च मे मेऽष्टावि ᳪ शतिश्च मे द्वात्रि ᳪ शच्च मे द्वात्रि ᳪ शच्च मे षट्त्रि ᳪ शच्च मे षट्त्रि ᳪ शच्च मे चत्त्वारि ᳪ शच्च मे चत्त्वारि ᳪ शच्च मे चतुश्चत्त्वारि ᳪ शच्च मे चतुश्चत्त्वारि ᳪ शच्च मेऽष्टा चत्त्वारि ᳪ शच्च मे यज्ञेन कल्प्पन्ताम् ॥२५॥

            त्र्यविश्च मे त्र्यवी च मे दित्त्यवाट्च मे दित्त्यौही च मे पञ्चाविश्च्च मे पञ्चावी च मे त्रिवत्त्सश्च्च त्रिवत्त्सा च मे तुर्य्यवाट्च मे तुर्य्यौही च मे यज्ञेन कल्प्पन्ताम् ॥२६॥

            पष्ठ्ठवाट्च मे पष्ठ्ठौही च मऽउक्षा च मे व्वशा च मऽऋषभश्च्च मे व्वेहच्च मेऽनड्वाँश्च्च मे धेनुश्च्च मे यज्ञेन कल्प्पन्ताम् ॥२७॥

            व्वाजाय स्वाहा प्प्रसवाय स्वाहाऽपिजाय स्वाहा क्क्रतवे स्वाहा व्वसवे स्वाहाऽहर्प्पतये स्वाहा ह्नेमुग्धाय स्वाहा मुग्धायवैन ᳪ शिनाय स्वाहा व्विन ᳪ शिनऽआन्त्यायनाय स्वाहान्त्याय भौवनाय स्वाहा भुवनस्यपतये स्वाहाधिपतये स्वाहा प्प्रजापतये स्वाहा॥ इयन्तेराण्म्मित्राय यन्तासि यमन ऽऊर्ज्जे त्त्वा व्वृष्टयै त्त्वा प्प्रजानांत्त्वाधिपत्त्याय ॥२८॥

            आयुर्य्यज्ञेन कलप्पतां प्प्राणो यज्ञेन कलप्पताञ्चक्षुर्य्यज्ञेन कलप्पता ᳪ श्रोत्रं यज्ञेन कलप्पतां व्वाग्ग्यज्ञेन कलप्पताम्मनो यज्ञेन कलप्पतामात्मा यज्ञेन कलप्पतां ब्रह्मा यज्ञेन कलप्पताञ्ज्योतिर्य्यज्ञेन कलप्पता ᳪ स्वर्य्यज्ञेन कलप्पतां पृष्ठंय्यज्ञेन कलप्पतां यज्ञो यज्ञेन कलप्पताम्॥ स्तोमश्च्च यजुश्च्चऽऋक्च साम च बृहच्च रथन्तरञ्च॥ स्वर्द्देवाऽअगन्न्मामृताऽअभूमप्प्रजापतेः प्प्रजाऽअभूमव्वेट्स्वाहा ॥२९॥
            `,
        end:`इति रुद्राष्टाध्यायी अष्टमोऽध्यायः ॥८॥`
    },
    {
        title:`॥ शान्त्याऽध्याय ॥`,
        des:`ॐ ऋचंव्वाचम्प्रपद्ये मनोयजु: प्रपद्ये सामप्प्राणम्प्रपद्ये चक्षु: श्रोत्रम्प्रपद्ये । व्वागोजः सहौजोमयि प्प्राणापानौ ॥१॥

            यन्मेच्छिद्द्रञ्चक्षुषो हृदयस्य मनसोव्वाति तृण्णम्बृहस्प्पतिर्म्मे तद्दधातु । शन्नो भवतु भुवनस्ययस्प्पति: ॥२॥

            भूर्ब्भुवः स्वः तत्सवितुर्व्वरेण्यम्भर्गोदेवस्य धीमहि । धियो यो नः प्प्रचोदयात् ॥३॥

            कयानश्च्चित्रऽ आभुवदूती सदावृधः सखा । कयाशचिष्ठ्ठयाव्वृता ॥४॥

            कस्त्वासत्योमदानाम्म ᳪ हिष्ठ्ठोमत्सदन्धसः । दृढाचिदारुजेव्वसु ॥५॥

            अभीषुणः सखीनामविताजरितृणाम् । शतम्भवास्यूतिभि: ॥६॥


            कयात्वन्नऽऊत्याभिप्प्रमन्दसेव्वृषन् । कयास्तोतृब्भ्यऽआभर ॥७॥

            इन्द्रोव्विश्श्वस्यराजति । शन्नोऽअस्तुद्विपदे शञ्चतुष्ष्पदे ॥८॥

            शन्नोमित्रः शम्व्वरुणः शन्नो भवत्वर्य्यमा । शन्नऽइन्द्रो बृहस्प्पति शन्नोव्विष्ष्णुरुरुक्क्रमः ॥९॥

            शन्नोव्वातः पवता ᳪ शन्नस्तपतुसूर्य्यः । शन्नः कनिक्क्रदद्देवः पर्ज्जन्न्योऽअभिवर्षतु ॥१०॥

            अहानि शम्भवन्तुनः श ᳪ रात्री: प्प्रतिधीयताम् । शन्नऽइन्द्राग्ग्नी भवतामवोभि: शन्नऽइन्द्रावरुणारातहव्व्या । शन्न ऽइन्द्रापूषणा व्वाजसातौशमिन्द्रा सोमासुवितायशंय्यो: ॥११॥

            शन्नोदेवीरभिष्ट्टयऽआपो भवन्तु पीतये । शंय्योरभिस्रवन्तुनः ॥१२॥


            स्योना पृथिविनोभवानृक्षरानिवेशनी । यच्छानः शर्म्म सप्प्रथा: ॥१३॥

            आपोहिष्ठ्ठामयोभुवस्तान ऽऊर्ज्जेदधातन । महेरणाय चक्षसे ॥१४॥

            यो वः शिवतमोरसस्तस्यभाजयतेहनः । उशतीरिवमातरः ॥१५॥

            तस्माऽअरङ्गमामवो यस्यक्षयायजिन्वथ । आपोजनयथा च नः ॥१६॥

            द्यौ: शान्तिरन्तरिक्ष ᳪ शान्ति: पृथिवीशान्तिरापः शान्तिरोषधयः शान्ति: । व्वनस्प्पतयः शान्तिर्व्विश्वेदेवा: शान्तिर्ब्ब्रह्म शान्ति: सर्व्व ᳪ शान्ति: शान्तिरेवशान्ति: सामा शान्तिरेधि ॥१७॥

            दृतेदृ ᳪ हमामित्रस्यमाचक्षुषा सर्व्वानि भूतानि समीक्षन्ताम् । मित्रस्याहञ्चक्षुषा सर्व्वानि भूतानिसमीक्षे । मित्रस्य चक्षुषा समीक्षामहे ॥१८॥


            दृतेदृ ᳪ हमा । ज्ज्योक्क्तेसन्दृशि जीव्व्यासञ्ज्योक्क्ते सन्दृशिजीव्व्यासम् ॥१९॥

            नमस्ते हरसे शोचिषे नमस्तेऽअस्त्वर्च्चिषे । अन्न्याँस्तेऽअस्म्मत्तपन्तुहेतयः पावकोऽअस्म्मब्भ्य ᳪ शिवोभव ॥२०॥

            नमस्तेऽअस्तु व्विद्युते नमस्तेस्तनयित्नवे । नमस्ते भगवन्नस्तु यतः स्वः समीहसे ॥२१॥

            यतोयतः समीहसे ततोनोऽअभयङ्कुरु । शन्नः कुरुप्प्रजाब्भ्योऽभयन्नः पशुब्भ्यः ॥२२॥

            सुमित्रियानऽआपऽओषधयः सन्तुदुर्म्मित्रियास्तस्म्मै सन्तु योऽस्म्मान्द्वेष्ट्टि यञ्च वयन्द्विष्म्मः ॥२३॥

            तच्चक्षुर्द्देवहितम्पुरस्ताच्च्छुक्क्रमुच्चरत् । पश्येमशरदः शतञ्जीवेमशरदः शत ᳪ श्रृणुयामशरदः शतं प्रब्बाम शरदः शतमदीना: स्यामशरदः शतम्भूयश्च्च शरदः शतात् ॥२४॥
            `,
        end:`॥ इति रुद्राष्टाध्यायी शान्त्याऽध्याय ॥ `
    },
    {
        title:`॥ स्वस्तिप्रार्थनानाममंत्राऽध्याय ॥`,
        des:`ॐ स्वस्ति न इन्द्रो वृद्धश्रवाः स्वस्ति नः पूषा विश्ववेदाः ॥ स्वस्ति नस्तार्क्ष्योऽरिष्टनेमिः स्वस्ति नो बृहस्पतिर्दधातु ॥१॥

            ॐ पयः पृथिव्यां पय ओषधीषु पयो दिव्यन्तरिक्षे पयो धाः। पयस्वतीः प्रदिशः सन्तु मह्यम् ॥२॥

            ॐ विष्णो रराटमसि विष्णोः श्नप्त्रेस्थो विष्ष्णोः स्यूरसि विष्णोर्ध्रुवोऽसि ॥ वैष्णवमसि वैष्णवे त्वा ॥३॥

            ॐ अग्निर्देवता वातौ देवता सूर्यो देवता चन्द्रमा देवता वसवो देवता रुद्रा देवताऽऽदित्या देवता मरुतो देवता विश्वेदेवा देवता बृहस्पतिर्देवतेन्द्रो देवता वरुणो देवता ॥४॥

            ॐ सद्योजातं प्रपद्यामि सद्योजाताय वै नमो नमः ॥ भवे भवे नातिभवे भवस्व मां भवोद्भयाय नमः ॥५॥

            वामदेवाय नमो ज्येष्ठाय नमः श्रेष्ठाय नमो रुद्राय नमः कालाय नमः कलविकरणाय नमः बलविकरणाय नमो बलाय नमो बलप्रमथनाय नमः सर्वभूतदमनाय नमो मनोन्मनाय नमः ॥६॥

            अघोरेभ्योऽथ घोरेभ्यो धोरघोरतरेभ्यः । सर्वेभ्यः सर्वशर्वेभ्यो नमस्तेऽस्तु रुद्ररूपेभ्यः ॥७॥

            तत्पुरुषाय विद्महे महादेवाय धीमहि । तन्नो रुद्रः प्रचोदयात् ॥८॥

            ईशानः सर्वविद्यानामीश्वरः सर्वभूतानाम् । ब्रह्माधिपतिर्ब्रह्मणोऽधिपतिर्ब्रह्मा शिवो मेऽस्तु सदाशिवोऽम् ॥९॥

            ॐ शिवोनामासि स्वधितिस्ते पिता नमस्तेऽस्तु मा मा हि ᳪ सीः ॥ निवर्तयाम्यायुषेऽन्नाद्याय प्रजननाय रायस्पोपाय सुप्रजास्त्वाय सुवीर्याय ॥१०॥

            ॐ विश्वानि देव सवितर्दुरितानि परासुव ॥ यद्भद्रं तन्न आसुव ॥११॥

            ॐ द्यौः शान्तिरन्तरिक्षः शान्तिः पृथिवी शान्तिरापः शान्तिरोषधयः शान्तिः । वनस्पतयः शान्तिर्विश्वेदेवाः शान्तिर्ब्रह्मशान्तिः सर्व ᳪ शान्तिः शान्तिरेव शान्तिः सामाशान्तिरेधि ॥१२॥
            `,
        end:`॥ इति रुद्राष्टाध्यायी शान्त्याऽध्याय ॥`
    }
]

export {adhiDevAwahan, pratydhiDevAvahan,rudraAstadhyayi}