
const yoginiAvahan=[
    {
        title:`महाकाली`,
        des1:`ॐ अम्बेऽअम्बकेऽम्बालिके न मा नयति कश्चन ॥ ससस्त्यश्वकः सुभद्रिकां काम्पीलवासिनीम् ॥`,
        des2:`ॐ भूर्भुवः स्वः महाकालि इहाऽगच्छ इह तिष्ठ ।। ॐ भूर्भुवः स्वः महाकाल्यै नमः॥`
    },
    {
        title:`महालक्ष्मी`,
        des1:`ॐ श्रीश्च ते लक्ष्मीश्च पत्न्यावहोरात्त्रे पार्श्वे नक्षत्राणि रूपमश्विनौ व्यात्तम् ॥ इष्णन्निषाणामुम्मऽइषाण सर्वलोकं मऽइषाण ॥`,
        des2:`ॐ भूर्भुवः स्वः महालक्ष्मि इहाऽगच्छ इह तिष्ठ।। ॐ भूर्भुवः स्वः महालक्ष्यै नमः ॥`
    },
    {
        title:`महासरस्वती`,
        des1:`ॐ पावका नः सरस्वती व्वाजैभिर्वाजिनीवती ॥ यज्ञं व्यष्टु धिवावसुः ॥ ॐ भूर्भुवः स्वः महासरस्वत्यै नमः।`,
        des2:`ॐ भूर्भुवः स्वः महासरस्वति इहाऽगच्छ इह तिष्ठ॥ ॐ भूर्भुवः स्वः महासरस्वत्यै नमः॥`
    },
    {
        title:`१. दिव्ययोगिनी`,
        des1:`ॐ तमीशानं जगतस्तस्थुषस्पतिं धियञ्जिन्वमवसे हूमहे वयम् । पूषा नो यथा वेदसामसद्वृधे रक्षिता पायुरदब्धः स्वस्तये॥`,
        des2:`ॐ भूर्भुवः स्वः दिव्ययोगिनि इहागच्छ इह तिष्ठ।। ॐ भूर्भुवः स्वः दिव्ययोगिन्यै नमः॥`
    },
    {
        title:`२. महायोगिनी`,
        des1:`ॐ आ ब्रह्मण ब्राह्मणो बह्मवर्चसी जायतामाराष्ट्रे राजन्यः शूर इषव्योति व्याधी महारथो जायताम्‌ दोग्ध्री धेनुर्वोढानड्वानाशुः सप्तिः पुरंध्रिर्योषा जिष्णूरथेष्ठाः सभेयो युवाऽस्य यजमानस्य वीरो जायतां निकामे निकामे नः पर्जन्यो वर्षतु फलवत्यो न ओषधयः पच्यन्ताम्‌ योगक्षेमो नः कल्पताम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः महायोगिनि इहागच्छ इह तिष्ठ।। ॐ भूर्भुवः स्वः महायोगिन्यै नमः॥`
    },
    {
        title:`३. सिद्धयोगिनी`,
        des1:`ॐ महाँ२ ऽइन्द्रो वज्रहस्तः षोडशी शर्म्म यच्छतु । हन्तु पाप्मानं योनिर्महेन्द्राय त्वा ॥ ॐ भूर्भुवः स्वः सिद्धयोगिनी इहागच्छ इह तिष्ठ।।`,
        des2:`ॐ भूर्भुवः स्वः सिद्धयोगिनि इहागच्छ इह तिष्ठ।। ॐ भूर्भुवः स्वः सिद्धयोगिन्यै नमः॥`
    },
    {
        title:`४. माहेश्वरी`,
        des1:`ॐ आयङ्गौः पृश्निरक्रमीदसदन्मातरं पुरः । पितरञ्च प्रयन्त्स्वः ॥`,
        des2:`ॐ भूर्भुवः स्वः माहेश्वरि इहागच्छ इह तिष्ठ।। ॐ भूर्भुवः स्वः माहेश्वर्यै नमः॥`
    },
    {
        title:`५. प्रेताक्षी`,
        des1:`ॐ आदित्यं गर्भं पयसा समङ्घ्रि हरसा मामि म ᳪ स्त्थाः शतायुषं कृणुहि धीयमानः ॥`,
        des2:`ॐ भूर्भुवः स्वः प्रेताक्षि इहागच्छ इह तिष्ठ।। ॐ भूर्भुवः स्वः प्रेताक्ष्यै नमः॥`
    },
    {
        title:`६. डाकिनी`,
        des1:`ॐ स्वर्णघर्मः स्वाहा स्वर्णार्कः स्वाहा स्वर्णशुक्रः स्वाहा स्वर्णज्योतिः स्वाहा स्वर्णसूर्यः स्वाहा।।`,
        des2:`ॐ भूर्भुवः स्वः डाकिनि इहागच्छ इह तिष्ठ।। ॐ भूर्भुवः स्वः डाकिन्यै नमः॥`
    },
    {
        title:`७. काली`,
        des1:`ॐ सत्यञ्च मे श्रद्धा च मे जगच्च मे धनञ्च मे व्विश्वञ्च मे महश्च मे क्रीडा च मे मोदश्च मे जातञ्च मे जनिष्यमाणञ्च मे सूक्तञ्च मे सुकृत मे यज्ञेन कल्प्यन्ताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः कालि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः काल्यै नमः॥`
    },
    {
        title:`८. कालरात्री`,
        des1:`ॐ भायै दार्व्वाहारं प्रभाया ऽअग्न्येधं बुध्नस्य वृष्टृपायाभिषेक्तारं व्वर्षिष्ठाय नाकाय परिवेष्टारं देवलोकाय पेशितारं मनुष्यलोकाय प्रकरितार ᳪ सर्व्वेब्भ्यो लोकेब्भ्य ऽउपसेक्तामव ऽॠत्यै व्वधायोपमन्थितारं मेधाय व्वासः पल्प्पूलीं प्रकामाय रजयित्त्रीम् ॥`,
        des2:`ॐ भूर्भुवः स्वः कालरात्रि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः कालरात्र्यै नमः॥`
    },
    {
        title:`९.  निशाकरी`,
        des1:`ॐ जिह्वां मे भेद्द्रं व्वाङ्महो मनो मन्युः स्वराड् भामः ॥ मोदाः प्रमोदा ऽअङ्गुलोरङ्गानि मित्त्रं मे सहः ॥`,
        des2:`ॐ भूर्भुवः स्वः निशाकरि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः निशाकर्यै नमः॥`
    },{
        title:`१०. हुङ्कारी`,
        des1:`ॐ हिङ्काराय स्वाहा हिङ्कृताय स्वाहा वक्रन्दते स्वाहोऽवक्कन्द्राय स्वाहा प्प्रोथते स्वाहा प्प्रप्पोथाय स्वाहा गन्धाय स्वाहा घ्राताय स्वाहा निविष्टाय स्वाहोपविष्टाय स्वाहा सन्दिताय स्वाहा वल्गते स्वाहासीनाय स्वाहा शयानाय स्वाहा स्वपते स्वाहा जाग्ग्रते स्वाहा कूजते स्वाहा प्प्रबुद्धाय स्वाहा व्विजृम्भमाणाय स्वाहा व्विचृत्ताय स्वाहा स ᳪ हानाय स्वाहोपस्थिताय स्वाहाऽयनाय स्वाहा प्प्रायणाय स्वाहा।।`,
        des2:`ॐ भूर्भुवः स्वः हुङ्कारि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः हुङ्कार्यै नमः॥`
    },
    {
        title:`११. सिद्धवेतालिका`,
        des1:`ॐ अग्निश्चमे घर्म्मश्चमेऽर्कश्च मे सूर्य्यश्चमे प्राणश्च मेऽश्वमेधश्चमे पृथिवी च मेऽदितिश्चमे दितिश्चमे द्यौश्चमेऽङ्गुल्यः शक्व्वरयो दिशश्चमे यज्ञेन कल्पन्ताम् ।।`,
        des2:`ॐ भूर्भुवः स्वः सिद्धिवैतालिके इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः सिद्धवैतालिकायै नमः॥`
    },
    {
        title:`१२. ह्रींकारी`,
        des1:`ॐ पूषन् तव व्व्रते व्वयं न रिष्येम कदाचन । स्तोतारस्तऽइहस्म्मसि ।।`,
        des2:`ॐ भूर्भुवः स्वः ह्रींकारि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः ह्रींकार्यै नमः॥`
    },
    {
        title:`१३. भूतडामर`,
        des1:`ॐ व्वेद्या व्वेदिः समाप्यते बर्हिषा बर्हिरिन्द्रियम् । यूपेन यूपऽआप्प्यते प्प्रणीतोऽअग्निऽग्निना ।।`,
        des2:`ॐ भूर्भुवः स्वः भूतडामरे इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः भूतडामरायै नमः॥`
    },
    {
        title:`१४. ऊर्ध्वकेशी`,
        des1:`ॐ अयमग्ग्निः सहस्रिणो व्वाजस्य शतिनस्प्पतिः ।मूर्द्धा कवी रयीणाम् ।। ॐ भूर्भुवः स्वः उर्ध्वकेशि इहागच्छ इह तिष्ठ।।`,
        des2:`ॐ भूर्भुवः स्वः ऊर्ध्वकेशिन्यै नमः॥`
    },
    {
        title:`१५. विरूपाक्षी`,
        des1:`ॐ इमम्मे व्वरुणश्श्रुधी हवमद्या च मृडय । त्वामवस्युराचके ॥ ॐ भूर्भुवः स्वः विरूपाक्षि इहागच्छ इह तिष्ठ।`,
        des2:`ॐ भूर्भुवः स्वः विरूपाक्ष्यै नमः॥`
    },
    {
        title:`१६. शुष्काङ्गी`,
        des1:`ॐ यमाय यमसूमथर्व्वभ्योऽवतोकाᳪ संव्वत्सराय पर्या॒यिणी परिवत्सरायाविजाता-मिदावत्सरायातीत्वरीमद्वित्सरायातिष्व्वद्वरीव्वत्सराय व्विजर्ज्जराᳪ सम्वत्सराय पलिक्नीमृभुब्भ्यो ऽजिनसन्ध ᳪ साद्ध्येब्भ्यश्च्चर्मम्नम्॥`,
        des2:`ॐ भूर्भुवः स्वः शुष्काङ्गि इहागच्छ इह तिष्ठ॥ ॐ भूर्भुवः स्वः शुष्काङ्ग्यै नमः ॥`
    },
    {
        title:`१७. नरभोजनी`,
        des1:`ॐ असियमो ऽअस्यादित्यो ऽवन्नसिषित्रितो गुह्येन व्व्रतेन ॥ असि सोमेन समया व्विपृक्त ऽआहुस्ते त्रीणि दिवि बन्धनानि ॥`,
        des2:`ॐ भूर्भुवः स्वः नरभोजनि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः नरभोजन्यै‌ नमः॥`
    },
    {
        title:`१८. फेत्कारी`,
        des1:`ॐ मित्त्रस्य चर्षणीधृतोऽवो देवस्य सानसि ॥ द्युम्नं चित्त्रश्र्श्रवस्तमम् ॥ ॐ भूर्भुवः स्वः फेत्कारि इहागच्छ इह तिष्ठ ॥`,
        des2:`ॐ भूर्भुवः स्वः फेत्कार्यै नमः॥`
    },
    {
        title:`१९. वीरभद्रा`,
        des1:`ॐ अग्रे बृहन्नुषसामूर्ध्वो ऽअस्त्थान्निर्ज्जगन्वान् तमसो ज्ज्योतिषागात् ॥ अग्निर्भानुना रुशता स्वङ्ग ऽआजातो व्विश्वा सद्मान्यप्प्रा ॥`,
        des2:`ॐ भूर्भुवः स्वः वीरभद्रे इहागच्छ इह तिष्ठ॥ ॐ भूर्भुवः स्वः वीरभद्रायै नमः ॥`
    },
    {
        title:`२०. धूम्राक्षी`,
        des1:`ॐ भगप्प्रणेतर्भग सत्यराधो भगे मान्धियमुदवाददन्नः । भग प्प्र नो जनय गोभिरश्वैर्भग प्प्र नृभिर्न्नृवन्तः स्याम ।।`,
        des2:`ॐ भूर्भुवः स्वः धूम्राक्षि इहागच्छ इह तिष्ठ॥ ॐ भूर्भुवः स्वः ध्रूम्राक्ष्यै नमः॥`
    },
    {
        title:`२१. कलहप्रिया`,
        des1:`ॐ सुप्रर्णोऽसि गरुत्माँस्त्रिवृत्ते शिरो गाय॒त्त्रं चक्षुर्बृहद्रयन्तरे प॒क्षौ ॥ स्तोम ऽआ॒त्मा छन्दाᳪस्यङ्गानि यजूᳪ षि नाम ॥ साम ते त॒नूर्व्वामदे॒व्यं यज्ञायज्ञियं पुच्छं धिष्ण्याः श॒फाः सुप॒र्णोऽसि गरुत्मान्दिवंं गच्छ स्वः पत॥`,
        des2:`ॐ भूर्भुवः स्वः कलहप्रिये इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः कलहप्रियायै नमः ॥`
    },
    {
        title:`२२. राक्षसी`,
        des1:`ॐ पितृभ्यः स्वधायिभ्यः स्वधा नमः पितामहेब्भ्यः स्वधायिब्भ्यः स्वधा नमः प्रपितामहेभ्यः स्वधायिब्भ्यः स्व॒धा नमः॥ अक्षन्न्पितरोऽमीमदन्त पितरोऽतीतृपन्त पितरः पितरः शुन्धद्ध्वम् ॥`,
        des2:`ॐ भूर्भुवः स्वः राक्षसि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः राक्षस्यै नमः॥`
    },
    {
        title:`२३. घोररक्ताक्षी`,
        des1:`ॐ वरुणस्यो ।। ॐ भूर्भुवः स्वः घोररक्ताक्षि इहागच्छ इह तिष्ठ॥`,
        des2:`ॐ भूर्भुवः स्वः घोररक्ताक्ष्यै नमः॥`
    },
    {
        title:`२४. विशालाक्षी`,
        des1:`ॐ व्वरुणः प्प्राविता भुवन्न्मित्त्रो व्विश्श्वाभिरूतिभिः । करतान्नः सुराधसः ॥`,
        des2:`ॐ भूर्भुवः स्वः विशालाक्षि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः विशालाक्ष्यै नमः॥`
    },
    {
        title:`२५. कौमारी`,
        des1:`ॐ ह ᳪ सः शुचिषद्वसुरन्तरिक्षसद्धोता व्वेदिषदतिथिर्द्दुरोणसत्। नृषद्वरसदृतसद्व्योम-सदब्जागोजा ऽऋतजा ऽअद्रिजा ऽऋतं बृहत् ॥`,
        des2:`ॐ भूर्भुवः स्वः कौमारि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः कौमार्य नमः॥`
    },
    {
        title:`२६. चण्डी`,
        des1:`ॐ सुसन्दृशन्त्वा व्वयं मधवन् वन्दिषीमहि । प्रनूनं पूर्णबन्धुरस्तुतो या सि व्वशाँ२ ऽअनु योजान्विन्द्र ते हरी ॥`,
        des2:`ॐ भूर्भुवः स्वः चण्डे इहागच्छ इह तिष्ठ॥ ॐ भूर्भुवः स्वः चण्ड्यै नमः॥`
    },
    {
        title:`२७. वाराही`,
        des1:`ॐ प्प्रतिपदसि प्प्रतिपदे त्त्वानुपदस्यनुपदे त्त्वा सम्पदसि सम्पदे त्वा तेजोऽसि तेजसे त्त्वा ॥`,
        des2:`ॐ भूर्भुवः स्वः वाराहि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः वाराह्यै नमः ॥`
    },
    {
        title:`२८. मुण्डधारिणी`,
        des1:`ॐ देवीरापो ऽअपान्नपाद्यो व ऽऊर्मिर्हविष्य ऽइन्द्रियावान्मदिन्तमः॥ तं देवेभ्यो देवत्त्रा दत्त शुक्क्रपेब्भ्यो येषां भाग स्थ स्वाहा ॥`,
        des2:`ॐ भूर्भुवः स्वः मुण्डधारिणि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः मुण्डधारिण्यै नमः ॥`
    },
    {
        title:`२९. भैरवी`,
        des1:`ॐ देवीद्वारो ऽअश्विना भिषजेन्द्रे सरस्वती ॥ प्प्राणं न वीर्य्यं नसि द्वारो दधुरिन्द्रियं व्वसुवने व्वसुधेयस्य व्यन्तु यज ॥`,
        des2:`ॐ भूर्भुवः स्वः भैरवि इहागच्छ इह तिष्ठ॥ ॐ भूर्भुवः स्वः भैरव्यै नमः ॥`
    },
    {
        title:`३०. वीरा`,
        des1:`ॐ देवी जोष्ट्री सरस्वत्यश्विनेन्द्रमवर्द्धयन्॥ श्र्श्रोत्त्रं न कर्णयोर्य्यशो जोष्ट्रीब्भ्यां दधुरिन्द्रियं व्वसुवने व्वसुधेयस्य व्यन्तु यज ॥`,
        des2:`ॐ भूर्भुवः स्वः वीरे इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः वीरायै नमः॥`
    },
    {
        title:`३१. भयङ्करी`,
        des1:`ॐ देवस्य त्वा सवितुः प्रसवे ऽश्विनोर्बाहुब्भ्यां पूष्णो हस्ताब्भ्याम् । अश्विनोर्भैषज्ज्येन तेजसे ब्रह्मवर्चसायाऽभिषिञ्चामि सरस्वत्यै भैषज्ज्येन वीर्य्यायान्नाद्यायाऽभिषिञ्चामीन्द्रस्येन्द्रियेण वलोय श्र्श्रियै यशसे ऽभिषिञ्चामि ॥`,
        des2:`ॐ भूर्भुवः स्वः भयङ्करि इहागच्छ इह तिष्ठ॥ ॐ भूर्भुवः स्वः भयङ्कर्यै नमः॥`
    },
    {
        title:`३२. वज्रधारिणी`,
        des1:`ॐ कदाचन स्तरीरसि नेन्द्र सश्चसि दाशुषे ॥ उपोपेन्नु सघवन्नमूल ऽइन्नु ते दानंं देवस्य पृच्यते ॥ `,
        des2:`ॐ भूर्भुवः स्वः वज्रधारिणि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः वज्रधारिण्यै नमः ॥`
    },
    {
        title:`३३. क्रोधा`,
        des1:`ॐ भद्रं कर्णेभिः शृणुयाम देवा भद्रं पश्येमाक्षभिर्यजत्राः । स्थिरैरङ्गैस्तुष्टुवा ᳪ  सस्तनूभिर्व्यशेमहि देवहितं यदायुः ॥`,
        des2:`ॐ भूर्भुवः स्वः क्रोधे इहागच्छ इह तिष्ठ॥ ॐ भूर्भुवः स्वः क्रोधायै नमः॥`
    },
    {
        title:`३४. दुर्मुखी`,
        des1:`ॐ इषे त्त्वोर्जे त्वा व्वायव स्त्थ देवो वः सविता प्प्रार्प्पयतु श्रेष्ठतमाय कर्मण ऽआप्यायध्द्वमग्ध्न्या ऽइन्द्राय भागंप्प्रजावतीरनमीवा ऽअयक्ष्मा सा वस्तेन ऽईशत माघशᳪ सो द्ध्रुवा ऽअस्मिन्गोपतौ स्यात बह्वीर्य्यजमानस्य पशून्पाहि ॥`,
        des2:`ॐ भूर्भुवः स्वः दुर्मुखि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः दुर्मुख्यै नमः ॥`
    },
    {
        title:`३५. प्रेतवाहिनी`,
        des1:`ॐ देवी द्यावापृथिवी मखस्य वामद्य शिरो राद्ध्यासं देवयजने पृथिव्याः ॥ मखाय त्त्वा मखस्य त्त्वा शीर्ष्णे ॥`,
        des2:`ॐ भूर्भुवः स्वः प्रेतवाहिनि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः प्रेतवाहिन्यै नमः॥`
    },
    {
        title:`३६. कर्का`,
        des1:`ॐ व्विश्वानि देव सवितर्दुरितानि परासुव॥ यद्भद्रं तन्नऽआसुव ॥`,
        des2:`ॐ भूर्भुवः स्वः कर्के इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः कर्कायै नमः॥`
    },
    {
        title:`३७. दीर्घलम्बोष्ठी`,
        des1:`ॐ असुन्वन्तमयजमानमिच्छस्तेनस्येत्यामन्विहि तस्क्करस्य ॥ अन्यमस्मदिच्छ सा त ऽइत्या नमो देवि निर्ऋते तुभ्यमस्तु ।।`,
        des2:`ॐ भूर्भुवः स्वः दीर्घलम्बोष्ठि इहागच्छ इह तिष्ठ॥ ॐ भूर्भुवः स्वः दीर्घलम्बोष्ठयै नमः॥`
    },
    {
        title:`३८. मालिनी`,
        des1:`ॐ अग्निश्च मे घर्म्मश्च मेऽर्क्कश्च मे सूर्य्यश्च मे प्प्राणश्च मेऽश्वमेधश्च मे पृथिवी च मेऽदितिश्च मे दितिश्च मे द्यौश्च मेऽङ्गुलयः शक्वरयो दिशश्च मे यज्ञेन कल्पन्ताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः मालिनि इहागच्छ इह तिष्ठ॥ ॐ भूर्भुवः स्वः मालिन्यै नमः॥`
    },
    {
        title:`३९. मन्त्रयोगिनी`,
        des1:`ॐ बह्वीनां पिता ब्बहुरस्य पुत्त्रश्ञ्चिश्चा कृणोति समनाव गत्य ॥ इषुधिः सङ्काः पृतनाश्ञ्च सर्व्वाः पृष्ठे निनद्धो जयति प्प्रसूतः ॥`,
        des2:`ॐ भूर्भुवः स्वः मन्त्रयोगिनि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः मन्त्रयोगिन्यै नमः॥`
    },
    {
        title:`४०. कालमोहिनी`,
        des1:`ॐ नमस्ते रुद्र मन्यव ऽउतो त ऽइषवे नमः॥ बाहुब्भ्यामुत ते नमः॥`,
        des2:`ॐ भूर्भुवः स्वः कालाग्निमोहिनि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः कालाग्निमोहिन्यै नमः॥`
    },
    {
        title:`४१. मोहिनी`,
        des1:`ॐ ऋतञ्च मेऽमृतञ्च मेऽयक्ष्मञ्च मेऽनामयञ्च मे जीवातुश्च मे दीर्घायुत्त्वं च मेऽनमित्रञ्च मेऽभयञ्च मे सुखञ्च मे शयनञ्च मे सुषाश्च मे सुदिनञ्च मे यज्ञेन कल्प्पन्ताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः मोहिनि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः मोहिन्यै नमः॥`
    },
    {
        title:`४२. चक्रा`,
        des1:`ॐ ते ऽआवरन्ति समनेव योषा मातेव पुत्रं बिव्भृतामुपस्थे ॥ अप शत्रून् व्विद्ध्यताᳪ सम्विदाने ऽआर्त्नी ऽइमे व्विष्फुरन्ती ऽअमित्यान् ॥`,
        des2:`ॐ भूर्भुवः स्वः चक्रे इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः चक्रायै नमः॥`
    },
    {
        title:`४३. कुण्डलिनी`,
        des1:`ॐ वेद्य व्वेदिः समाप्यते बर्हिषारिन्द्रियम्। यूपेन यूपी ऽआप्प्यते प्रणीतोऽअग्निरग्निना ॥`,
        des2:`ॐ भूर्भुवः स्वः कुण्डलिनि इहागच्छ इह तिष्ठ॥ ॐ भूर्भुवः स्वः कुण्डलिन्यै नमः॥`
    },
    {
        title:`४४. बालुका`,
        des1:`ॐ पावका नः सरस्वती व्वाजेभिर्व्वाजिनीवती यम व्वष्टु धियावसुः ॥`,
        des2:`ॐ भूर्भुवः स्वः बालुके इहागच्छ इह तिष्ठ।। ॐ भूर्भुवः स्वः बालुकायै नमः॥`
    },
    {
        title:`४५. कौबेरी`,
        des1:`ॐ अस्क्कन्नमद्य देवेब्भ्य ऽआज्यᳪ संभ्रियासमघृणा व्विष्ष्णो मा त्त्वावक्क्रमिषं व्वसुमतीमग्ने ते च्छायामुपस्त्थेषं विष्णोः स्त्थानमसीतऽइन्द्रो वीर्य्यमकृष्णोदूर्ध्द्वोऽध्द्वर ऽआस्त्थात् ॥`,
        des2:`ॐ भूर्भुवः स्वः कौबेरि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः कौबेर्यै नमः॥`
    },
    {
        title:`४६. यमदूती`,
        des1:`ॐ ते ऽआचरन्ती समनेव योषा मातेव पुत्रं व्विब्भृतामुपस्त्थे ॥ अप शत्रून् व्विद्ध्यता ᳪ संव्विदाने आर्त्नी ऽइमे व्विष्फुरन्ती ऽअमित्रान् ॥`,
        des2:`ॐ भूर्भुवः स्वः यमदूति इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः यमदूत्यै नमः॥`
    },
    {
        title:`४७. करालिनी`,
        des1:`ॐ मही द्यौः पृथिवी च नऽइमं यज्ञं मिमिक्षताम् ॥ पिपृतान्नो भरीमभिः ॥`,
        des2:`ॐ भूर्भुवः स्वः करालिनि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः करालिन्यै नमः॥`
    },
    {
        title:`४८. कौशिकी`,
        des1:`ॐ उपामगृहीतोऽसि सावित्रोऽसि चनोभाश्श्चनोधा ऽअसि च नो महि धेहि ॥ जिन्व यज्ञं जिन्व यज्ञपतिं भगाय देवाय त्त्वा सवित्रे।`,
        des2:`ॐ भूर्भुवः स्वः कौशिकि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः कौशिक्यै नमः ॥`
    },
    {
        title:`४९. यक्षिणी`,
        des1:`ॐ आप्यायस्य समेतु ते विश्वतः सोम वृष्ण्यम् । भवा व्वाजस्य सङ्गथे ॥`,
        des2:`ॐ भूर्भुवः स्वः यक्षिणि इहागच्छ इह तिष्ठ।। ॐ भूर्भुवः स्वः यक्षण्यै नमः॥`
    },
    {
        title:`५०. भक्षिणी`,
        des1:`ॐ कार्षिरसि समुद्रस्य त्वाक्षित्याऽउन्नयामि। समापोऽअद्भिरग्मतसमोषधीभिरोषधीः॥`,
        des2:`ॐ भूर्भुवः स्वः भक्षिणि इहागच्छ इह तिष्ठ।। ॐ भूर्भुवः स्वः भक्षिण्यै नमः॥`
    },
    {
        title:`५१. कौमारी`,
        des1:`ॐ त्र्यम्बकं यजामहे सुगन्धिम्पुष्टिवर्द्धनम्। उर्वारुकमिव बन्धनादितो मुक्षीय मामुतः ॥`,
        des2:`ॐ भूर्भुवः स्वः कौमारि इहागच्छ इह तिष्ठ।। ॐ भूर्भुवः स्वः कौमार्यै नमः॥`
    },
    {
        title:`५२. मन्त्रवाहिनी`,
        des1:`ॐ श्रीश्च ते लक्ष्मीश्च पत्न्यावहोरात्रे पार्श्वे नक्षत्राणि रूपमश्विनौ व्यात्तम् । इष्णन्निषाणामुम्म ऽइषाण सर्वलोकं मऽइषाण॥`,
        des2:`ॐ भूर्भुवः स्वः मन्त्रवाहिनि इहागच्छ इह तिष्ठ ॥ ॐ भूर्भुवः स्वः मन्त्रवाहिन्यै नमः॥`
    },
    {
        title:`५३. विशालाक्षी`,
        des1:`ॐ व्विष्णोरराटमसि विष्णोः श्नप्त्रे स्त्थो विष्णोः स्यूरसि विष्णोध्रुवोसि वैष्णवमसि विष्णवे त्वा॥`,
        des2:`ॐ भूर्भुवः स्वः विशालाक्षि इहागच्छ इह तिष्ठ।। ॐ भूर्भुवः स्वः विशालाक्ष्यै नमः॥`
    },
    {
        title:`५४. कार्मुकी`,
        des1:`ॐ ब्राह्मणमद्य विदेयं पितृमन्तं पैतृमत्यमृषिमार्षेय ᳪ सुधातुदक्षिणम्। अस्मद्राता देवत्रा गच्छत प्रदातारमाविशत ॥`,
        des2:`ॐ भूर्भुवः स्वः कार्मुकि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः कार्मुक्यै नमः ॥`
    },
    {
        title:`५५. व्याघ्री`,
        des1:`ॐ या व्याघ्रं विषूचिकोभौ वृकं च रक्षति। श्येनं पतत्रिण ᳪ सि ᳪसेमं पात्व ᳪ हसः ॥`,
        des2:`ॐ भूर्भुवः स्वः व्याघ्रि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः व्याघ्र्यै नमः ॥`
    },
    {
        title:`५६. महाराक्षसी`,
        des1:`ॐ एका च मे तिस्रश्च मे तिस्रश्च मे पञ्च मे पञ्च मे सप्त च मे सप्त च मे नव च मे नव च मऽएकादश च मऽएकादश च मे त्रयोदश च मे त्रयोदश च मे पञ्चदश च मे पञ्चदश च मे सप्तदश च मे सप्तदश च मे नवदश च मे नवदश च मऽएकवि ᳪ शतिश्च्च म ऽएकवि ᳪ शतिश्च्च मे त्रयोवि ᳪ शतिश्च्च मे त्रयोवि ᳪ शतिश्च्च मे पञ्चवि ᳪ शतिश्च्च मे पञ्चवि ᳪ शतिश्च्च मे सप्तवि ᳪ शतिश्च्च मे सप्तवि ᳪ शतिश्च्च मे नववि ᳪ शतिश्च्च मे नववि ᳪ शतिश्च्च म ऽएकत्रि ᳪ शश्च्च म ऽएकत्रि ᳪ शश्च्च मे त्रयस्त्रि ᳪ शश्च्च मे यज्ञेन कल्प्पन्ताम्  ॥`,
        des2:`ॐ भूर्भुवः स्वः महाराक्षसि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः महाराक्षस्यै नमः ॥`
    },
    {
        title:`५७. प्रेतभक्षिणी`,
        des1:`ॐ प्रेता जयता नर ऽइन्द्रो वः शर्म्म यच्छतु। उग्रावः सन्तु बाहवो नाधृष्या यथासथ ॥`,
        des2:`ॐ भूर्भुवः स्वः प्रेतभक्षिणि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः प्रेतभक्षिण्यै नमः ॥`
    },
    {
        title:`५८. धूर्जटी`,
        des1:`ॐ असङ्ख्याता सहस्राणि ये रुद्द्राऽअधिभूम्म्याम्।  तेषा ᳪ सहस्रयोजनेऽवधन्न्वानि तन्न्मसि ॥`,
        des2:`ॐ भूर्भुवः स्वः धूर्जटि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः धूर्जट्यै नमः ॥`
    },
    {
        title:`५९. विकटा`,
        des1:`ॐ सुपर्णोऽसि गरुत्माँस्त्रिवृत्ते शिरो गायत्रं चक्षुबृहद्रथन्तरे पक्षौ। स्तोमऽआत्मा छन्दा ᳪ स्यङ्गानि यजू ᳪ षि नाम। साम ते तनूर्वामदेव्यं यज्ञायज्ञियं पुच्छं धिष्ण्याः शफाः। सुपर्णोऽसि गरुत्मान्दिवं गच्छ स्वः पत ॥`,
        des2:`ॐ भूर्भुवः स्वः विकटे इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः विकटायै नमः ॥`
    },
    {
        title:`६०. घोररूपा`,
        des1:`ॐ या ते रुद्द्र शिवातनूरघोराऽपापकाशिनी। तयानस्तन्न्वाशन्तमया गिरिशन्ताभि चाकशीहि॥`,
        des2:`ॐ भूर्भुवः स्वः घोररूपे इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः घोररूपायै नमः ॥`
    },
    {
        title:`६१. कपालिका`,
        des1:`ॐ देवी द्यावापृथिवी मखस्य वामद्य शिरो राद्ध्यासं देवयजने पृथिव्याः। मखाय त्त्वा मखस्य त्त्वा शीर्ष्णे।।`,
        des2:`ॐ भूर्भुवः स्वः कपालिके इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः कपालिकायै नमः।।`
    },
    {
        title:`६२. निकला`,
        des1:`ॐ इदं विष्णुर्विचक्रमे त्रेधा निदधे पदं। समूढमस्य पा ᳪ सुरे ।।`,
        des2:`ॐ भूर्भुवः स्वः निकले इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः निकलायै नमः।।`
    },
    {
        title:`६३. अमला`,
        des1:`ॐ व्वृष्ण ऽउर्म्मिरसि राष्ट्रंदा मे देहि स्वाहा  व्वृष्ण ऽउर्म्मिरसि राष्ट्रदा राष्ट्रममुष्मै देहि व्वृषसेनोऽसि राष्ट्रदा राष्ट्रं मे देहि स्वाहा व्वृषसेनोऽसि राष्ट्रदा राष्ट्रममुष्मै देहि॥`,
        des2:`ॐ भूर्भुवः स्वः अमले इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः अमलायै नमः ॥`
    },
    {
        title:`६४. सिद्धिप्रदा`,
        des1:`ॐ भायै दार्व्वाहार प्रभाया ऽअग्न्येधं ब्रध्नस्य विष्टपायाभिषेक्तारं व्वर्षिष्ठाय नाकाय परिवेष्टारं देवलोकाय पेशतारं मनुष्यलोकाय प्प्रकरितार ᳪ सर्वेभ्यो लोकेब्भ्य ऽउपसेक्तारमव ऽऋत्यै वृधायोपमन्थितारं मेधाय व्यासः पल्पूली प्रकामाय रजयित्त्रीम् ॥`,
        des2:`ॐ भूर्भुवः स्वः सिद्धिप्रदे इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः सिद्धिप्रदायै नमः ॥`
    }
];

const dhayan=`ॐ खड्गं चक्रगदेषुचापपरिधाञ्छूलं भुशुण्डीं शिरः। शङ्ख सन्दधर्तं करैस्त्रिनयनां सर्वाङ्गभूषावृताम् ॥ नीलाश्मद्युतिभास्यपाददशकां सेवे महाकालिकां ।
              यामस्तौत्स्वपिते हरौ कमलजो हन्तुं मधुं कैटभम् ॥ ॐ अक्षस्रक्परशुं गदेषुकुलिशं पद्यं धनुष्कुण्डिकां । दण्डं शक्तिमसिं च चर्म जलजं घण्टां सुराभाजनं ।।
              शूलं पाशसुदर्शने च दधतीं हस्तैः प्रसन्नाननां । सेवे सैरिभमर्दिनीमिह महालक्ष्मीं सरोजस्थिताम् ॥ 
              ॐ घण्टाशूलहलानि शङ्खमुसले चक्रं धनुः सायकं । हस्ताब्जैर्दधतीं घनान्तविलसच्छीतांशुतुल्यप्रभाम् ॥ गौरीदेहसमुद्भवां त्रिजगतामाधारभूतामहा । 
              पूर्वामत्र सरस्वतीमनुभजे शुम्भादिदैत्यार्दिनीम् ।।`;

const pratistha=`ॐ मनो जूतिर्ज्जुषतामाज्ज्यस्य बृहस्पतिर्य्यज्ञमिमं तनोत्वरिष्टं य्यज्ञ ᳪ समिमं दधातु। विश्वे देवासऽइह मादयन्तामों प्रतिष्ठ ॥ ॐ आवाहयाम्यहं देवी योगिनीं परमेश्वरीम् । योगाभ्यासेन संतुष्टा परं ध्यानसमन्विता ॥ 
                दिव्यकुण्डल संकाशा दिव्यज्वाला त्रिलोचना। मूर्तिमती ह्यमूर्त्ता च उग्राचैवोग्ररूपिणी ॥ अनेक भावसंयुक्ता  संसारार्णवतारिणी ॥ यज्ञे कुर्वन्तु निर्विघ्नं  श्रेयो यच्छन्तु मातरः ॥ 
                ॐ भूर्भुवः स्वः महाकाली-महालक्ष्मी-महासरस्वती सहिताः दिव्ययोगिन्यादि चतुष्षष्टि योगिन्यः  इहागच्छत इह तिष्ठत।`;

const prarthana=`ॐ दिव्ययोगी-महायोगी सिद्धयोगी गणेश्वरी । प्रेताशी डाकिनी काली कालरात्री निशाचरी । हुङ्कारी सिद्धवेताली खर्परी भूतगामिनी ॥ 
                 उर्ध्वकेशी विरूपाक्षी शुष्कांगी मांसभोजिनी । फूत्कारी वीरभद्राक्षी धूम्राक्षी कलहप्रिया ॥ रक्ता च घोररक्ताक्षी विरूपाक्षी भयंकरी । 
                 चौरिका भारिका चण्डी वाराही मुण्डधारिणी । भैरवी चक्रिणी क्रोधा दुर्मुखी प्रेतवासिनी । कालाक्षी मोहिनी चक्री कंकाली भुवनेश्वरी । 
                 कुण्डला तालकौमारी यमदूती करालिनी ॥ कौशिकी यक्षिणी यक्षी कौमारी यन्त्रवाहिनी || दुर्घटा विकटा घोरा कपाला विषलङ्घना । 
                 चतुःषष्टिः समाख्याता योगिन्यो हि वरप्रदाः ॥ त्रैलोक्यपूजिता नित्यं देवमानुषयोगिभिः ॥`

export {yoginiAvahan,dhayan,pratistha,prarthana}