
const sodasmatrikaAvahan=[
    {
        title:`१. गणपति`,
        des1:`ॐ लम्बोदरं महाकायं गजवक्त्रं चतुर्भुजं । आवाहयाम्यहमं देवं गणेशं सिद्धिदायकम् ।। `,
        des2:`ॐ भूर्भुवः स्वः गणपते इहागच्छ इह तिष्ठ । ॐ भूर्भुवः स्वः गणपतये नमः ।`
    },
    {
        title:`२. गौरी `,
        des1:`ॐ आयङ्गौः पृश्निरक्रमीदसदन्मातरं पुरः । पितरं च प्रयत्न्स्वः ॥ `,
        des2:`ॐ भूर्भुवः स्वः गौरि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः गौर्यै नमः।`
    },
    {
        title:`३. पद्मा`,
        des1:`ॐ हिरण्यरूपा ऽउषसो व्विरोक ऽउभाविन्दा ऽउदिथः सूर्यश्च । आरोहतं व्वरुण मित्र गर्तं ततश्श्चक्षाथामदितिं दितिञ्च मित्रोऽसि व्वरुणोऽसि ॥`,
        des2:`ॐ भूर्भुवः स्वः पद्मे इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः पद्मायै नमः।`
    },
    {
        title:`४. शची`,
        des1:`ॐ निवेशनः सङ्गमनो व्वसूनां विश्वा रूपाभिचष्टे शचीभिः ।देवऽइव सविता सत्यधर्मेन्द्रो न तस्त्थौ समरे पथीनाम् ॥`,
        des2:`ॐ भूर्भुवः स्वः शचि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः शच्यै नमः।`
    },
    {
        title:`५. मेधा`,
        des1:`ॐ मेधां मे व्वरुणो ददातु मेधामग्निः प्रजापतिः। मेधामिन्द्रश्च व्वायुश्श्च मेधां धाता ददातु मे स्वाहा ॥`,
        des2:`ॐ भूर्भुवः स्वः मेधे इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः मेधायै नमः।`
    },
    {
        title:`६. सावित्री`,
        des1:`ॐ सविता त्त्वा सवाना ᳪ सुवतामग्निर्गृहपतीना ᳪ सोमो वनस्पतीनाम् ।बृहस्पतिर्वाचऽइन्द्रो ज्ज्यैष्ठ्यायरूद्रः पशुब्भ्यो मित्रः सत्त्यो व्वरुणो धर्मपतीनाम् ॥`,
        des2:`ॐ भूर्भुवः स्वः सावित्रि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः सावित्र्यै नमः।`
    },
    {
        title:`७. विजया`,
        des1:`ॐ विज्ज्यन्धनुः कपर्दिनो व्विशल्यो बाणवाँ२ ऽउत । अनेशन्नस्य या ऽइषव ऽआभुरस्य निषङ्गधिः॥`,
        des2:`ॐ भूर्भुवः स्वः विजये इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः विजयायै नमः।`
    },
    {
        title:`८. जया`,
        des1:`ॐ बह्वीनां पिता ब्बहुरस्य पुत्रश्चिश्श्चाकृणोति समनावगत्य ।इषुधि: सङ्काः पृतनाश्च सर्वाः पृष्ठे निनद्धो जयति प्रसूतः ॥`,
        des2:`ॐ भूर्भुवः स्वः जये इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः जयायै नमः।`
    },
    {
        title:`९. देवसेना`,
        des1:`ॐ इन्द्रऽआसान्नेता बृहस्पतिर्दक्षिणा यज्ञः पुर ऽएतु सोमः । देवसेनानामभिभञ्जतीनां जयन्तीनां मरुतो यन्त्वग्रम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः देवसेने इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः देवसेनायै नमः।`
    },
    {
        title:`१०. स्वधा`,
        des1:`ॐ पितृभ्यः स्वायिब्भ्यः स्वधा नमः पितामहेभ्यः स्वायिब्भ्यः स्वधा नमः प्रपितामहेभ्यः स्वायिब्भ्यः स्वधा नमः। अक्षन्न्पितरोऽमीमदन्त पितरोऽतीतृपन्त पितरः, पितरो शुन्धद्ध्वम् ॥`,
        des2:`ॐ भूर्भुवः स्वः स्वधे इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः स्वधायै नमः।`
    },
    {
        title:`११. स्वाहा`,
        des1:`ॐ स्वाहा प्राणेब्भ्यः साधिपतिकेब्भ्यः । पृथिव्यै स्वाहाग्नये स्वाहान्तरिक्षाय स्वाहा व्वायवे स्वाहा। दिवे स्वाहा सूर्याय स्वाहा॥`,
        des2:`ॐ भूर्भुवः स्वः स्वाहे इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः स्वाहायै नमः।`
    },
    {
        title:`११. मातृ`,
        des1:`ॐ आपोऽअसम्मान्मातरः शुन्धयन्तु घृतेन तो घृतप्वः पुनन्तु । विश्व ᳪ हि रिप्रं प्रवहन्ति देवीरुदिदाब्भ्यः शुचिरा पूतऽएमि । दीक्षातपसोस्तनूरसि तां त्वा शिवा ᳪ शग्मां परिदधे भद्रं व्वर्णं पुष्यन्॥ `,
        des2:`ॐ भूर्भुवः स्वः मातर इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः मातृभ्यो नमः।`
    },
    {
        title:`१२. लोकमातृ`,
        des1:`ॐ रयिश्च मे रायश्च मे पुष्टंचमे पुष्टिश्च मे विभुचमे प्रभुच मे पूर्ण्णं च मे पूर्णतरं च मे कुयवं चमऽक्षितं च मेऽन्नं च मेऽक्षुच्च मे यज्ञेन कल्पन्ताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः लोकमातर इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः लोकमातृभ्यो नमः।`
    },
    {
        title:`१३. धृति`,
        des1:`ॐ यत्प्रज्ञानमुत चेतो धृतिश्च्च यज्ज्योतिरन्तरमृतं प्रजासु ।यस्मान्नऋते किञ्च न कर्म क्रियते तन्मे मनः शिवसंकल्प्पमस्तु ॥`,
        des2:`ॐ भूर्भुवः स्वः धृते इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः धृत्यै नमः।`
    },
    {
        title:`१४. पुष्टि`,
        des1:`ॐ अङ्गान्न्यात्मन्न्भिषजा तदश्विनात्मानमङ्गैः समधात्सरस्वती ।इन्द्रस्य रूप ᳪ शतमानमायुश्चन्द्रेण ज्योतिरमृतं दधानाः॥ ॐ भूर्भुवः स्वः पुष्टे इहागच्छ इह तिष्ठ।`,
        des2:`ॐ भूर्भुवः स्वः पुष्टयै नमः।`
    },
    {
        title:`१५. तुष्टि`,
        des1:`ॐ जातवेदसे सुनवाम सोममरातीयतो निदहाति वेदः । सनः पर्षदति दुर्गाणि विश्वा नावेव सिन्धुन्दुरितात्यग्निः ॥`,
        des2:`ॐ भूर्भुवः स्वः तुष्टे इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः तुष्टयै नमः।`
    },
    {
        title:`१६. आत्मनः कुलदेवता`,
        des1:`ॐ प्राणाय स्वाहाऽपानाय स्वाहा ळ्यानाय स्वाहा ।चक्षुषे स्वाहा क्षोत्राय स्वाहा व्वाचे स्वाहा मनसे स्वाहा ॥`,
        des2:`ॐ भूर्भुवः स्वः आत्मनः कुलदेवते इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः आत्मनः कुलदेवतायै नमः।`
    }
]

export {sodasmatrikaAvahan}