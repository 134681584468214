
export const ganeshPuja=[
    {
        title:`भगवान् गणेशका ध्यान`,
        des1:`गजाननं भूतगणादिसेवितं कपित्थजम्बूफलचारुभक्षणम् ।`,
        des2:`उमासुतं शोकविनाशकारकं नमामि विघ्नेश्वरपादपङ्कजम् ॥`
    },
    {
        title:`भगवती गौरीका ध्यान`,
        des1:`नमो देव्यै महादेव्यै शिवायै सततं नमः । नमः प्रकृत्यै भद्रायै नियताः प्रणताः स्म ताम् ॥ `,
        des2:`श्रीगणेशाम्बिकाभ्यां नमः, ध्यानं समर्पयामि ।`
    },
    {
        title:`भगवान् गणेशका आवाहन`,
        des1:` एह्येहि हेरम्ब महेशपुत्र समस्तविघ्नौघविनाशद । माङ्गल्यपूजाप्रथमप्रधान गृहाण पूजां भगवन् नमस्ते ॥`,
        des2:`ॐ भूर्भुवः स्वः सिद्धिबुद्धिसहिताय गणपतये नमः, गणपतिमावाहयामि, स्थापयामि, पूजयामि च ।`
    },
    {
        title:`भगवती गौरीका आवाहन`,
        des1:`ॐ हेमाद्रितनयां देवीं वरदां शङ्करप्रियाम् । लम्बोदरस्य जननीं गौरीमावाहयाम्यहम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गौर्यै नमः, गौरीमावाहयामि, स्थापयामि, पूजयामि च ।`
    },
    {
        title:`- प्रतिष्ठा -`,
        des1:`अस्यै प्राणाः प्रतिष्ठन्तु अस्यै प्राणाः क्षरन्तु च । अस्यै देवत्वमर्चायै मामहेति च कश्चन ॥`,
        des2:`गणेशाम्बिके! सुप्रतिष्ठिते वरदे भवेताम् ।`
    },
    {
        title:`- पाद्य -`,
        des1:`ॐ सर्वतीर्थसमुदभूतं पाद्यं गन्धादिभिर्युतम्। विघ्नराज ! गृहाणेमं भगवन् ! भक्तवत्सलः।।`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, पादयोः पाद्यं समर्पयामि ।`
    },
    {
        title:`- अर्घ्य -`,
        des1:`ॐ गणाध्यक्ष! नमस्तेऽस्तु गृहाण करुणाकर । अर्घ्यं च फल संयुक्तं गन्धपुष्पाक्षतैर्युतम्।।`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, हस्तयोर्घ्यं समर्पयामि ।`
    },
    {
        title:`- आचमन -`,
        des1:`ॐविनायक! नमस्तुभ्यं त्रिदशैरभिवन्दित। गंगोदकेन देवेश कुरुष्वाचमनं प्रभो।।`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, मुखे आचमनीयं समर्पयामि ।`
    },
    {
        title:`- स्नान -`,
        des1:`गंगे च यमुने चैव गोदावरि सरस्वति, नर्मदे सिंधु कावेरी जलेऽस्मिन्‌ सन्निधिं कुरु ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, स्नानं समर्पयामि ।`
    },
    {
        title:`- दुग्धस्नान -`,
        des1:`कामधेनुसमुद्भूतं सर्वेषां जीवनं परम् । पावनं यज्ञहेतुश्च पयःस्नानार्थमर्पितम्`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, पयः स्नानं समर्पयामि ।`
    },
    {
        title:`- दधिस्नान -`,
        des1:`पयसस्तु समुद्भूतं मधुराम्लं शशिप्रभम् । दध्यानीतं मया देव स्नानार्थं प्रतिगृह्यताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, दधिस्नानं समर्पयामि ।`
    },
    {
        title:`- घृतस्नान -`,
        des1:`नवनीतसमुत्पन्नं सर्वसंतोषकारकम् ॥ घृतं तुभ्यं प्रदास्यामि स्नानार्थं प्रतिगृह्यताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, घृतस्नानं समर्पयामि ।`
    },
    {
        title:`- मधुस्नान -`,
        des1:`पुष्परेणुसमुद्भूतं सुस्वादु मधुरं मधु । तेजः पुष्टिकरं दिव्यं स्नानार्थं प्रतिगृह्यताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, मधुस्नानं समर्पयामि ।`
    },
    {
        title:`- शर्करास्नान -`,
        des1:`इक्षुरससमुद्भूतां शर्करां पुष्टिदां शुभाम् । मलापहारिकां दिव्यां स्नानार्थं प्रतिगृह्यताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, शर्करास्नानं समर्पयामि ।`
    },
    {
        title:`- पञ्चामृतस्नान -`,
        des1:`पञ्चामृतं मयानीतं पयो दधि घृतं मधु । शर्करया समायुक्तं स्नानार्थं प्रतिगृह्यताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, पञ्चामृतस्नानं समर्पयामि ।`
    },
    {
        title:`- गन्धोदकस्नान -`,
        des1:`मलयाचलसम्भूतचन्दनेन विनिःसृतम् ।इदं गन्धोदकस्नानं कुङ्कुमाक्तं च गृह्यताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, गन्धोदकस्नानं समर्पयामि ।`
    },
    {
        title:`- शुद्धोदकस्नान -`,
        des1:`ॐ मन्दाकिन्यास्तु यद्वारि सर्वपापहरं शुभम्। तदिदं कल्पितं देव ! स्नानार्थं प्रतिगृह्यताम्।।`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, शुद्धोदकस्नानं समर्पयामि । शुद्धोदकस्नानान्ते आचमनीयं जलं समर्पयामि । `
    },
    {
        title:`- वस्त्र -`,
        des1:`शीतवातोष्णसंत्राणं लज्जाया रक्षणं परम् । देहालङ्करणं वस्त्रमतः शान्तिं प्रयच्छ मे ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, वस्त्रं च उपवस्त्रं समर्पयामि । वस्त्रान्ते आचमनीयं जलं समर्पयामि ।`
    },
    {
        title:`- यज्ञोपवीत -`,
        des1:`नवभिस्तन्तुभिर्युक्तं त्रिगुणं देवतामयम् । उपवीतं मया दत्तं गृहाण परमेश्वर ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, यज्ञोपवीतं समर्पयामि । यज्ञोपवीतान्ते आचमनीयं जलं समर्पयामि ।`
    },
    {
        title:`- चन्दन -`,
        des1:`श्रीखण्डं चन्दनं दिव्यं गन्धाढ्यं सुमनोहरम् ।विलेपनं सुरश्रेष्ठ! चन्दनं चन्दनं प्रतिगृह्यताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, चन्दनानुलेपनं समर्पयामि।`
    },
    {
        title:`- अक्षत -`,
        des1:`अक्षताश्च सुरश्रेष्ठ कुङ्कुमाक्ता: सुशोभिताः । मया निवेदिता भक्त्या गृहाण परमेश्वर ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, अक्षतान् समर्पयामि ।`
    },
    {
        title:`- पुष्पमाला -`,
        des1:`माल्यादीनि सुगन्धीनि मालत्यादीनि वै प्रभो । मयाहृतानि पुष्पाणि पूजार्थं प्रतिगृह्यताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, पुष्पमालां समर्पयामि ।`
    },
    {
        title:`- दूर्वा -`,
        des1:`दूर्वाङ्कुरान् सुहरितानमृतान् मङ्गलप्रदान् । आनीतांस्तव पूजार्थं गृहाण गणनायक ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, दूर्वाङ्कुरान् समर्पयामि ।`
    },
    {
        title:`- बिल्वपत्र -`,
        des1:`ॐ अमृतोद्भवं च श्रीवृक्षं शङ्करस्य सदा प्रियम्। बिल्वपत्रं प्रयच्छामि पवित्रं ते सुरेश्वर।।`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, इदं बिल्वपत्रं समर्पयामि ।`
    },
    {
        title:`- शमीपत्र -`,
        des1:`ॐ शमी शमय मे पापं शमी लोहितकंटका। धारिण्यर्जुनबाणानां रामस्य प्रियवादिनी।।`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, इदं शमीपत्रं समर्पयामि ।`
    },
    {
        title:`- सिन्दूर -`,
        des1:`सिन्दूरं शोभनं रक्तं सौभाग्यं सुखवर्धनम् । शुभदं कामदं चैव सिन्दूरं प्रतिगृह्यताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, सिन्दूरं समर्पयामि ।`
    },
    {
        title:`- अबीर-गुलाल आदि नाना परिमल द्रव्य -`,
        des1:`अबीरं च गुलालं च हरिद्रादिसमन्वितम् । नाना परिमलं द्रव्यं गृहाण परमेश्वर ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, नानापरिमलद्रव्याणि समर्पयामि।`
    },
    {
        title:`- सुगन्धिद्रव्य -`,
        des1:`दिव्यगन्धसमायुक्तं महापरिमलाद्भुतम् । गन्धद्रव्यमिदं भक्त्या दत्तं वै परिगृह्यताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, सुगन्धिद्रव्यं समर्पयामि ।`
    },
    {
        title:`- धूप -`,
        des1:`वनस्पतिरसोद्भूतो गन्धाढ्यो गन्ध उत्तमः। आघ्रेयः सर्वदेवानां धूपोऽयं प्रतिगृह्यताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, धूपमाघ्रापयामि ।`
    },
    {
        title:`- दीप -`,
        des1:`साज्यं च वर्तिसंयुक्तं वह्निना योजितं मया । दीपं गृहाण देवेश त्रैलोक्यतिमिरापहम् ॥ भक्त्या दीपं प्रयच्छामि देवाय परमात्मने ।`,
        des2:` त्राहि मां निरयाद् घोराद् दीपज्योतिर्नमोऽस्तु ते ॥ ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, दीपं दर्शयामि । हस्तप्रक्षालन-ॐ हृषीकेशाय नमः`
    },
    {
        title:`- नैवेद्य -`,
        des1:`शर्कराखण्डखाद्यानि दधिक्षीरघृतानि च। आहारं भक्ष्यभोज्यं च नैवेद्यं प्रतिगृह्यताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, नैवेद्यं निवेदयामि | नैवेद्यान्ते आचमनीयं जलं समर्पयामि ।`
    },
    {
        title:`- ऋतुफल -`,
        des1:`इदं फलं मया देव स्थापितं पुरतस्तव । तेन मे सफलावाप्तिर्भवेज्जन्मनि जन्मनि ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, ऋतुफलानि समर्पयामि। फलान्ते आचमनीयं जलं समर्पयामि ।`
    },
    {
        title:`- ताम्बूल -`,
        des1:`पूगीफलं महद्दिव्यं नागवल्लीदलैर्युतम् । एलादिचूर्णसंयुक्तं ताम्बूलं प्रतिगृह्यताम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, मुखवासार्थम् एलालवंग - पूगीफलसहितं ताम्बूलं समर्पयामि ।`
    },
    {
        title:`- दक्षिणा -`,
        des1:`हिरण्यगर्भगर्भस्थं हेमबीजं विभावसोः । अनन्तपुण्यफलदमतः शान्तिं प्रयच्छ मे ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, कृताया: पूजायाः साद्गुण्यार्थे द्रव्यदक्षिणां समर्पयामि।`
    },
    {
        title:`- आरती -`,
        des1:`कदलीगर्भसम्भूतं कर्पूरं तु प्रदीपितम् । आरार्तिकमहं कुर्वे पश्य मे वरदो भव ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, आरार्तिकं समर्पयामि ।`
    },
    {
        title:`- पुष्पाञ्जलि -`,
        des1:`नानासुगन्धिपुष्पाणि यथाकालोद्भवानि च । पुष्पाञ्जलिर्मया दत्तो गृहाण परमेश्वर ||`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, पुष्पाञ्जलिं समर्पयामि ।`
    },
    {
        title:`- प्रदक्षिणा -`,
        des1:`यानि कानि च पापानि जन्मान्तरकृतानि च । तानि सर्वाणि नश्यन्तु प्रदक्षिणपदे पदे ॥`,
        des2:`ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, प्रदक्षिणां समर्पयामि ।`
    },
    {
        title:`- विशेषार्घ्य -`,
        des1:`रक्ष रक्ष गणाध्यक्ष रक्ष त्रैलोक्यरक्षक । भक्तानामभयं कर्ता त्राता भव भवार्णवात् ॥ द्वैमातुर कृपासिन्धो षाण्मातुराग्रज प्रभो ।`,
        des2:`वरदस्त्वं वरं देहि वाञ्छितं वाञ्छितार्थद ॥ अनेन सफलार्घ्येण वरदोऽस्तु सदा मम । ॐ भूर्भुवः स्वः गणेशाम्बिकाभ्यां नमः, विशेषार्घ्यं समर्पयामि ।`
    }
]

export const prePuja=[
    {
        title:`अचमन`,
        des1:`ॐ केशवाय नमः ॐ नारायणाय नमः ॐ माधवाय नमः`,
        des2:`ॐ हृषीकेशाय नमः ॐ गोविंदाय नमः`,
        des3:``,
    },
    {
        title:`पवित्रीकरण`,
        des1:`ॐ अपवित्रः पवित्रो वा सर्वावस्थां गतोऽपि वा । यः स्मरेत् पुण्डरीकाक्षं स बाह्याभ्यन्तरः शुचिः॥`,
        des2:`ॐ पुण्डरीकाक्षः पुनातु। ॐ पुण्डरीकाक्षः पुनातु। ॐ पुण्डरीकाक्षः पुनातु।`,
        des3:``,
    },
    {
        title:`आसन शुद्धि`,
        des1:`ॐ पृथिवी त्वया धृता लोका देवि त्वं विष्णुना धृता । त्वं च धारय मां देवि पवित्रं कुरु चासनम् ॥`,
        des2:``,
        des3:``,
    },
    {
        title:`तिलक`,
        des1:`चंदनस्य महत्पुण्यं पवित्रं पापनाशनं। आपदां हरते नित्यं लक्ष्मी तिष्ठति सर्वदा।।`,
        des2:``,
        des3:``,
    },
    {
        title:`दिग्बन्धन रक्षा स्तोत्र`,
        des1:`ॐ पूर्वे रक्षतु वाराहः आग्नेयां गरुड़ध्वजः । दक्षिणे पदमनाभस्तु नैऋत्यां मधुसूदनः ॥ पश्चिमे चैव गोविन्दो वायव्यां तु जनार्दनः । उत्तरे श्री पति रक्षेदेशान्यां हि महेश्वरः ॥`,
        des2:`ऊर्ध्व रक्षतु धातावो ह्यधोऽनन्तश्च रक्षतु । अनुक्तमपियत्स्थानं रक्षत्वीशो ममाद्रिधृक् ॥ अपसर्पन्तु ये भूताः, ये भूताः भुवि संस्थिताः  ये भूताः विघ्नकर्तारस्ते गच्छन्तु शिवाज्ञया ॥`,
        des3:`अपक्रमंतु भूतानि पिशाचाः सर्वतोदिशम् । सर्वेषाम् विरोधेन यज्ञकर्म समारम्भे ॥`,
    },
    {
        title:`रक्षासूत्र`,
        des1:`येन बद्धो बली राजा दानवेन्द्रो महाबलः। तेन त्वाम् अभिबध्नामि रक्षे मा चल मा चल॥`,
        des2:``,
        des3:``,
    },
    {
        title:`अखंड दीपक`,
        des1:`भो दीप देव-रूपस्त्वं कर्मसाक्षि ह्यविघ्नकृत । यावत् कर्म-समाप्तिः स्यात् तावत् त्वं सुस्थिरो भवः॥`,
        des2:``,
        des3:``,
    }

]

export const swastiVachan=`ॐ आनोभद्रा: क्रतवो यन्तु विस्वतो
                    दब्धासो अपरीतास उद्भिद:।

                    देवानो यथा सदमिद वृधे
                    असन्नप्रायुवो रक्षितारो दिवे दिवे।।

                    देवानां भद्रा सुमतिर्रिजुयताम देवाना
                    ग्वंग रातिरभि नो निवार्ताताम।
                    देवानां ग्वंग सख्यमुपसेदिमा वयम
                    देवान आयु: प्रतिरन्तु जीवसे।।

                    तान पूर्वया निविदा हूमहे वयम
                    भगं मित्र मदितिम दक्षमस्रिधम।

                    अर्यमणं वरुण ग्वंग सोममस्विना
                    सरस्वती न: सुभगा मयस्करत ।।

                    तन्नोवातो मयोभूवातु भेषजं
                    तन्नमाता पृथिवी तत्पिता द्यौ: ।

                    तद्ग्रावान: सोमसुतो मयोभूवस्त
                    दस्विना श्रुनुतं धिष्ण्या युवं ।।

                    तमीशानं जगतस्तस्थुखसपतिं
                    धियंजिन्वमवसे हूमहे वयम ।

                    पूषा नो यथा वेद सामसद वृधे
                    रक्षिता पायुरदब्ध: स्वस्तये ।।

                    स्वस्ति न इन्द्रो वृद्ध श्रवा:
                    स्वस्ति न पूषा विस्ववेदा: ।

                    स्वस्ति नस्तार्क्ष्यो अरिष्टनेमि:
                    स्वस्ति नो वृहस्पति दधातु।।

                    पृषदश्वा मरुत: प्रिश्निमातर:
                    शुभं यावानो विदथेषु जग्मय:।

                    अग्निजिह्वा मनव: सूरचक्षसो
                    विश्वे नो देवा अवसागमन्निह।।

                    भद्रं कर्णेभि: शृणुयाम देवा
                    भद्रं पश्येमाक्षभिर्यजत्राः ।

                    स्थिरैरङ्गैस्तुष्टुवा ग्वंग सस्तनू
                    भिर्व्यशेमहि देवहितं यदायु:।।

                    शतमिन्नु शरदो अन्ति देवा
                    यत्रा नश्चक्रा जरसं तनूनाम् ।

                    पुत्रासो यत्र पितरो भवन्ति
                    मानो मध्या रीरिषतायुर्गन्तो:।।

                    अदितिर्द्यौरदितिरन्तरिक्ष्म
                    दितिर्माता स पिता स पुत्र:।

                    विश्वेदेवा अदिति: पञ्चजना
                    अदितिर्जातमदितिर्जनित्वम् ।।

                    द्यौ: शान्ति रन्तरिक्ष् ग्वंग शान्ति: पृथिवी
                    शान्ति राप: शान्ति रोषधय: शान्ति:।
                    वनस्पतय: शान्तिर्विश्वे देवा: शान्तिर्ब्रह्म
                    शान्ति: सर्व ग्वंग शान्ति: शान्तिरेव
                    शान्ति: सामा शान्तिरेधि।।
                    यतो यत: समीहसे ततो नो अभयं कुरु ।
                    शं न: कुरु प्रजाभ्यो भयं न: पशुभ्य: ।।

                    सुशान्तिर्भवतु

                    श्री मन्महागणाधिपतये नमः।
                    लक्ष्मीनारायणाभ्यां नम:।
                    उमामहेश्वराभ्यां नम:।
                    वाणीहिरण्यगर्भाभ्यां नं:।

                    शचिपुरन्दराभ्यां नम:।
                    इष्टदेवताभ्यो नम:।
                    कुलदेवताभ्यो नम:।
                    ग्रामदेवताभ्यो नम:।
                    वास्तुदेवताभ्यो नम:।
                    स्थानदेवताभ्यो नम:।
                    सर्वेभ्यो देवेभ्यो नम:।
                    सर्वेभ्यो ब्राह्मणेभ्यो नम:।
                    ॐ सिद्धिबुद्धिसहिताय श्री मन्महागणाधिपतये नम:।`;

export const pauranikSloka=`सुमुखश्चैकदन्तश्च
                    कपिलो गजकर्णकः।
                    लम्बोदरश्च विकटो
                    विघ्ननाशो विनायक:।।

                    धूम्रकेतुर्गणाध्यक्षो
                    भालचन्द्रो गजानन:।
                    द्वद्शैतानि नामानि
                    यः पठे च्छ्रिणुयादपी।।

                    विद्यारंभे विवाहे च
                    प्रवेशे निर्गमे तथा।
                    संग्रामे संकटे चैव
                    विघ्नस्तस्य न जायते।।

                    शुक्लाम्बरधरं देवं
                    शशिवर्णं चतुर्भुजम्।
                    प्रसन्नवदनं ध्यायेत्
                    सर्व्विघ्नोपशान्तये।।

                    अभिप्सितार्थ सिद्ध्यर्थं
                    पूजितो य: सुरासुरै:।
                    सर्वविघ्नहरस्तस्मै
                    गणाधिपतये नम:।।

                    सर्वमङ्गलमाङ्गल्ये !
                    शिवे ! सर्वार्थसाधिके।
                    शरण्ये त्र्यम्बिके !
                    गौरी नारायणि नमोस्तुते।।

                    सर्वदा सर्वकार्येषु
                    नास्ति तेषाममङ्गलम्।
                    येषां हृदयस्थो भगवान्
                    मङ्गलायतनो हरि:।।

                    तदेव लग्नं सुदिनं तदेव,
                    ताराबलं चन्द्रबलं तदेव ।
                    विद्याबलं दैवबलं तदेव,
                    लक्ष्मीपते तेन्घ्रियुगं स्मरामि।।
                    लाभस्तेषां जयस्तेषां
                    कुतस्तेषां पराजय:।
                    येषामिन्दीवरश्यामो
                    हृदयस्थो जनार्दन:।।

                    यत्र योगेश्वर: कृष्णो
                    यत्र पार्थो धनुर्धर:।
                    तत्र श्रीर्विजयो भूति
                    र्ध्रुवा नीतिर्मतिर्मम।।

                    अनन्यास्चिन्तयन्तो मां
                    ये जना: पर्युपासते।
                    तेषां नित्याभियुक्तानां
                    योगक्षेमं वहाम्यहम्।।

                    स्मृतेःसकल कल्याणं
                    भाजनं यत्र जायते।
                    पुरुषं तमजं नित्यं
                    व्रजामि शरणं हरम्।।

                    सर्वेष्वारंभ कार्येषु
                    त्रय:स्त्री भुवनेश्वरा:।
                    देवा दिशन्तु नः सिद्धिं
                    ब्रह्मेशानजनार्दना:।।

                    विश्वेशम् माधवं दुन्धिं
                    दण्डपाणिं च भैरवम्।
                    वन्दे कशी गुहां गंगा
                    भवानीं मणिकर्णिकाम्।।

                    वक्रतुण्ड् महाकाय
                    सूर्य कोटि समप्रभ।
                    निर्विघ्नं कुरु में देव
                    सर्वकार्येषु सर्वदा।।
                    ॐ श्री गणेशाम्बिका भ्यां नम: ।`
