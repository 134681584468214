
const sarvatoBhadraAvahan=[
    {
        title:`१. ब्रह्मा (मध्य वापी)`,
        des1:`ॐ ब्रह्म यज्ञानं प्रथमं पुरस्ताद्विसीमतः सुरुचो वेनऽआवः। सबुध्न्या उपमाऽअस्य विष्ठाः सतश्च योनिमसश्च विवः॥`,
        des2:`ॐ भूर्भुवः स्वः ब्रह्मण इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः ब्रह्मणे नमः ॥`
    },
    {
        title:`२. सोम (उत्तर वापी)`,
        des1:`ॐ वय ᳪ सोमव्रते तव मनस्तनूषु बिब्भ्रतः। प्रजावन्तः सचेमहि॥`,
        des2:`ॐ भूर्भुवः स्वः सोम इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः सोमाय नमः॥`
    },
    {
        title:`३. ईशान (ईशान खण्ड)`,
        des1:`ॐ तमीशानञ्जगतस्तस्थुषस्पतिं धियञ्जिन्वमवसे हूमहे वयं। पूषा नो यथा वेदसामसद्वृधे रक्षिता पायुरदब्धः स्वस्तये ॥`,
        des2:`ॐ भूर्भुवः स्वः ईशान इहागच्छ, इह तिष्ठ । ॐ भूर्भुवः स्वः ईशानाय नमः॥`
    },
    {
        title:`४. इन्द्र (पूर्व वापी)`,
        des1:`ॐ त्रातारमिन्द्रमवितारमिन्द्रᳪ हवे हवे सुहव ᳪ शूरमिन्द्रम्, ह्वायामि शक्रम्पुरुहूतमिन्द्र ᳪ स्वस्ति नो मघवा धात्विन्द्रः॥`,
        des2:`ॐ इन्द्र, इहागच्छ, इह तिष्ठ । ॐ भूर्भुवः स्वः इन्द्राय नमः॥`
    },
    {
        title:`५. अग्नि (अग्निकोण खण्ड )`,
        des1:`ॐ त्वन्नोऽअग्ने तव पायुभिर्मघोनो रक्ष तन्वश्च वन्द्य। त्राता तोकस्य तनये गवामस्य निमेषᳪरक्षमाणस्तवव्व्रते॥`,
        des2:`ॐ भूर्भुवः स्वः अग्ने इहागच्छ, इह तिष्ठ।ॐ भूर्भुवः स्वः अग्नये नमः ॥`
    },
    {
        title:`६. यम (दक्षिण वापी)`,
        des1:`ॐ यमाय त्वांगिरस्वते पितृमते स्वाहा। स्वाहा घर्माय स्वाहा धर्मः पित्रे ॥`,
        des2:`ॐ भूर्भुवः स्वः यम इहागच्छ, इह तिष्ठ। ॐ भूर्भुवः स्वः यमाय नमः॥`
    },
    {
        title:`७. निर्ऋति (नैर्ऋत्यकोण खण्ड)`,
        des1:`ॐ असुन्नवन्तमयजमानमिच्छस्तेनस्येत्यामन्विहितस्करस्य। अन्यमस्मदिच्छ सात ऽइत्या नमो देवि निर्ऋते तुभ्यमस्तु ॥`,
        des2:`ॐ भूर्भुवः स्वः निरृत इहागच्छ, इह तिष्ठ । ॐ भूर्भुवः स्वः निर्ऋतये ॥`
    },
    {
        title:`८. वरुण (पश्चिम वापी)`,
        des1:`ॐ तत्त्वा यामि ब्रह्मणा वन्दमानस्तदाशास्ते यजमानो हविर्भिः। अहेडमानो वरुणेह बोध्युरुशᳪस मा नऽआयुः प्रमोषीः॥`,
        des2:`ॐ भूर्भुवः स्वः वरुण इहागच्छ, इह तिष्ठ। ॐ भूर्भुवः स्वः वरुणाय नमः॥`
    },
    {
        title:`९. वायु (वायुकोण खण्ड)`,
        des1:`ॐ आ नो नियुद्भिः शतिनीभिरध्वर ᳪ सहश्रिणीभिरुपयाहि यज्ञम्। वायो ऽअस्मिन्त्सवने मादयस्व यूयम्पात स्वस्तिभिः सदा नः ॥`,
        des2:`ॐ भूर्भुवः स्वः वायु इहागच्छ, इह तिष्ठ । ॐ भूर्भुवः स्वः वायवे नमः॥`
    },
    {
        title:`१०. अष्टवसु (वायु-सोम मध्यभद्र)`,
        des1:`ॐ वसुभ्यस्त्वा रुद्रेभ्यस्त्वाऽऽदित्येभ्यस्त्वा सञ्जानाथां द्यावापृथिवी मित्रावरुणौ त्वा वृष्ट्यावताम्। व्यन्तु व्योक्त ᳪ रिहाणा मरुतां पृषतीर्गच्छ व्वशा प्रिश्न्निर्ब्भूत्वा दिव ᳪ गच्छ ततो नो वृष्टिमावह। चक्षुष्पा ऽअग्नेऽसि चक्षुर्मे पाहि॥ `,
        des2:`ॐ भूर्भुवः स्वः अष्टवसवः इहागच्छत इह तिष्ठत। ॐ भूर्भुवः स्वः अष्टवसुभ्यो नमः॥`
    },
    {
        title:`११. एकादशरुद्र (सोम-ईशान मध्यभद्र)`,
        des1:`ॐ नमस्ते रुद्र मन्यव ऽउतो त ऽइषवे नमः॥ बाहुब्भ्यामुत ते नमः॥`,
        des2:`ॐ भूर्भुवः स्वः एकादशरुद्राः इहागच्छत इह तिष्ठत॥ ॐ भूर्भुवः स्वः एकादशरुद्रेभ्यो नमः॥`
    },
    {
        title:`१२. द्वादशादित्य (ईशानेन्द्र मध्यभद्र)`,
        des1:`ॐ यज्ञो देवानां प्रत्येति सुम्नमादित्यासो भवता मृडयन्तः । आ वोऽर्व्वाची सुमतिर्ववृत्त्याद ᳪ होश्चिद्या व्वरिवोवित्तरासत् ॥`,
        des2:`ॐ भूर्भुवः स्वः द्वादशादित्याः इहागच्छत इह तिष्ठत॥ ॐ भूर्भुवः स्वः द्वादशादित्येभ्यो नमः॥`
    },
    {
        title:`१३. अश्विनी (इन्द्राग्नी मध्यभद्र)`,
        des1:`ॐ अश्विना तेजसा चक्षुः प्प्राणेन सरस्वती वीर्य्यम् । व्वाचेन्द्रो बलनेन्द्राय दधुरिन्द्रियम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः अश्वनौ इहागच्छतं इह तिष्ठतं । ॐ भूर्भुवः स्वः अश्विभ्यां नमः॥`
    },
    {
        title:`१४. सपैत्रिक विश्वेदेव (अग्न-यम मध्यभद्र)`,
        des1:`ॐ विश्वेदेवास ऽआगत शृणुता म ऽइम ᳪ हवम् । एदं बर्हिर्न्नषीदत । उपयामगृहीतोऽसि व्विश्श्वेब्भ्यस्त्वा देवेभ्य ऽएष ते योनिर्व्विश्वेब्भ्यस्त्वा देवेब्भ्यः ॥`,
        des2:`ॐ भूर्भुवः स्वः सपैत्रिकविश्वेदेवाः इहागच्छत इह तिष्ठत।। ॐ भूर्भुवः स्वः सपैतृकविश्वेभ्यो देवेभ्यो नमः॥`
    },
    {
        title:`१५. सप्तयक्ष (यम-निर्ऋति मध्यभद्र)`,
        des1:`ॐ अभित्त्यं देव ᳪ सवितारमोण्योः कविक्क्रतुमर्च्चीमि सत्त्यसव ᳪ रत्नधामभि प्रियं मतिं कविम् । ऊर्ध्वा यस्याऽमतिर्भाअदिद्यु तत्सर्वामनि हिरण्यपाणिरमिमीत सुक्क्रतुः कृ॒पा स्वः । प्रजाब्भ्स्त्वा प्प्रजास्त्वाऽनुष्प्राणन्तु प्रजास्त्वमनुष्प्राणिहि ॥`,
        des2:`ॐ भूर्भुवः स्वः सप्तयक्षाः इहागच्छत इह तिष्ठत । ॐ भूर्भुवः स्वः सप्तयक्षेभ्यो नमः॥`
    },
    {
        title:`१६. अष्टकुलनाग (निर्ऋति-वरुण मध्यभद्र) `,
        des1:`ॐ नमोऽस्तु सर्पेभ्यो ये के च पृथिवीमनु । ये ऽअन्तरिक्षे ये दिवि तेब्भ्यः सर्पेभ्यो नमः॥`,
        des2:`ॐ भूर्भुवः स्वः अष्टकुलनागाः इहागच्छत इह तिष्ठत। ॐ अष्टकुलनागेभ्यो नमः॥`
    },
    {
        title:`१७. गन्धर्वाप्सरा (वरुण-वायु मध्यभद्र) `,
        des1:`ॐ ऋताषाड् ऋतधामाग्ग्निगन्धर्व्वस्तस्यौषधयोऽप्सरसो मुदो नाम । स न इदं ब्रह्म क्षत्रं पातु तस्मै । स्वाहा व्वाट् ताब्भ्यः स्वाहा ॥ `,
        des2:`ॐ भूर्भुवः स्वः गन्धर्वाप्सराः इहागच्छत इह तिष्ठत। ॐ भूर्भुवः स्वः गन्धर्वाऽप्सरोभ्यो नमः ॥`
    },
    {
        title:`१८. स्कन्द (ब्रह्म-सोम मध्य वापी)`,
        des1:`ॐ यदक्क्रन्दः प्प्रथमं जायमान ऽउद्यन्त्समुद्रादुत वा पुरीषात् । श्येनस्य पक्षा हरिणस्य बाहू ऽउपस्तुत्त्यं महि जातं ते ऽअर्व्वन् ॥ `,
        des2:`ॐ भूर्भुवः स्वः स्कन्द इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः स्कन्दाय नमः॥`
    },
    {
        title:`१९. वृषभ (तदुत्तर)`,
        des1:`ॐ आशुः शिशानो वृषभो न भीमो घनाघनः क्षोभणश्चर्षणीनाम् । संक्रन्दनोऽनिमिष ऽएकवीरः शत ᳪ सेना अजयत्साकमिन्द्रः ॥ `,
        des2:`ॐ भूर्भुवः स्वः वृषभ इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः वृषभाय नमः॥`
    },
    {
        title:`२०. शूल (तदुत्तर)`,
        des1:`ॐ कार्षिरसि समुद्रस्य त्वाक्षित्याऽउन्नयामि। समापोऽअद्भिरग्मतसमोषधीभिरोषधीः॥ `,
        des2:`ॐ भूर्भुवः स्वः शूल इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः शूलाय नमः॥`
    },
    {
        title:`२१. महाकाल (तदुत्तर)`,
        des1:`ॐ कार्षिरसि समुद्रस्य त्वाक्षित्याऽउन्नयामि। समापो ऽअद्भिरग्मत-समोषधीभिरोषधीः॥ `,
        des2:`ॐ भूर्भुवः स्वः महाकाल इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः महाकालाय नमः॥`
    },
    {
        title:`२२. दक्षादिसप्तगण (ब्रह्म-ईशान मध्यश्रृंखला)`,
        des1:`ॐ शुक्रज्योतिश्च चित्रज्योतिश्च सत्यज्योतिश्च ज्योतिष्मांश्च। शुक्रश्च ऽऋतपाश्चात्य हाः ॥ `,
        des2:`ॐ भूर्भुवः स्वः दक्षादिसप्तगणाः इहागच्छत इह तिष्ठत। ॐ भूर्भुवः स्वः दक्षादिसप्तगणेभ्यो नमः॥`
    },
    {
        title:`२३. दुर्गा (ब्रह्म-इन्द्र मध्यवापी)`,
        des1:`ॐ अम्बेऽअम्बकेऽम्बालिके न मा नयति कश्चन ॥ सस॑स्त्यश्वकः सुभद्रिकां काम्पीलवासिनीम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः दुर्गे इहाऽगच्छ इह तिष्ठ ।। ॐ भूर्भुवः स्वः दुर्गायै नमः॥`
    },
    {
        title:`२४. विष्णु (तत्पूर्व)`,
        des1:`ॐ इदं विष्णुर्विचक्रमे त्रेधा निदधे पदं। समूढमस्य पा ᳪ सुरे स्वाहा ॥`,
        des2:`ॐ भूर्भुवः स्वः विष्णो इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः विष्णवे नमः॥`
    },
    {
        title:`२५. स्वधा (ब्रह्म-अग्नि मध्यश्रृंखला)`,
        des1:`ॐ पितृभ्यः स्वधायिभ्यः स्वधा नमः पितामहेब्भ्यः स्वधायिब्भ्यः स्वधा नमः प्रपितामहेभ्यः स्वधायिब्भ्यः स्व॒धा नमः॥ अक्षन्न्पितरोऽमीमदन्त पितरोऽतीतृपन्त पितरः पितरः शुन्धद्ध्वम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः स्वधे इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः स्वधायै नमः॥`
    },
    {
        title:`२६. मृत्युरोग (ब्रह्म-यम मध्यश्रृंखला)`,
        des1:`ॐ परं मृत्यो ऽअनु परेहि पन्थां यस्ते ऽअन्यऽइतरो देवयानात्। चक्षुष्मते शृण्वते ते ब्रवीमि मा नः प्रजा ᳪ रीरिषो मोत वीरान्॥ `,
        des2:`ॐ भूर्भुवः स्वः मृत्युरोगाः इहागच्छत इह तिष्ठत। ॐ भूर्भुवः स्वः मृत्युरोगेभ्यो नमः॥`
    },
    {
        title:`२७. गणपति (ब्रह्म-निरृति मध्यश्रृंखला)`,
        des1:`ॐ गणानान्त्वा गणपति ᳪ हवामहे प्रियाणान्त्वा प्रियपति ᳪ हवामहे निधीनान्त्वा निधिपतिᳪ हवामहे व्वसो मम आहमजानि गर्भधमात्त्वमजासि गर्भधम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः गणपते इहागच्छ इह तिष्ठ । ॐ भूर्भुवः स्वः गणपतये नमः ॥`
    },
    {
        title:`२८. अप (ब्रह्म-वरुण मध्यश्रृंखला)`,
        des1:`ॐ आपो हिष्ठा मयोभुवस्तानऽऊर्जे दधातन महेरणाय चक्षसे यो वः शिवतमो रसस्तस्य भाजयतेह नः उशतीरिव मातरः। तस्माऽअरङ्गमामवो यस्य क्षयाय जिन्वथ आपो जनयथा च नः॥ `,
        des2:`ॐ भूर्भुवः स्वः अद्भ्यः इहागच्छत इह तिष्ठत। ॐ भूर्भुवः स्वः अद्भ्यो नमः ॥`
    },
    {
        title:`२९. मरुत (ब्रह्म-वायु मध्यश्रृंखला)`,
        des1:`ॐ मरुतो यस्य हि क्षये पाथा दिवो विमहसः। स सुगोपातमो जनः ॥ `,
        des2:`ॐ भूर्भुवः स्वः मरुताः इहागच्छत इह तिष्ठत। ॐ भूर्भुवः स्वः मरुद्भ्यो नमः ॥`
    },
    {
        title:`३०. पृथिवी (ब्रह्मपादमूल)`,
        des1:`ॐ स्योना पृथिवि नो भवानृक्षरानिवेशनी यच्छा नः शर्मसप्रथाः॥ `,
        des2:`ॐ भूर्भुवः स्वः पृथिवि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः पृथिव्यै नमः॥`
    },
    {
        title:`३१. गङ्गादिनदी (तदुत्तर)`,
        des1:`ॐ पञ्चनद्यः सरस्वतीमपियन्ति सस्रोतसः। सरस्वती तु पञ्चधा सो देशे ऽभवत्सरित् ॥ `,
        des2:`ॐ भूर्भुवः स्वः गङ्गादिनद्यः इहागच्छत इह तिष्ठत। ॐ भूर्भुवः स्वः गङ्गादिनदीभ्यो नमः ॥`
    },
    {
        title:`३२. सप्तसागर (तदुत्तर)`,
        des1:`ॐ समुद्रोऽसि नभस्वानार्द्द्रदानुः शम्भूर्म्मयोभूरभि मा व्वाहि स्वाहा । अवस्यूसि दुवस्वाञ्छम्भूर्म्मयोभूरभि मा व्वाहि स्वाहा ॥ `,
        des2:`ॐ भूर्भुवः स्वः सप्तसागराः इहागच्छत इह तिष्ठत। ॐ भूर्भुवः स्वः सप्तसागरेभ्यो नमः ॥`
    },
    {
        title:`३३. मेरु (कर्णिकापरिधि)`,
        des1:`ॐ प्र पर्व्वतस्य व्वृषभस्य पृष्ट्ठान्नावरश्चरन्ति स्वसि च ऽइयानाः । ता ऽआववृन्नधरागुदक्ता अहिर्बुधन्यमनु रीयमाणाः । विष्ष्णोर्व्विक्क्रमणमसि व्विष्ष्णोर्विक्क्रान्तमसि व्विष्ष्णोः क्क्रान्तमसि॥ `,
        des2:`ॐ भूर्भुवः स्वः मेरो इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः मेरवे नमः॥`
    },
    {
        title:`३४. गदा (श्वेतपरिधि उत्तर)`,
        des1:`ॐ गणानान्त्वा गणपति ᳪ हवामहे प्रियाणान्त्वा प्रियपति ᳪ हवामहे निधीनान्त्वा निधिपतिᳪ हवामहे व्वसो मम आहमजानि गर्भधमात्त्वमजासि गर्भधम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः गदे इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः गदायै नमः॥`
    },
    {
        title:`३५. त्रिशूल (ईशानकोण)`,
        des1:`ॐ त्रि ᳪशद्धाम विराजति व्वाक्क्पतङ्गाय धीयते । प्रति वस्तोरह द्य भिः ॥ `,
        des2:`ॐ भूर्भुवः स्वः त्रिशूल इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः त्रिशूलाय नमः॥`
    },
    {
        title:`३६. वज्र (पूर्व)`,
        des1:`ॐ महाँ२ इन्द्रो व्वज्रहस्तः षोडशी शर्म्म यच्छतु। हन्न्तु पाप्मानं योऽस्मान्द्वेष्टि । उपयामगृहीतोऽसि महेन्द्रायत्त्वैष ते योनिर्म्महेन्द्राय त्वा ॥ `,
        des2:`ॐ भूर्भुवः स्वः वज्र इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः वज्राय नमः॥`
    },
    {
        title:`३७. शक्ति (अग्निकोण)`,
        des1:`ॐ व्वसु च मे व्वसतिश्च मे कर्म्म च मे ऽर्थश्च म ऽएमश्च म ऽइत्या च मे गतिश्च मे यज्ञेन कल्पन्ताम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः शक्ति इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः शक्तये नमः॥`
    },
    {
        title:`३८. दण्ड (दक्षिण)`,
        des1:`ॐ इड ऽएह्यदित ऽएहि काम्या ऽएत । मयि वः कामधरणं भूयात् ॥ `,
        des2:`ॐ भूर्भुवः स्वः दण्ड इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः दण्डाय नमः॥`
    },
    {
        title:`३९. खड्ग (नैरृत्यकोण)`,
        des1:`ॐ खड्‌गो व्वैश्श्वदेवः श्वा कृष्णः कर्ण्णो गर्दभस्तरक्षुस्ते रक्षसामिन्द्राय सूकरः सि ᳪ हो मारुतः कृकलासः पिप्पका शकुनिस्ते शरव्यायै विश्वेषां देवानां पृषतः ॥ `,
        des2:`ॐ भूर्भुवः स्वः खड्ग इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः खड्गाय नमः॥`
    },
    {
        title:`४०. पाश (पश्चिम)`,
        des1:`ॐ उदुत्तमं व्वरुण पाशमस्स्मदवाधमं व्वि मध्यम ᳪ श्रथाय । अथा व्वयमादित्य व्रते तवानागसो अदितये स्याम ॥ `,
        des2:`ॐ भूर्भुवः स्वः पाश इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः पाशाय नमः॥`
    },
    {
        title:`४१. अङ्कुश (वायव्यकोण)`,
        des1:`ॐ अ ᳪ शुश्श्च मे रश्मिश्श्च मेऽदाब्ध्यश्च मेऽधिपतिश्श्च म ऽउपा ᳪ शुश्श्च मेऽन्तर्य्यामश्श्च मऽएन्द्रवायवश्श्च मे मैत्र्त्रावरुणश्श्च म ऽआश्वनश्श्च मे प्प्रतिप्प्रस्थानश्श्च मे शुक्क्रश्श्चमे मन्थी च मे यज्ञेन कल्पन्ताम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः अङ्कुश इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः अङ्कुशाय नमः॥`
    },
    {
        title:`४२. गौतम (रक्तपरिधि उत्तर)`,
        des1:`ॐ आयङ्गौः पृश्निरक्रमीदसदन्मातरं पुरः । पितरञ्च प्रयत्न्स्वः ॥ `,
        des2:`ॐ भूर्भुवः स्वः गौतम इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः गौतमाय नमः॥`
    },
    {
        title:`४३. भरद्वाज (ईशानकोण)`,
        des1:`ॐ अयं दक्षिणा विश्श्वकर्मा तस्य मनो व्वैश्श्वकर्मणं ग्रीष्मो मानसस्त्रिष्टुब्ग्रैष्मी त्रिष्टुभः स्वार ᳪ स्वारादन्तर्य्यामोऽन्तर्य्यामापञ्चदशः पञ्चदशाद् बृहद्भरद्वाज ऽऋषिः प्रजापतिगृहीतया त्वया मनो गृह्णामि प्रजाब्भ्यः ॥ `,
        des2:`ॐ भूर्भुवः स्वः भरद्वाज इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः भरद्वाज नमः॥`
    },
    {
        title:`४४. विश्वामित्र (पूर्व)`,
        des1:`ॐ इदमुत्तरात्स्वस्तस्य श्रोत्र्त्र ᳪ सौव ᳪ शरच्छ्रौत्र्यनुष्टुप् शारद्यनुष्टुभ ऽऐडमैडान्मन्थी मन्थिन ऽएकवि ᳪ शाद्द्वैराजं विश्श्वामित्रऽऋषिः प्रजापतिगृहीतया त्वया श्श्रोत्रं गृह्णामि प्प्रजाभ्यः ॥ `,
        des2:`ॐ भूर्भुवः स्वः विश्वामित्र इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः विश्वमित्राय नमः॥`
    },
    {
        title:`४५. कश्यप (अग्निकोण)`,
        des1:`ॐ त्र्यायुषं ज्जमदग्नेः कश्यपस्य त्र्यायुषम् । यद्द्देवेषु त्र्यायुषं तन्नों ऽअस्तु त्र्यायुषम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः कश्यप इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः कश्यपाय नमः॥`
    },
    {
        title:`४६. जमदग्नि (दक्षिण) `,
        des1:`ॐ अयं पश्श्चाद्विश्श्वव्यचास्तस्य चक्षुर्व्वैश्श्वव्व्यचसं वर्षाश्श्चाक्षुष्ष्यो जगती व्वार्षी जगत्या ऽऋक्सममृक्समाच्छुक्क्रः शुक्क्रात्सप्तदशः सप्तदशाद् जमदग्ग्नर्ऋषिः प्रजापतिगृहीतया त्वया चक्षुर्गृह्णामि प्रजाब्भ्यः ॥ `,
        des2:`ॐ भूर्भुवः स्वः जमदग्नि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः जमदग्नये नमः॥`
    },
    {
        title:`४७. वशिष्ठ (नैरृत्यकोण)`,
        des1:`ॐ अयं पुरो भुवस्तस्य प्राणो भौवायनो व्वसन्तः प्प्राणाय नो गायत्त्री व्वासन्ती गायत्र्यै गायत्रं गायत्त्रादुपा ᳪ शुरुपा ᳪ शोस्त्रिवृत्रिवृतो रथन्तरं व्वसिष्ठ ऽऋषि प्प्रजापतिगृहीतया त्वया प्राणं गृह्णामि प्रजाब्भ्यः ॥ `,
        des2:`ॐ भूर्भुवः स्वः वशिष्ठ इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः वशिष्ठाय नमः॥`
    },
    {
        title:`४८. अत्रि (पश्चिम)`,
        des1:`ॐ अत्त्र पितरो मादयद्ध्वं य्यथाभाग मावृषायद्ध्वम् । अमीमदन्त पितरो यथाभाग मावृषायिषत ॥ `,
        des2:`ॐ भूर्भुवः स्वः अत्रि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः अत्रये नमः॥`
    },
    {
        title:`४९. अरुन्धती (वायव्यकोण)`,
        des1:`ॐ तं पत्नीभिरनुगच्छेम देवाः पुत्रैर्भ्रातृभिरुत वा हिरण्ण्यैः । नाकं गृब्भ्णानाः सुकृतस्य लोके तृतीये पृष्ठे ऽधिरोचने दिवः ॥ `,
        des2:`ॐ भूर्भुवः स्वः अरुन्धति इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः अरुन्धत्यै नमः॥`
    },
    {
        title:`५०. ऐन्द्री (कृष्णपरिधि पूर्व)`,
        des1:`ॐ अदित्यै रास्स्नासीन्द्राण्ण्या ऽउष्ष्णीषः । पूषासि घर्म्माय दीष्ष्व॥ `,
        des2:`ॐ भूर्भुवः स्वः ऐन्द्रि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः ऐन्द्रयै नमः॥`
    },
    {
        title:`५१. कौमारी (अग्निकोण) `,
        des1:`ॐ अम्बेऽअम्बकेऽम्बालिके न मा नयति कश्चन ॥ सस॑स्त्यश्वकः सुभद्रिकां काम्पीलवासिनीम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः कौमारि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः कौमार्यै नमः॥`
    },
    {
        title:`५२. ब्राह्मी (दक्षिण)`,
        des1:`ॐ इन्द्रायाहि धियेषितो व्विप्प्रजूतः सुतावतः उप ब्ब्रह्माणि व्वाग्घतः ॥`,
        des2:`ॐ भूर्भुवः स्वः ब्राह्मि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः ब्राह्म्यै नमः॥`
    },
    {
        title:`५३. वाराही (नैरृत्यकोण)`,
        des1:`ॐ आयङ्गौः पृश्निरक्रमीदसदन्मातरं पुरः । पितरञ्च प्रयत्न्स्वः॥`,
        des2:`ॐ भूर्भुवः स्वः वाराहि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः वाराह्यै नमः॥`
    },
    {
        title:`५४. चामुण्डा (पश्चिम)`,
        des1:`ॐ अम्बेऽअम्बकेऽम्बालिके न मा नयति कश्चन ॥ सस॑स्त्यश्वकः सुभद्रिकां काम्पीलवासिनीम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः चामुण्डे इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः चामुण्डायै नमः॥`
    },
    {
        title:`५५. वैष्णवी (वायव्यकोण)`,
        des1:`ॐ आप्यायस्व समेतु ते विश्वतः सोम वृष्ण्यम्। भवा व्वाजस्य सङ्गथे ॥ `,
        des2:`ॐ भूर्भुवः स्वः वैष्णवि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः वैष्णव्यै नमः॥`
    },
    {
        title:`५६. माहेश्वरी (उत्तर)`,
        des1:`ॐ या ते रुद्द्र शिवा तनूरघोराऽपापकाशिनी । तया नस्तन्न्वा शन्न्तमया गिरिशन्ताभि चाकशीहि ॥ `,
        des2:`ॐ भूर्भुवः स्वः माहेश्वरि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः माहेश्वर्यै नमः॥`
    },
    {
        title:`५७. वैनायकी (ईशानकोण)`,
        des1:`ॐ समख्ये देव्या धिया सन्दक्षिणयोरुचक्षसा । मा मऽआयुः प्प्रमोषीर्म्मो ऽअहं तव व्वीरं व्विदेय तव देवि सन्दृशि ॥ `,
        des2:`ॐ भूर्भुवः स्वः वैनायकि इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः वैनायक्यै नमः॥`
    },
]



export {sarvatoBhadraAvahan}













