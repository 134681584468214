const kushkundika=[
    {
        title:`पूर्व दिशा में`,
        des1:`ॐ प्राची दिगग्निरधिपतिरसितो रक्षितादित्या इषवः। तेभ्यो नमोऽधि- पतिभ्योनमो रक्षितृभ्यो नमइषुभ्यो नमएभ्यो अस्तु। यो३स्मान् द्वेष्टि यं वयं द्विष्मस्तं वो जम्भे दध्मः।  अथर्व०३.२७.१`
    },
    {
        title:`दक्षिण दिशा में`,
        des1:`ॐ दक्षिणा दिगिन्द्रोऽ धिपतिस्तिरश्चिराजी रक्षिता पितर इषवः। तेभ्यो नमोऽधिपतिभ्यो नमो रक्षितृभ्यो नमइषुभ्यो नमएभ्यो अस्तु। यो३स्मान् द्वेष्टि यं वयं द्विष्मस्तं वो जम्भे दध्मः। -अथर्व० ३.२७.२ `
    },
    {
        title:`पश्चिम दिशा में`,
        des1:`ॐ प्रतीची दिग्वरुणोऽधिपतिः पृदाकू रक्षितान्नमिषवः। तेभ्यो नमोऽधि- पतिभ्यो नमो रक्षितृभ्यो नमइषुभ्यो नमएभ्यो अस्तु। यो३स्मान् द्वेष्टि यं वयं द्विष्मस्तं वो जभ्ये दध्मः। -अथर्व. ३.२७.३ `
    },
    {
        title:`उत्तर दिशा में`,
        des1:`ॐ उदीची दिक्सोमोऽधिपतिः स्वजो रक्षिताशनिरिषवः। तेभ्यो नमोऽधि- पतिभ्यो नमो रक्षितृभ्यो नमइषुभ्यो नम एभ्यो अस्तु। यो३स्मान् द्वेष्टि यं वयं द्विष्मस्तं वो जम्भे दध्मः। -अथर्व. ३.२७.४ `
    }
]

const navgrahDevHom  = [
    {
        title: `१.सूर्य (समिधा अर्क)`,
        des1: ` ॐ आ कृष्णेन रजसा वर्तमानो निवेशयन्न मृतं मर्त्यं च । हिरण्येन सविता रथेना देवो याति भूवनानि पश्यन् स्वाहा, इदं सूर्याय ॥`
    },
    {
        title: `२.चन्द्र (समिधा पलाश)`,
        des1: `ॐ इमं देवा असपत्न ᳪ सुवध्वं महते क्षत्राय महते ज्यैष्ठ्याय महते जानराज्यायेन्द्रस्येन्द्रियाय । इमममुष्य पुत्रममुष्यै पुत्रमस्यै विश एष वोऽमी राजा सोमोऽस्माकं ब्राह्मणाना ᳪ राजा स्वाहा, इदं चन्द्रमसे॥`
    },
    {
        title: `३.मङ्गल (समिधा खैर)`,
        des1: `ॐ अग्निर्मूर्धा दिवः ककुत्पतिः पृथिव्या अयम् । अपाᳪ रेताᳪ सि जिन्वति स्वाहा, इदं कुजाय॥`
    },
    {
        title: `४.बुध (समिधा अपामार्ग) - (ईशानकोण में, हरा, धनुष) - पीले, हरे अक्षत-पुष्प`,
        des1: `ॐ उद्‍बुध्यस्वाग्ने प्रतिजागृहि त्वभिष्टापूर्ते स ᳪ सृजेथामयं च । अस्मिन्त्सधस्थे अध्युत्तरस्मिन् विश्वेदेवा यजमानश्च सीदत स्वाहा, इदं बुधाय॥`
    },
    {
        title: `५. बृहस्पति (समिधा पीपल) - पीले अक्षत-पुष्पसे`,
        des1: `ॐ बृहस्पते अति यदर्यो अहार्द द्युमद्विभाति क्रतुमज्जनेषु । यदीदयच्छवसऋतप्रजात तदस्मासु द्रविणं धेहि चित्रम् स्वाहा, इदं गुरवे॥`
    },
    {
        title: `६. शुक्र (समिधा गूलर) - श्वेत अक्षत-पुष्प`,
        des1: `ॐ अन्नात्परिस्त्रुतो रसं ब्रह्मणा व्यपिबत्क्षत्रं पयः सोमं प्रजापतिः । ऋतेन सत्यमिन्द्रियं विपान ᳪ शुक्रमन्धस इन्द्रस्येन्द्रियमिदं पयोऽमृतं मधु स्वाहा, इदं शुक्राय॥`
    },
    {
        title: `७. शनि (समिधा शमी)  - काले अक्षत-पुष्प`,
        des1: `ॐ शं नो देवीरभिष्ट्य आपो भवन्तु पीतये । शं योरभि स्रवन्तु नः स्वाहा, इदं शनये॥`
    },
    {
        title: `८.राहु (समिधा दूर्वा)`,
        des1: `ॐ कया नश्चित्र आ भुवदूती सदावृधः । सखा कया सचिष्ठया वृता स्वाहा, इदं राहवे॥ `,
    },
    {
        title: `९. केतु (समिधा कुशा) - धूमिल अक्षत-पुष्प`,
        des1: `ॐ केतुं कृण्वन्नकेतवे पेशो मर्या अपेशसे । समुषद्भिरजायथाः स्वाहा, इदं केतवे॥`
    },

];

const vastuDevHom=[
    {
        des2:`ॐ भूर्भुवः स्वः शिखिने नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः पर्जन्याय नमः  स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः जयन्ताय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कुलीशायुधाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सूर्याय नमः स्वाहा ॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सत्याय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः भृशाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः आकाशाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वायवे नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः पूष्णे नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वितथाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः गृहक्षताय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः यमाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः गन्धर्वाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः भृंगराजाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः मृगाय नमः स्वाहा ॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः पित्रे नमः स्वाहा ॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः दौवारिकाय नमः स्वाहा ॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सुग्रीवाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः पुष्पदन्ताय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वरुणाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः असुराय नमः स्वाहा ॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः शेषाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः पापाय नमः स्वाहा॥`
    },
    {
        des2:` ॐ भूर्भुवः स्वः रोगाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अहिर्बुध्न्याय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः मुख्याय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः भल्लाटाय नमः स्वाहा ॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सोमाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सर्पेभ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अदितये नमः स्वाहा ॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः दितये नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अद्भ्यो नमः स्वाहा ॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः आपवत्साय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अर्यम्णे नमः स्वाहा ॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सावित्राय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सवित्रे नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः विवस्वते नमः स्वाहा ॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः विबुधाधिपाय नमः स्वाहा ॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः जयाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः मित्राय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः राजयक्ष्मणे नमः स्वाहा ॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः रुद्राय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः पृथ्वीधराय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः ब्रह्मणे नमः स्वाहा॥`
    }
]

const sarvatoBhadraHome=[
    {
        des2:` ॐ भूर्भुवः स्वः ब्रह्मणे नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सोमाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः ईशानाय नमः स्वाहा`
    },
    {
        des2:`ॐ भूर्भुवः स्वः इन्द्राय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अग्नये नमः  स्वाहा॥`
    },
    {
        des2: `ॐ भूर्भुवः स्वः यमाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः निर्ऋतये स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वरुणाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वायवे नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अष्टवसुभ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः एकादशरुद्रेभ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः द्वादशादित्येभ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अश्विभ्यां नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सपैतृकविश्वेभ्यो देवेभ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सप्तयक्षेभ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ अष्टकुलनागेभ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः गन्धर्वाऽप्सरोभ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः स्कन्दाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वृषभाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः शूलाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः महाकालाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः दक्षादिसप्तगणेभ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः दुर्गायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः विष्णवे नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः स्वधायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः मृत्युरोगेभ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः गणपतये नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अद्भ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः मरुद्भ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः पृथिव्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः गङ्गादिनदीभ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सप्तसागरेभ्यो नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः मेरवे नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः गदायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः त्रिशूलाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वज्राय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः शक्तये नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः दण्डाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः खड्गाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः पाशाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अङ्कुशाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः गौतमाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः भरद्वाज नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः विश्वमित्राय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कश्यपाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः जमदग्नये नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वशिष्ठाय नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अत्रये नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अरुन्धत्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः ऐन्द्रयै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कौमार्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः ब्राह्म्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वाराह्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः चामुण्डायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वैष्णव्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः माहेश्वर्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वैनायक्यै नमः स्वाहा॥`
    },
]

const chaturLingatoHom=[
    {
        des2:`ॐ भूर्भुवः स्वः असिताङ्गभैरवाय नमः स्वाहा॥१॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः रुरुभैरवाय नमः स्वाहा ॥२॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः चण्डभैरवाय नमः स्वाहा ॥३॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः क्रोधभैरवाय नमः स्वाहा ॥४॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः उन्मत्तभैरवाय नमः स्वाहा  ॥५॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः कपालभैरवाय नमः स्वाहा॥६॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः भीषणभैरवाय नमः स्वाहा ॥७॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः संहारभैरवाय नमः स्वाहा ॥८॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः भवाय नमः स्वाहा ॥९॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः सर्वाय नमः स्वाहा ॥१०॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः पशुपतये नमः स्वाहा ॥११॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः ईशानाय नमः स्वाहा॥१२॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः रुद्राय नमः स्वाहा ॥१३॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः उग्ग्राय नमः स्वाहा ॥१४॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः भीमाय नमः स्वाहा ॥१५॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः महान्ते नमः स्वाहा ॥१६॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः अनन्ताय नमः स्वाहा ॥१७॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः वासुकये नमः स्वाहा ॥१८॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः तक्षकाय नमः स्वाहा ॥१९॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः कुलिशाय नमः स्वाहा ॥२०॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः कर्कोटकाय नमः स्वाहा ॥२१॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः शङ्खपालाय नमः स्वाहा ॥२२॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः कम्बलाय नमः स्वाहा  ॥२३॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः अश्वतराय नमः स्वाहा ॥२४॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः शूलाय नमः स्वाहा ॥२५॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः चन्द्रमौलिने नमः स्वाहा ॥२६॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः चन्द्रमसे नमः स्वाहा ॥२७॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः वृषभध्वजाय नमः स्वाहा ॥२८॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः त्रिलोचनाय नमः स्वाहा ॥२९॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः शक्तिधराय नमः स्वाहा ॥३०॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः महेश्वराय नमः स्वाहा ॥३१॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः शूलपाणये नमः स्वाहा ॥३२॥`
    }
    
]

const yoginiHom=[
    {
        des2:`ॐ भूर्भुवः स्वः महाकाल्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः महालक्ष्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः महासरस्वत्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः दिव्ययोगिन्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः महायोगिन्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सिद्धयोगिन्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः माहेश्वर्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः प्रेताक्ष्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः डाकिन्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः काल्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कालरात्र्यै नमः स्वाहा॥`
    },
    {
        des2:` ॐ भूर्भुवः स्वः निशाकर्यै नमः स्वाहा॥`
    },{
        des2:`ॐ भूर्भुवः स्वः हुङ्कार्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सिद्धवैतालिकायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः ह्रींकार्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः भूतडामरायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः ऊर्ध्वकेशिन्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः विरूपाक्ष्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः शुष्काङ्ग्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः नरभोजन्यै‌ नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः फेत्कार्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वीरभद्रायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः ध्रूम्राक्ष्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कलहप्रियायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः राक्षस्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः घोररक्ताक्ष्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः विशालाक्ष्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कौमार्य नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः चण्ड्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वाराह्यै नमः स्वाहा॥`
    },{
        des2:`ॐ भूर्भुवः स्वः मुण्डधारिण्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः भैरव्यै नमः  स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वीरायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः भयङ्कर्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वज्रधारिण्यै नमः  स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः क्रोधायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः दुर्मुख्यै नमः  स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः प्रेतवाहिन्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कर्कायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः दीर्घलम्बोष्ठयै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः मालिन्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः मन्त्रयोगिन्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कालाग्निमोहिन्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः मोहिन्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः चक्रायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कुण्डलिन्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः बालुकायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कौबेर्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः यमदूत्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः करालिन्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कौशिक्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः यक्षण्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः भक्षिण्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कौमार्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः मन्त्रवाहिन्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः विशालाक्ष्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कार्मुक्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः व्याघ्र्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः महाराक्षस्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः प्रेतभक्षिण्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः धूर्जट्यै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः विकटायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः घोररूपायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कपालिकायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः निकलायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अमलायै नमः स्वाहा॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सिद्धिप्रदायै नमः स्वाहा॥`
    }
];

const sodasmatrikaHom=[
    {
        des2:`ॐ भूर्भुवः स्वः गणपतये नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः गौर्यै नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः पद्मायै नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः शच्यै नमः स्वाहा।`
    },
    {
        des2:`ॐॐ भूर्भुवः स्वः मेधायै नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सावित्र्यै नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः विजयायै नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः जयायै नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः देवसेनायै नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः स्वधायै नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः स्वाहायै नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः मातृभ्यो नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः लोकमातृभ्यो नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः धृत्यै नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः पुष्टयै नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः तुष्टयै नमः स्वाहा।`
    },
    {
        des2:`ॐ भूर्भुवः स्वः आत्मनः कुलदेवतायै नमः स्वाहा।`
    }
]

const saptGhriMatrikaHom=[
    {
        des2:`ॐ भूर्भुवः स्वः श्रियै नमः स्वाहा।`,
   
    },
    {
        des2:`ॐ भूर्भुवः स्वः लक्ष्म्यै नमः स्वाहा।`,
    },
    {
        des2:`ॐ भूर्भुवः स्वः धृत्यै नमः स्वाहा।`,
    },
    {
        des2:`ॐ मेधायै नमः स्वाहा।`,
    },
    {
        des2:`ॐ भूर्भुवः स्वः स्वाहायै नमः स्वाहा।`,
    },
    {
        des2:` ॐ भूर्भुवः स्वः प्रज्ञायै नमः स्वाहा।`,
    },
    {
        des2:`ॐ सरस्वत्यै नमः स्वाहा।`,
    }

]

const chetrapaalHom=[
    {
        des2:`ॐ भूर्भुवः स्वः अजराय नमः अजरामा नमः स्वाहा ॥१॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः व्यापकाख्याय नमः स्वाहा ॥२॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः इन्द्रचौराय नमः स्वाहा ॥३॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः इन्द्रमूर्तये नमः स्वाहा ॥४॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः उक्षाभिधाय नमः स्वाहा ॥५॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कूष्माण्डाय नमः स्वाहा ॥६॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः वरुणाय नमः स्वाहा ॥७॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः बाहुकाख्याय नमः स्वाहा ॥८॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः विमुक्ताय नमः स्वाहा ॥९॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः लिप्तकाय नमः स्वाहा ॥१०॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः लीलालोकाय नमः स्वाहा ॥११॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः एकदंष्ट्राय नमः स्वाहा ॥१२॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः ऐरावताख्याय नमः स्वाहा ॥१३॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः ओषधीघ्नाय नमः स्वाहा ॥१४॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः बन्धनाख्याय नमः स्वाहा ॥१५॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः दिव्यकायाय नमः स्वाहा ॥१६॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः कम्बलाख्य नमः स्वाहा ॥१७॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः  क्षोभणाख्याय नमः स्वाहा ॥१८॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः गवे नमः स्वाहा ॥१९॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः घण्टाभिधाय नमः स्वाहा ॥२०॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः व्यालाय नमः स्वाहा ॥२१॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अणुस्वरूपाय नमः स्वाहा ॥२२॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः चन्द्रवारुणाय नमः स्वाहा ॥२३॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः फटाटोपाय नमः स्वाहा ॥२४॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः जटालाय नमः स्वाहा ॥२५॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः क्रतवे नमः स्वाहा ॥२६॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः घण्टेश्वराय नमः स्वाहा ॥२७॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः विटङ्काय नमः स्वाहा ॥२८॥ `
    },
    {
        des2:` ॐ भूर्भुवः स्वः मणिमतये नमः स्वाहा ॥२९॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः गणबन्धाय नमः स्वाहा ॥३०॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः डामराय नमः स्वाहा ॥३१॥`
    },
    {
        des2:` ॐ भूर्भुवः स्वः दुण्ढिकर्णाय नमः स्वाहा ॥३२॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः स्थविराय नमः स्वाहा ॥३३॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः दन्तुराय नमः स्वाहा ॥३४॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः धनदाय नमः स्वाहा ॥३५॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः नागकर्णाय नमः स्वाहा ॥३६॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः मारीगणाय नमः स्वाहा ॥३७॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः फेत्काराय नमः स्वाहा ॥३८॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः चीकराय नमः स्वाहा ॥३९॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः सिंहाकृतये नमः स्वाहा ॥४०॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः मृगाय नमः स्वाहा ॥४१॥`
    },
    {
        des2:` ॐ भूर्भुवः स्वः यक्ष्मप्रियाय नमः स्वाहा ॥४२॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः मेघवाहनाय नमः स्वाहा ॥४३॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः तीक्ष्णोष्ट्राय नमः स्वाहा ॥४४॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः अनलाय नमः स्वाहा ॥४५॥`
    },
    {
        des2:`ॐ भूर्भुवः स्वः शुक्लतुण्डाय नमः स्वाहा ॥४६॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः अन्तरिक्षाय नमः स्वाहा ॥४७॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः बर्बरकाय नमः स्वाहा ॥४८॥ `
    },
    {
        des2:`ॐ भूर्भुवः स्वः पावनाय नमः स्वाहा ॥४९॥`
    },

]

const navAhuti=[
    {
        des2:`ॐ भूः स्वाहा, इदं भूः॥`
    },
    {
        des2:`ॐ भुवः स्वाहा, इदं भुवः॥`
    },
    {
        des2:`ॐ स्वः स्वहा, इदं स्वः ॥`
    },
    {
        des2:`ॐ त्वन्नोऽअग्ने वरुणस्य विद्वान् देवस्य हेडो अवयासि सीष्ठाः। यजिष्ठो वह्नितमः शोशुचानो विश्वा द्वेषाᳪसि प्रमुमुग्ध्यस्मत् स्वाहा, इदमग्नीवरुणाभ्याम् ॥`
    },
    {
        des2:`ॐ स त्वन्नो अग्नेऽवमो भवोती नेदिष्ठो अस्या उषसो व्युष्टौ। अवयक्ष्व नो वरुणᳪरराणो वीहि मृडीकᳪसुहवो न एधि स्वाहा, इदमग्नीवरुणाभ्याम् ॥`
    },
    {
        des2:`ॐ अयाश्चाग्नेऽस्य नभिशस्तिपाश्च सत्यमित्त्वमया असि । अया नो यज्ञᳪवहास्ययानो धेहि भेषज ᳪ स्वाहा, इदमग्नये॥`
    },
    {
        des2:`ॐ ये ते शतँवरुण ये सहस्रं यज्ञियाः पाशा वितता महान्तः। तेभिर्न्नो अद्य सवितोत विष्णुर्विश्वे मुञ्चन्तु मरुतः स्वर्काः स्वाहा, इदं वरुणाय सवित्रे विष्णवे विश्वेभ्यो देवेभ्यो मरुद्भ्यः स्वर्केभ्यः॥`
    },
    {
        des2:`ॐ उदुत्तमँव्वरुण पाशमस्मदवाधमं विमध्यमᳪश्रथाय । अथा व्वयमादित्य व्रते तवानागसो अदितये स्याम स्वाहा, इदं वरुणाय ॥`
    },
    {
        des2:`ॐ प्रजापतये स्वाहा, इदं प्रजापतये॥`
    }
]

const bali=[
    {
        title:`इन्द्रादि दशदिक्पालबलि`,
        des:`ॐ प्राच्यै दिशे स्वाहार्वाच्यै दिशे स्वाहा दक्षिणायै दिशे स्वाहार्वाच्यै दिशे स्वाहा प्रतीच्यै दिशे स्वाहार्वाच्यै दिशे स्वाहोदीच्यै दिशे स्वाहार्वाच्यै दिशे स्वाहोर्ध्वायै दिशे स्वाहार्वाच्यै दिशे स्वाहावाच्यै दिशे स्वाहार्वाच्यै दिशे स्वाहा ॥ ॐ इन्द्रादिभ्यो दशभ्यो दशदिक्पालेभ्यो नमः । ॐ इन्द्रादि दशदिक्पालेभ्यः साङ्गेभ्यः सपरिवारेभ्यः सायुद्धेभ्यः सशक्तिकेभ्यः इमान् सदीपदधिमाषभक्तबलीन् समर्पयामि। भो भो इन्द्रादि दशदिक्पालाः! स्वां स्वां दिशं रक्षत बलिं भक्षत मम सकुटुम्बस्य सपरिवारस्य आयुः कर्तारः क्षेमकर्तारः शान्तिकर्तारः पुष्टिकर्तारः तुष्टिकर्तारः वरदा: भवत। अनेन बलिदानेन इन्द्रादिदशदिक्पालाः प्रियन्ताम् । `
    },
    {
        title:`नवग्रह बलि:`,
        des:`ॐ ग्रहा ऽऊर्जाहुतयो व्यन्तो विप्राय मतिम् । तेषाँविशिप्रियाणाँवो हमिषमूर्जँ समग्रभमुपयामगृहीतो सीन्द्राय त्वा जुष्टङ्गृह्णाम्येष ते योनिरिन्द्राय त्वा जुष्टतमम् ॥ ॐ सूर्यादिनवग्रहेभ्यो नमः ।   सूर्यादिनवग्रहेभ्य: साङ्गेभ्यः सपरिवारेभ्यः सायुद्धेभ्यः सशक्तिकेभ्यः अधिदेवता-प्रत्यधिदेवता-गणपत्यादिपञ्चलोकपालवास्तोष्पतिसहितेभ्यः इमं सदीपदधिमाषभक्तबलिं  समर्पयामि । भो भो सूर्यादिनवग्रहा: ! साङ्गा :सपरिवारा: सायुधा: सशक्तिका: अधिदेवता-प्रत्यधिदेवता: गणपत्यादिपञ्चलोकपाल-वास्तोष्पतिसहिता: इमं बलि गृह्णीत मम सकुटुम्बस्य सपरिवारस्य आयुः कर्तारः क्षेमकर्तारः शान्तिकर्तारः पुष्टिकर्तारः तुष्टिकर्तारो वरदा भवत। अनेन बलिदानेन साङ्गा सूर्यादिनवग्रहा: प्रियन्ताम् ।`
    },
    {
        title:`वास्तुबलि`,
        des:`ॐ वास्तोष्पते प्रति जानीह्यस्मान्त्स्वावेशो अनमीवो भवा नः। यत्त्वेमहे प्रति तन्नो जुषस्व शं नो भव द्विपदे शं चतुष्पदे ॥ शिख्यादिसहितावास्तोष्पते  साङ्गाय सपरिवाराय सायुधाय सशक्तिकाय इमं सदीपदधिमाषभक्तबलिं  समर्पयामि। भो शिख्यादिसहितावास्तोष्पते! इमं बलि गृह्णीत मम सकुटुम्बस्य सपरिवारस्य आयुकर्ता  क्षेमकर्ता पुष्टिकर्ता तुष्टिकर्ता वरदो भवत। अनेन बलिदानेन  शिख्यादिसहितावास्तोष्पति: प्रियन्ताम् ।`
    },
    {
        title:`योगिनीबलि`,
        des:`ॐ योगेयोगे तवस्तरं वाजेवाजे हवामहे। सखाय इन्द्रमूतये॥ महाकाली-महालक्ष्मी-महासरस्वतीसहितगजाननाद्यावाहितचतु:षष्टियोगिन्यः साङ्गाभ्यः सपरिवाराभ्यः सायुधाभ्यः सशक्तिकाभ्यः इमं सदीपदधिमाषभक्तबलिं  समर्पयामि। भो महाकाली-महालक्ष्मी-महासरस्वतीसहितचतु:षष्टियोगिन्यः ! इमं बलि गृह्णीत मम सकुटुम्बस्य सपरिवारस्य आयुः कर्त्र्यः, क्षेमकर्त्र्यः, शान्तिकर्त्र्यः, पुष्टिकर्त्र्यः तुष्टिकर्त्र्यः वरदा भवत। अनेन बलिदानेन महाकाली-महालक्ष्मी-महासरस्वतीसहितगजाननाद्यावाहितचतु:षष्टियोगिन्यः प्रियन्ताम् ।`
    },
    {
        title:`प्रधानदेवता बलि`,
        des:`---`
    }
]




export {
        kushkundika,
        navgrahDevHom,
        vastuDevHom, 
        sarvatoBhadraHome,
        chaturLingatoHom,
        yoginiHom,
        sodasmatrikaHom,
        saptGhriMatrikaHom,
        chetrapaalHom,
        navAhuti,
        bali
    }