

const vastuDevAvahan=[
    {
        title:`१. शिखि`,
        des1:`ॐ नमः शम्भवाय च मयेभवाय च नमः शङ्कराय च मयस्कराय च नमः शिवाय च शिवतराय च॥ `,
        des2:`ॐ भूर्भुवः स्वः शिखिन इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः शिखिने नमः॥`
    },
    {
        title:`२. पर्जन्य`,
        des1:`ॐ शन्नो व्वातः पवता ᳪ शन्नस्तपतु सूर्य्यः । शन्नः कनिक्क्रदद्देवः पर्जन्यो ऽअभिवर्षतु ॥ `,
        des2:`ॐ भूर्भुवः स्वः पर्जन्य इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः पर्जन्याय नमः ॥`
    },
    {
        title:`३. जयन्त`,
        des1:` ॐ मर्म्माणि ते व्वर्म्मणा सोमस्त्वा राजा ऽमृतेननानुवस्ताम् । उरोर्व्वरीयो व्वरुवणस्ते कृणोतु जयन्तं त्वानु देवा मदन्तु ॥ `,
        des2:`ॐ भूर्भुवः स्वः जयन्त इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः जयन्ताय नमः ॥`
    },
    {
        title:`४. कुलिशायुध`,
        des1:`ॐ सजोषा इन्द्र सगणो मरुद्भिः सोमम्पिब वृत्रहा शूर विद्वान् जहि शत्रूँरपमृधोनुदस्वाथाभयङ्कृणुहि विश्वतो नमः ॥ `,
        des2:`ॐ भूर्भुवः स्वः कुलीशायुध इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः कुलीशायुधाय नमः ॥`
    },
    {
        title:`५. सूर्य `,
        des1:`ॐ आ कृष्णेन रजसा व्वर्तमानो निवेशयन्न मृतं मर्त्यं च । हिरण्येन सविता रथेना देवो याति भूवनानि पश्यन् ॥ `,
        des2:`ॐ भूर्भुवः स्वः सूर्य इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः सूर्याय नमः ॥`
    },
    {
        title:`६. सत्य`,
        des1:`ॐ व्व्रतेन दीक्षामाप्नोति दीक्षयाप्नोति दक्षिणां दक्षिणा श्रद्धामाप्नोति श्रद्धयासत्य माप्यते ॥ `,
        des2:`ॐ भूर्भुवः स्वः सत्य इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः सत्याय नमः ॥`
    },
    {
        title:`७. भृश`,
        des1:`ॐ आ त्वाहार्षमन्तरभूर्ध्रुवस्तिष्ठाव्विचाचलिः । व्विशस्ता सर्व्वा व्वाञ्छन्तु मा त्वद्रामधिभ्रशत् ॥ `,
        des2:`ॐ भूर्भुवः स्वः भृश इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः भृशाय नमः ॥`
    },
    {
        title:`८. आकाश `,
        des1:`ॐ या वां कशा मधुमत्यश्विना सूनृतावती । तया यम मिमिक्षतम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः आकाश इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः आकाशाय नमः ॥`
    },
    {
        title:`९. वायु `,
        des1:`ॐ व्वायो ये ते सहस्रिणो रथासस्तेभिरागहि । नियुत्वान्त्सोमपीतये ॥ `,
        des2:`ॐ भूर्भुवः स्वः वायो इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः वायवे नमः ॥`
    },
    {
        title:`१०. पूष्ण `,
        des1:`ॐ पूषन् तव व्व्रते व्वयं न रिष्येम कदाचन। स्तोतारस्त ऽइह स्मसि ॥`,
        des2:`ॐ भूर्भुवः स्वः पूषण इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः पूष्णे नमः ॥`
    },
    {
        title:`११. वितथ `,
        des1:`ॐ तत्सूर्यस्य देवत्वं तन्महित्वं मध्या कर्तोर्वितत ᳪ सं जभार । यदेदयुक्त हरितः सधस्थादाद्रात्री वासस्तनुते सिमस्मै ॥ `,
        des2:`ॐ भूर्भुवः स्वः वितथ इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः वितथाय नमः ॥`
    },
    {
        title:`१२. गृहक्षत `,
        des1:`ॐ अक्षन्नमीमदन्त ह्यव प्प्रिया ऽअधूषत। अस्तोषत स्वभानवो व्विप्रा नविष्ठया मती योजान्विन्द्र ते हरी ॥ `,
        des2:`ॐ भूर्भुवः स्वः गृहक्षत इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः गृहक्षताय नमः ॥`
    },
    {
        title:`१३. यम `,
        des1:`ॐ यमाय त्वांगिरस्वते पितृमते स्वाहा। स्वाहा घर्माय स्वाहा धर्मः पित्रे ॥ `,
        des2:`ॐ भूर्भुवः स्वः यम इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः यमाय नमः ॥`
    },
    {
        title:`१४. गन्धर्व `,
        des1:` ॐ गन्धर्व्वस्तं व्विश्वावसुः परिदधातु व्विश्ंस्यारिष्ट्यै यजमानस्य परिधिरस्यग्निरिड ऽईडित ॥ इन्द्रस्य बाहुरसि दक्षिणो व्विश्वस्यारिष्ट्यै यजमानस्य परिधरस्यग्निरिड ऽईडितः ॥मित्त्रावरुणौ त्वोत्तरतः परिधतां ध्रुवेण धर्मणा व्विश्वस्यारिष्ट्यै यजमानस्य परिधिरस्यग्निरिड ऽईडितः ॥ `,
        des2:`ॐ भूर्भुवः स्वः गन्धर्व इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः गन्धर्वाय नमः ॥`
    },
    {
        title:`१५. भृङ्गराज `,
        des1:`ॐ सौरी बलाका शार्गः सृजयः शयाण्डकस्ते मैत्त्राः सरस्वत्यैशारिः पुरुषवाक् श्वाविद्भौमी शार्द्दूलो व्वृकः पृदाकुस्ते मन्यवे सरस्वते शुक्रः पुरुषांक् ॥ `,
        des2:`ॐ भूर्भुवः स्वः भृंगराज इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः भृंगराजाय नमः ॥`
    },
    {
        title:`१६. मृग `,
        des1:`ॐ मृगो न भीमः कुचरो गिरिष्ठाः परावत ऽआजगन्था परस्याः ॥ सृक ᳪ स ᳪ शायपविमिन्द्र तिग्मं व्वि शत्त्रून् ताड्ढि वि मृघो नुदस्व ॥ `,
        des2:`ॐ भूर्भुवः स्वः मृग इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः मृगाय नमः ॥`
    },
    {
        title:`१७. पितृ `,
        des1:`ॐ उशन्तस्त्वा निधीमह्युशन्तः समिधीमहि ॥ उशन्नुशत ऽआवह पितॄन्हविषे ऽअत्तवे ॥ `,
        des2:`ॐ भूर्भुवः स्वः पितर इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः पित्रे नमः ॥`
    },
    {
        title:`१८. दौवारिक `,
        des1:` ॐ द्वे व्विरूपे चरतः स्वर्थे ऽअन्यान्या व्वत्समुप धापयेते । हरिरन्यस्यां भवति स्वधावाञ्छुक्क्रो ऽअन्यस्यां ददृशे सुवर्च्चा ॥`,
        des2:`ॐ भूर्भुवः स्वः दौवारिक इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः दौवारिकाय नमः ॥`
    },
    {
        title:`१९. सुग्रीव `,
        des1:` ॐ नीलग्ग्रीवाः शितिकण्ठा दिव ᳪ रुद्द्राऽउपश्श्रिताः। तेषा ᳪ सहस्रयोजनेऽवधन्न्वानि तन्न्मसि ॥ `,
        des2:`ॐ भूर्भुवः स्वः सुग्रीव इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः सुग्रीवाय नमः ॥`
    },
    {
        title:`२०. पुष्पदन्त `,
        des1:` ॐ नमो गणेब्भ्यो गणपतिब्भ्यश्च वो नमोनमो व्व्रातेब्भ्यो व्व्रातपतिब्भ्यश्च वो नमोनमो गृत्सेब्भ्यो गृत्सपतिब्भ्यश्च वो नमोनमो व्विरूपेब्भ्यो व्विश्वरुपेब्भ्यश्च वो नमः ॥ `,
        des2:`ॐ भूर्भुवः स्वः पुष्पदन्त इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः पुष्पदन्ताय नमः ॥`
    },
    {
        title:`२१. वरुण `,
        des1:` ॐ इमम्मे व्वरुणश्श्रुधी हवमद्या च मृडय। त्वामवस्युराचके।। ॥ `,
        des2:`ॐ भूर्भुवः स्वः वरुण इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः वरुणाय नमः ॥`
    },
    {
        title:`२२. असुर `,
        des1:` ॐ यमश्विना नमुचेरासुरादधि सरस्वत्यसुनोदिन्द्रियाय॥ इ॒मं त ᳪ शुवक्रं मधुमन्तमिन्दु ᳪ सोम ᳪ राजानमिह भक्षयामि॥ `,
        des2:`ॐ भूर्भुवः स्वः असुर इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः असुराय नमः ॥`
    },
    {
        title:`२३. शेष `,
        des1:`ॐ या ऽइषवो यातुधानाना ये वा व्वन॒स्पतीं२ रनु ॥ ये वाऽवटेषु ॥ शेरते तेभ्यः ॥ `,
        des2:`ॐ भूर्भुवः स्वः शेष इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः शेषाय नमः ॥`
    },
    {
        title:`२४. पाप `,
        des1:`ॐ एतत्ते रुद्राऽवसन्तेन परो मूजवतो ऽतीहि ॥ अवतत धन्वा पिनाकावसः कृत्तिवासा ऽअहि ᳪ स॒न्नः शिवोऽतीहि ॥ `,
        des2:`ॐ भूर्भुवः स्वः पाप इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः पापाय नमः ॥`
    },
    {
        title:`२५. रोग`,
        des1:`ॐ द्द्रापेऽअन्धसस्प्पतेदरिद्द्रनीललोहित । आसाम्प्रजानामेषाम्पशूनाम्माभेर्म्मारोङ्मोचनः किञ्चनाममत् ॥`,
        des2:`ॐ भूर्भुवः स्वः रोग इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः रोगाय नमः ॥`
    },
    {
        title:`२६. अहिर्बुध्न्य`,
        des1:`ॐ अहिरिव भोगै: पर्येति बाहुं ज्याया हेतिं परिबाधमान: । हस्तघ्नो विश्वा वयुनानि विद्वान् पुमान् पुमा ᳪ सं परि पातु विश्वत: ॥`,
        des2:`ॐ भूर्भुवः स्वः अहिर्बुध्न्य इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः अहिर्बुध्न्याय नमः ॥`
    },
    {
        title:`२७. मुख्य`,
        des1:`ॐ अवतत्त्य धनुष्ट्व ᳪ सहस्राक्षशतेषुधे। निशीर्य्यशल्ल्यानाम्मुखा शिवोनः सुमना भव ॥`,
        des2:`ॐ भूर्भुवः स्वः मुख्य इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः मुख्याय नमः ॥`
    },
    {
        title:`२८. भल्लाट`,
        des1:` ॐ इमारुद्द्रायतवसे कपर्द्दिने क्षयद्वीराय प्प्रभरामहेमतीः । यथाषमसद्द्विपदे चतुष्ष्पदे व्विश्व्म्पुष्टङ्ग्रामेऽअस्म्मिन्ननातुरम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः भल्लाट इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः भल्लाटाय नमः ॥`
    },
    {
        title:`२९. सोम `,
        des1:` ॐ वय ᳪ सोमव्रते तव मनस्तनूषु बिब्भ्रतः। प्रजावन्तः सचेमहि॥`,
        des2:`ॐ भूर्भुवः स्वः सोम इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः सोमाय नमः॥`
    },
    {
        title:`३०. सर्प`,
        des1:`ॐ नमोऽस्तु सर्पेभ्यो ये के च पृथिविमनु । ये ऽअन्तरिक्षे ये दिवि तेभ्यः सर्पेभ्यो नमः॥ `,
        des2:`ॐ भूर्भुवः स्वः सर्पाः इहागच्छत इह तिष्ठत। ॐ भूर्भुवः स्वः सर्पेभ्यो नमः ॥`
    },
    {
        title:`३१. अदिति `,
        des1:`ॐ अदितिर्द्यौरदितिरन्तरिक्षमदितिर्माता स पिता स पुत्रः। विश्वेदेवा अदितिः पञ्चजना अदितिर्जातमदितिर्जनित्वम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः अदिते इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः अदितये नमः ॥`
    },
    {
        title:`३२. दिति `,
        des1:`ॐ इड ऽएह्यदित ऽएहि काम्या ऽएत । मयि वः कामधरणं भूयात् ॥ ॐ भूर्भुवः स्वः दण्ड इहागच्छ इह तिष्ठ ॥ `,
        des2:`ॐ भूर्भुवः स्वः दिते इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः दितये नमः ॥`
    },
    {
        title:`३३. अप `,
        des1:` ॐ आपो हिष्ठा मयोभुवस्तानऽऊर्जे दधातन महेरणाय चक्षसे यो वः शिवतमो रसस्तस्य भाजयतेह नः उशतीरिव मातरः। तस्माऽअरङ्गमामवो यस्य क्षयाय जिन्वथ आपो जनयथा च नः॥ `,
        des2:`ॐ भूर्भुवः स्वः अद्भ्यः इहागच्छत इह तिष्ठत। ॐ भूर्भुवः स्वः अद्भ्यो नमः ॥`
    },
    {
        title:`३४. आपवत्स `,
        des1:` ॐ आ ते व्वत्सो मनो यमत्परमाञ्चित्सधस्थात्॥ अग्ने त्वां कामया गिरा ॥ `,
        des2:`ॐ भूर्भुवः स्वः आपवत्स इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः आपवत्साय नमः ॥`
    },
    {
        title:`३५. अर्यमण`,
        des1:`ॐ यदद्य सूर ऽउदितेऽनागा मित्रो ऽअर्यमा । सुवाति सविता भगः ॥ `,
        des2:`ॐ भूर्भुवः स्वः अर्यमण इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः अर्यम्णे नमः ॥`
    },
    {
        title:`३६. सावित्र`,
        des1:`ॐ हस्त ऽआधाय सवि॒ता बिब्भ्रद्भिः हिरण्मयीम् ॥ अग्नेर्ज्ज्योतिर्न्निचाय्य पृथिव्या ऽअद्ध्याभरदानुष्टुभेनच्छन्दसाङ्गिरस्वत्॥`,
        des2:`ॐ भूर्भुवः स्वः सवित्रे इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः सावित्राय नमः ॥`
    },
    {
        title:`३७. सवितृ`,
        des1:`ॐ व्विश्वानि देव सवितर्द्दुरितानि परासुव । यद्भद्द्रं तन्न ऽआ सुव ॥`,
        des2:`ॐ भूर्भुवः स्वः सविता इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः सवित्रे नमः ॥`
    },
    {
        title:`३८. विवस्वान`,
        des1:`ॐ व्विवस्वन्नादित्यैष ते सोमपीथस्तस्मिन्मत्स्व ॥ श्र्श्रदस्मै नरो व्वचसे दधातन यदाशीर्द्दा दम्पती व्वाममश्नुतः ॥ पुमान्पुत्त्रो जायते विन्दते व्वस्वधा विश्वाहारप ऽएधते गृहे ॥ `,
        des2:`ॐ भूर्भुवः स्वः विवस्वान इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः विवस्वते नमः ॥`
    },
    {
        title:`३९: विबुधाधिप`,
        des1:`ॐ स बोधि सूरिर्म्मघवा व्वसुपते व्वसुदावन् । युयोध्यस्मद् द्वेषा ᳪ सि व्विश्वकर्म्मणे स्वाहा ॥ `,
        des2:`ॐ भूर्भुवः स्वः विबुधाधिप इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः विबुधाधिपाय नमः ॥`
    },
    {
        title:`४०. जय`,
        des1:`ॐ अषाढं य्युत्सु पृतनासु पप्प्रि ᳪ स्वर्षामप्सां व्वृजनस्य गोपाम् ॥ भरेषुजा ᳪ सुक्षितिः सुश्र्श्रवसं जयन्तं त्वामनु मदेन सोम ॥`,
        des2:`ॐ भूर्भुवः स्वः जय इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः जयाय नमः ॥`
    },
    {
        title:`४१. मित्र`,
        des1:`ॐ मित्त्रो न एहि सुमित्त्रध ऽइन्द्रस्योरुमाविश दक्षिणमुशन्नुशन्त ᳪ स्योनः स्योनम्॥ स्वानब्भ्राजाङ्घरिब्बर्भारे हस्त सुहस्त कृशानवेतेवः सोमवक्रयणात्तान्रक्षद्ध्वं मा वो दभन् ॥ `,
        des2:`ॐ भूर्भुवः स्वः मित्र इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः मित्राय नमः ॥`
    },
    {
        title:`४२. राजयक्ष्मा`,
        des1:`ॐ नाशयित्त्री बलासस्यार्शस ऽउपचितामसि ॥ अथो शतस्य यक्ष्माणां पाकारोरसि नाशनी ॥`,
        des2:`ॐ भूर्भुवः स्वः राजयक्ष्मा इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः राजयक्ष्मणे नमः ॥`
    },
    {
        title:`४३. रुद्र `,
        des1:`ॐ अव रुद्रमदीमह्यव द्देवं त्र्यम्बकम् ॥ यथा नो व्वस्यसस्करद्यथा नः श्रेयसस्करद्यथा नो व्यवसाययात् ॥ `,
        des2:`ॐ भूर्भुवः स्वः रुद्र इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः रुद्राय नमः ॥`
    },
    {
        title:`४४. पृथ्वीधर `,
        des1:`ॐ स्योना पृथिवि नो भवानृक्षरानिवेशनी यच्छा नः शर्मसप्रथाः॥ `,
        des2:`ॐ भूर्भुवः स्वः पृथ्वीधर इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः पृथ्वीधराय नमः ॥`
    },
    {
        title:`४५. ब्रह्मा `,
        des1:`ॐ ब्रह्म यज्ञानं प्रथमं पुरस्ताद्विसीमतः सुरुचो वेनऽआवः। सबुध्न्या उपमाऽअस्य विष्ठाः सतश्च योनिमसश्च विवः॥ `,
        des2:`ॐ भूर्भुवः स्वः ब्रह्मण इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः ब्रह्मणे नमः ॥`
    }
]

export {vastuDevAvahan}





 

